import axios from 'axios'
import React, { useEffect, useState } from 'react'
import Footer from '../../Layout/Footer'
import Header from '../../Layout/Header'
import Nav from '../../Layout/Nav'
import FormCommission from './FormCommission'
import ListCommission from './ListCommission'

function Commission() {
    const [broker, setBroker] = useState()
    const getBroker = () => {
        axios.get(process.env.REACT_APP_API_URL + "/getBroker")
        .then(res => res.data)
        .then((brokers) => setBroker(brokers))
        .catch(err => console.log(err))
    }
    useEffect(() => {
        getBroker()
    }, [])
    return (
        <>
            <Nav />
            <div className='page'>
                <Header />
                <div className="section-body">
                    <div className="container-fluid">
                        <div className="d-flex justify-content-between align-items-center ">
                            <div className="header-action">
                                <h1 className="page-title">จัดการ Commission</h1>
                                <ol className="breadcrumb page-breadcrumb">
                                    <li className="breadcrumb-item"><a href="/dashboard">หน้าแรก</a></li>
                                    <li className="breadcrumb-item"><a href="/user">ผู้ใช้งาน</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">จัดการ Commission</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section-body mt-4">
                    <div className="container-fluid">
                        <FormCommission brokers={broker} />
                        <ListCommission />
                    </div>
                </div>
                <Footer />     
            </div>  
        </>
    )
}

export default Commission
