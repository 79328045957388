import React from 'react'
import { useAuthUser } from 'react-auth-kit'
import FormProfileAdmin from './form/FormProfileAdmin'

function FormProfile() {
    const auth = useAuthUser()
    const showForm = () => {
        switch (auth().role) {
            case 1:
                return <FormProfileAdmin />
            default:
                break;
        }
    }
    return (
        <>
            <div className="tab-pane" id="editProfile">
                {showForm()}
            </div>
        </>
    )
}

export default FormProfile
