import React, { useEffect, useState } from 'react'
import axios from 'axios'

function Order({ formProps: { register, formState: {errors}, setValue }, data}) {
    const {broker} = data[0][0]
    const {carType, carBody} = data[1][0]
    const {makeCode, modelCode, year, carSize, seat, weight, color, colorDes} = data[2][0]
    const {registrationType, registrationNo, registrationProvince, registrationYear, carCountry, chassisNumber, engineNumber} = data[3][0]
    const { insuredType, insuredPrefix, insuredName, insuredLastname, insuredUniqueID, insuredIdType, nationality, occupation,
            address, moo, soi, road, subdistrict, district, province, zipcode, mobilePhoneNumber, phoneNumber, officePhoneNumber,
            gender, emailAddr, birthDay, driver, branchNo } = data[4][0]
    const {dateStart, dateStop, price, stamp, vat, priceNet} = data[5][0]
    const [brokerName, setBrokerName] = useState()
    const [registrationTypeName, setRegistrationTypeName] = useState()
    const [insuredTypeName, setInsuredTypeName] = useState()
    console.log(data);
    const getBroker = () => {
        axios.get(process.env.REACT_APP_API_URL + '/getBrokerName/' + broker)
        .then(res => setBrokerName(res.data.broker_name))
        .catch((err) => console.log(err))
    }
    const getRegisSpc = () => {
        axios.get(process.env.REACT_APP_API_URL + '/getRegisSpcName/' + registrationType)
        .then(res => setRegistrationTypeName(res.data))
        .catch((err) => console.log(err))
    }
    const getInsuredType = () => {
        if (insuredType === '1' | insuredType === 'PERSON') {
            setInsuredTypeName('บุคคลธรรมดา')
        } else if(insuredType === '2' | insuredType === 'COMPANY') {
            setInsuredTypeName('นิติบุคคล')
        }
    }
    useEffect(() => {
        getBroker()
        getRegisSpc()
        getInsuredType()
    }, [])
    return (
        <>
            <div className="card">
                <div className="card-body">
                    <h5 className='text-azure'>รายละเอียดคำสั่งซื้อ</h5>
                    <div className="text-muted">กรุณาตรวจสอบข้อมูลให้ถูกต้อง</div>
                    <div className="row mt-3 clearfix">
                        <div className="col-md-12 col-sm-12">
                            <div className='form-group'>
                                <label>บริษัทประกันภัย</label>
                                <input className="form-control bg-light-blue" type="text" value={brokerName} aria-label="readonly input example" readonly />
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <div className="form-group">
                                <label>วันที่เริ่มกรมธรรม์</label>
                                <input type="text" className="form-control bg-azure text-white" name="start" value={dateStart} readonly />
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <div className="form-group">
                                <label>วันที่สิ้นสุดกรมธรรม์</label>
                                <input type="text" className="form-control bg-azure text-white" name="end" value={dateStop} readonly />
                            </div>
                        </div>
                        <div className="col-md-12 col-sm-12">
                            <div className='form-group'>
                                <label>เบี้ยประกันภัยรวม (บาท)</label>
                                <input type="text" className="form-control bg-azure text-white" name="priceNet" value={priceNet} readonly />    
                            </div>
                        </div>
                        <div className="col-md-12 col-sm-12">
                            <div className='form-group'>
                                <label>ประเภทรถ</label>
                                <input className="form-control bg-light-blue" type="text" value={carBody} aria-label="readonly input example" readonly />
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className='form-group'>
                                <label>ยี่ห้อรถ</label>
                                <input className="form-control bg-light-blue" type="text" value={makeCode.label} aria-label="readonly input example" readonly />
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className='form-group'>
                                <label>รุ่นรถ</label>
                                <input className="form-control bg-light-blue" type="text" value={modelCode.label} aria-label="readonly input example" readonly />
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className='form-group'>
                                <label>สี</label>
                                {color.label === 'อื่น ๆ' ? <input type="text" className="form-control bg-light-blue" name='weight' value={colorDes} readonly />
                                                        : <input type="text" className="form-control bg-light-blue" name='weight' value={color.label} readonly />}
                                
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-12">
                            <div className='form-group'>
                                <label>ปีรถ (ค.ศ.)</label>
                                {year ? <input className="form-control bg-light-blue" type="text" value={year.label} aria-label="readonly input example" readonly />
                                        : <input className="form-control bg-light-blue" type="text" aria-label="readonly input example" readonly />}
                                
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-12">
                            <div className="form-group">
                                <label>จำนวนที่นั่ง</label>
                                <input type="text" className="form-control bg-light-blue" name='seat' value={seat.label} readonly />
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-12">
                            <div className="form-group">
                                <label>ขนาดรถ (ซีซี)</label>
                                <input type="text" className="form-control bg-light-blue" name='carSize' value={carSize} readonly />
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-12">
                            <div className="form-group">
                                <label>น้ำหนักรถ (กิโลกรัม)</label>
                                <input type="text" className="form-control bg-light-blue" name='weight' value={weight} readonly />
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-12">
                            <div className="form-group">
                                <label>ประเภททะเบียน</label>
                                <input type="text" className="form-control bg-light-blue" name='registrationType' value={registrationTypeName} readonly />
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-12">
                            <div className='form-group'>
                                <label>รถประเทศ</label>
                                <input type="text" className="form-control bg-light-blue" name='registrationType' value={carCountry.label} readonly />
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-12">
                            <div className="form-group">
                                <label>ทะเบียนรถ</label>
                                <input type="text" className="form-control bg-light-blue" name='registrationNo' value={registrationNo} placeholder='กก1234, 1กก1234' readonly />
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-12">
                            <div className='form-group'>
                                <label>ทะเบียนจังหวัด</label>
                                <input type="text" className="form-control bg-light-blue" name='registrationProvince' value={registrationProvince.label} readonly />
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <div className="form-group">
                                <label className='text-azure'>หมายเลขตัวถัง</label>
                                <input type="text" className="form-control bg-azure text-white text-uppercase" name='chassisNumber' value={chassisNumber} readonly />
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <div className="form-group">
                                <label className='text-azure'>หมายเลขเครื่องยนต์</label>
                                <input type="text" className="form-control bg-light-blue" name='engineNumber' value={engineNumber} readonly/>
                            </div>
                        </div>
                        <div className="col-md-12 col-sm-12">
                            <div className="form-group">
                                <label>ประเภทผู้เอาประกัน</label>
                                <input type="text" className="form-control bg-light-blue" name='engineNumber' value={insuredTypeName} readonly/>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <div className="form-group">
                                <label>เลขประจำตัวประชาชน/เลขประจำตัวผู้เสียภาษี</label>
                                <input type="text" className="form-control bg-light-blue" name='insuredUniqueID' value={insuredUniqueID} readonly />
                            </div>

                        </div>
                        <div className="col-md-6 col-sm-12">
                            <div className="form-group">
                                <label>ประเภทเลขประจำตัวผู้เอาประกัน </label>
                                {insuredIdType ? <input type="text" className="form-control bg-light-blue" name='insuredIdType' value={insuredIdType.label} readonly />
                                                : <input type="text" className="form-control bg-light-blue" name='insuredIdType' disabled />}
                                
                            </div>
                        </div>
                        {insuredTypeName === 'บุคคลธรรมดา' ?
                            <>
                                <div className="col-md-4 col-sm-12">
                                    <div className='form-group'>
                                        <label>คำนำหน้า</label>
                                        <input type="text" className="form-control bg-light-blue" name='insuredPrefix' value={insuredPrefix.label} readonly />
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    <div className="form-group">
                                        <label>ชื่อ</label>
                                        <input type="text" className="form-control bg-light-blue" name='insuredName' value={insuredName} readonly />
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    <div className="form-group">
                                        <label>นามสกุล </label>
                                        <input type="text" className="form-control bg-light-blue" name='insuredLastname' value={insuredLastname} readonly />
                                    </div>
                                </div>
                            </>
                        :
                            <>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>ชื่อนิติบุคคล</label>
                                        <input type="text" className="form-control bg-light-blue" name='insuredName' value={insuredName} readonly />
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>สาขา </label>
                                        <input type="text" className="form-control bg-light-blue" name='insuredLastname' value={branchNo} readonly />
                                    </div>
                                </div>
                            </>
                        }
                        <div className="col-md-12 col-sm-12">
                            <div className='form-group'>
                                <label>ผู้ขับขี่</label>
                                <input type="text" className="form-control bg-light-blue" name='occupation' value={driver} readonly />
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className='form-group'>
                                <label>อาชีพ</label>
                                <input type="text" className="form-control bg-light-blue" name='occupation' value={occupation.label} readonly />
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className='form-group'>
                                <label>เพศ</label>
                                <input type="text" className="form-control bg-light-blue" name='gender' value={gender.label} readonly />
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className='form-group'>
                                <label>สัญชาติ</label>
                                {nationality === undefined ? <input type="text" className="form-control bg-light-blue" name='nationality' disabled readonly />
                                                    : <input type="text" className="form-control bg-light-blue" name='nationality' value={nationality.label} readonly />}
                                
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-12">
                            <div className="form-group">
                                <label>ที่อยู่</label>
                                <input type="text" className="form-control bg-light-blue" name='address' value={address} readonly />
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-12">
                            <div className="form-group">
                                <label>หมู่</label>
                                <input type="text" className="form-control bg-light-blue" name='moo' value={moo} readonly />
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-12">
                            <div className="form-group">
                                <label>ซอย</label>
                                <input type="text" className="form-control bg-light-blue" name='soi' value={soi} readonly />
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-12">
                            <div className="form-group">
                                <label>ถนน</label>
                                <input type="text" className="form-control bg-light-blue" name='road' value={road} readonly />
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-12">
                            <div className="form-group">
                                <label>จังหวัด</label>
                                <input type="text" className="form-control bg-light-blue" name='province' value={province.label} readonly />
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-12">
                            <div className="form-group">
                                <label>อำเภอ</label>
                                <input type="text" className="form-control bg-light-blue" name='district' value={district.label} readonly />
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-12">
                            <div className="form-group">
                                <label>ตำบล</label>
                                <input type="text" className="form-control bg-light-blue" name='subdistrict' value={subdistrict.label} readonly />
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-12">
                            <div className="form-group">
                                <label>รหัสไปรษณีย์</label>
                                <input type="text" className="form-control bg-light-blue" name='zipcode' value={zipcode.label} readonly />
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                                <label>เบอร์โทรศัพท์มือถือ</label>
                                <input type="text" className="form-control bg-light-blue" name='mobilePhoneNumber' value={mobilePhoneNumber} readonly />
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                                <label>เบอร์โทรศัพท์</label>
                                <input type="text" className="form-control bg-light-blue" name='phoneNumber' value={phoneNumber} readonly />
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                                <label>เบอร์โทรศัพท์ที่ทำงาน</label>
                                <input type="text" className="form-control bg-light-blue" name='officePhoneNumber' value={officePhoneNumber} readonly />
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <div className="form-group">
                                <label>อีเมล</label>
                                <input type="text" className="form-control bg-light-blue" name='email' value={emailAddr} readonly />
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <div className="form-group">
                                <label>วัน/เดือน/ปี เกิด</label>
                                <input type="text" className="form-control bg-light-blue" name='birthDay' value={birthDay} readonly />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Order
