import React, { useRef, useState } from 'react'
import { useAuthUser } from 'react-auth-kit'
import axios from 'axios'
import Swal from 'sweetalert2'

function FormWithdrawHead(props) {
    const auth = useAuthUser()
    const form = useRef()
    const selectMoney50 = useRef()
    const selectMoney100 = useRef()
    const selectMoney500 = useRef()
    const selectMoney1000 = useRef()
    const selectMoney5000 = useRef()
    const selectMoney10000 = useRef()
    const checkCreditTotal = useRef()
    const [amount, setAmount] = useState()
    const onSubmit = (e) => {
        e.preventDefault() //ไม่ให้ Browser refresh
        const formData = new FormData(form.current)
        const formEnt = Object.fromEntries(formData.entries())
        axios.post(process.env.REACT_APP_API_URL + '/manageCreditByHead', formEnt)
        .then(response=>{
            form.current.reset()
            setAmount()
            Swal.fire('สำเร็จ',response.data.success,'success').then((result) => {
                if (result.isConfirmed) {
                    window.location.reload()
                }
            })
        }).catch(err=>{
            console.log(err.response);
            Swal.fire('แจ้งเตือน',err.response.data.error,'error')
        })
    }
    const handleMoney = (e) => {
        const amount = e.target.value
        if (amount > props.creditTotal) {
            Swal.fire('แจ้งเตือน','ยอดเงินไม่เพียงพอ','warning')
            selectMoney50.current.checked = false
            selectMoney100.current.checked = false
            selectMoney500.current.checked = false
            selectMoney1000.current.checked = false
            selectMoney5000.current.checked = false
            selectMoney10000.current.checked = false
        } else {
            setAmount(amount)
        }
        checkCreditTotal.current.checked = false 
    }
    const handleAmont = (e) => {
        const amount = e.target.value
        if (amount > props.creditTotal) {
            Swal.fire('แจ้งเตือน','ยอดเงินไม่เพียงพอ','warning')
            selectMoney50.current.checked = false
            selectMoney100.current.checked = false
            selectMoney500.current.checked = false
            selectMoney1000.current.checked = false
            selectMoney5000.current.checked = false
            selectMoney10000.current.checked = false
        } else {
            setAmount(amount)
        }
        checkCreditTotal.current.checked = false  
        
    }
    const handleCreditTotal = (e) => {
        console.log('check total : ',checkCreditTotal.current.checked);
        if (checkCreditTotal.current.checked === true) {
            const amount = e.target.value
            if (amount > props.creditTotal) {
                Swal.fire('แจ้งเตือน','ยอดเงินไม่เพียงพอ','warning')
            } else {
                setAmount(amount)
            }
            selectMoney50.current.checked = false
            selectMoney100.current.checked = false
            selectMoney500.current.checked = false
            selectMoney1000.current.checked = false
            selectMoney5000.current.checked = false
            selectMoney10000.current.checked = false
        } else {
            setAmount('')
        }
    }
    return (
        <>
            <div className='card'>
                <div className="card-header">
                    <h3 className="card-title">รายละเอียดการฝากเงิน</h3>
                    <div className="card-options ">
                        <a href="#" className="card-options-collapse" data-toggle="card-collapse"><i className="fe fe-chevron-up"></i></a>
                    </div>
                </div>
                <div className='card-body'>
                    <form ref={form} onSubmit={onSubmit}>
                        <div className="row clearfix">
                            <div className="col-sm-12">
                                <label>เลือกจำนวนเงิน</label>
                                <div className='form-group'>
                                    <div className="button-radio-money">
                                        <input type="radio" id="selectMoney" name="selectMoney" value="50" onClick={handleMoney} ref={selectMoney50} />
                                        <label className="btn btn-default" for="a25">50</label>
                                    </div>
                                    <div className="button-radio-money">
                                        <input type="radio" id="selectMoney" name="selectMoney" value="100" onClick={handleMoney} ref={selectMoney100} />
                                        <label className="btn btn-default" for="a25">100</label>
                                    </div>
                                    <div className="button-radio-money">
                                        <input type="radio" id="selectMoney" name="selectMoney" value="500" onClick={handleMoney} ref={selectMoney500} />
                                        <label className="btn btn-default" for="a25">500</label>
                                    </div>
                                    <div className="button-radio-money">
                                        <input type="radio" id="selectMoney" name="selectMoney" value="1000" onClick={handleMoney} ref={selectMoney1000} />
                                        <label className="btn btn-default" for="a25">1,000</label>
                                    </div>
                                    <div className="button-radio-money">
                                        <input type="radio" id="selectMoney" name="selectMoney" value="5000" onClick={handleMoney} ref={selectMoney5000} />
                                        <label className="btn btn-default" for="a25">5,000</label>
                                    </div>
                                    <div className="button-radio-money">
                                        <input type="radio" id="selectMoney" name="selectMoney" value="10000" onClick={handleMoney} ref={selectMoney10000} />
                                        <label className="btn btn-default" for="a25">10,000</label>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-12'>
                                <div className="form-check mb-2 mt-2">
                                    <input className="form-check-input" type="checkbox" value={props.creditTotal} id="flexCheckDefault" onClick={handleCreditTotal} ref={checkCreditTotal} />
                                    <label className="form-check-label" for="flexCheckDefault">
                                        ถอนเงินทั้งหมด
                                    </label>
                                </div>
                            </div>
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <label>ระบุจำนวนเงิน <span className='text-danger'>*</span></label>
                                    <input type="nummber" name='amount' value={amount} onChange={handleAmont} className="form-control" required />
                                </div>
                            </div>
                            <div className='col-sm-12'>
                                <div className='form-group'>
                                    <label>เหตุผลในการถอนเงิน <span className='text-danger'>*</span></label>
                                    <div className="form-check">
                                        <input class="form-check-input" type="radio" name="channel" value="cash" id="flexRadioDefault1" required />
                                        <label class="form-check-label" for="flexRadioDefault1">ถอนเงิน</label>
                                    </div>    
                                    <div className="form-check">
                                        <input class="form-check-input" type="radio" name="channel" value="ผู้ดูแลระบบนำเงินไปซื้อประกันแทน" id="flexRadioDefault2" required />
                                        <label class="form-check-label" for="flexRadioDefault2">ผู้ดูแลระบบนำเงินไปซื้อประกันแทน</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12">
                                <input type="hidden" name='type' value="withdraw" />
                                <input type="hidden" name='filler' value={auth().id} />
                                <input type="hidden" name='user_id' value={props.userId} />    
                                <div className='form-group'>
                                    <button type='submit' className="btn btn-primary btn-block">ถอนเครดิต</button>
                                </div>
                            </div> 
                        </div>
                    </form>    
                </div>
            </div>
        </>
    )
}

export default FormWithdrawHead
