import React, { useState } from 'react'
import Nav from '../Layout/Nav'
import Header from '../Layout/Header'
import Footer from '../Layout/Footer'
import ListUserLevel from './listUser/ListUserLevel'
import FormUserLevel from './form/FormUserLevel'
import { useAuthUser } from 'react-auth-kit'

function UserLevel() {
    const auth = useAuthUser()
    const search = window.location.search
    let params = new URLSearchParams(search)
    const userId = params.get('userId')
    const manageRole = params.get('manageRole')
    const [toggle, setToggle] = useState(true)
    const handleToggle = () => {
        console.log(toggle);
        if (toggle === true) {
            setToggle(false)
        } else {
            setToggle(true)
        }
    }
    const navigator = (userId, manageRole) => {
        if (auth().role === 'agent') {
            if (manageRole === 'platinum') {
                return (
                    <ol className="breadcrumb page-breadcrumb">
                        <li className="breadcrumb-item"><a href="/dashboard">หน้าแรก</a></li>
                        <li className="breadcrumb-item active" aria-current="page">ผู้ใช้งานระดับ Platinum</li>
                    </ol>
                )
            } else if(manageRole === 'gold') {
                return (
                    <ol className="breadcrumb page-breadcrumb">
                        <li className="breadcrumb-item"><a href="/dashboard">หน้าแรก</a></li>
                        <li className="breadcrumb-item active" aria-current="page"><a href='#' onClick={historyBack}>ผู้ใช้งานระดับ Platinum</a></li>
                        <li className="breadcrumb-item active" aria-current="page">ผู้ใช้งานระดับ Gold</li>
                    </ol>
                )
            } else if(manageRole === 'silver') {
                return (
                    <ol className="breadcrumb page-breadcrumb">
                        <li className="breadcrumb-item"><a href="/dashboard">หน้าแรก</a></li>
                        <li className="breadcrumb-item active" aria-current="page"><a href='#' onClick={historyBack2}>ผู้ใช้งานระดับ Platinum</a></li>
                        <li className="breadcrumb-item active" aria-current="page"><a href='#' onClick={historyBack}>ผู้ใช้งานระดับ Gold</a></li>
                        <li className="breadcrumb-item active" aria-current="page">ผู้ใช้งานระดับ Silver</li>
                    </ol>
                )
            }    
        } else {
            if (manageRole === 'platinum') {
                return (
                    <ol className="breadcrumb page-breadcrumb">
                        <li className="breadcrumb-item"><a href="/dashboard">หน้าแรก</a></li>
                        <li className="breadcrumb-item active" aria-current="page"><a href='/user'>ผู้ใช้งานระดับ Agent</a></li>
                        <li className="breadcrumb-item active" aria-current="page">ผู้ใช้งานระดับ Platinum</li>
                    </ol>
                )
            } else if(manageRole === 'gold') {
                return (
                    <ol className="breadcrumb page-breadcrumb">
                        <li className="breadcrumb-item"><a href="/dashboard">หน้าแรก</a></li>
                        <li className="breadcrumb-item active" aria-current="page"><a href='/user'>ผู้ใช้งานระดับ Agent</a></li>
                        <li className="breadcrumb-item active" aria-current="page"><a href='#' onClick={historyBack}>ผู้ใช้งานระดับ Platinum</a></li>
                        <li className="breadcrumb-item active" aria-current="page">ผู้ใช้งานระดับ Gold</li>
                    </ol>
                )
            } else if(manageRole === 'silver') {
                return (
                    <ol className="breadcrumb page-breadcrumb">
                        <li className="breadcrumb-item"><a href="/dashboard">หน้าแรก</a></li>
                        <li className="breadcrumb-item active" aria-current="page"><a href='/user'>ผู้ใช้งานระดับ Agent</a></li>
                        <li className="breadcrumb-item active" aria-current="page"><a href='#' onClick={historyBack2}>ผู้ใช้งานระดับ Platinum</a></li>
                        <li className="breadcrumb-item active" aria-current="page"><a href='#' onClick={historyBack}>ผู้ใช้งานระดับ Gold</a></li>
                        <li className="breadcrumb-item active" aria-current="page">ผู้ใช้งานระดับ Silver</li>
                    </ol>
                )
            }    
        }
        
    }

    const historyBack = () => {
        window.history.back()
    }

    const historyBack2 = () => {
        window.history.go(-2)
    }

    return (
        <>
            <Nav />
            <div className='page'>
                <Header />
                <div className="section-body">
                    <div className="container-fluid">
                        <div className="d-flex justify-content-between align-items-center ">
                            <div className="header-action">
                                <h1 className="page-title">ผู้ใช้งาน</h1>
                                {navigator(userId, manageRole)}
                            </div>
                            <ul className="nav nav-tabs page-header-tab">
                                <li className="nav-item"><a className="nav-link active" onClick={handleToggle} data-toggle="tab" href="#ListUserPlatinum">รายชื่อผู้ใช้งาน</a></li>
                                <li className="nav-item"><a className="nav-link" data-toggle="tab" href="#formUserSeller">เพิ่มข้อมูล</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="section-body mt-4">
                    <div className="container-fluid">
                        <div className="tab-content">
                            <div className="tab-pane active" id="ListUserPlatinum">
                                <ListUserLevel toggle={toggle} userIdLead={userId} manageRole={manageRole} />
                            </div>
                            <div className="tab-pane" id="formUserSeller">
                                <FormUserLevel userIdLead={userId} manageRole={manageRole} />
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />     
            </div>
        </>
    )
}

export default UserLevel
