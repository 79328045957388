import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Swal from 'sweetalert2'

function ListUserAgent(props) {
    const [users, setUsers] = useState()
    const no = 1
    const getUser = () => {
        axios.get(process.env.REACT_APP_API_URL + "/getUser/agent/null")
        .then(res => res.data)
        .then((users) => setUsers(users))
        .catch(err => console.log(err))
    }

    const showRole = (role) => {
        switch (role) {
            case 1:
                return 'ผู้ดูแลระบบ'
                break;
            case 2:
                return 'ตัวแทน'
                break;
            case 3:
                return 'พนักงานขาย'
                break;
            case 4:
                return 'พนักงานขายรายย่อย'
                break;
            default:
                return 'เกิดข้อผิดพลาด'
                break;
        }
    }

    const showStatus = (status_active) => {
        if (status_active === 'active') {
            return (<span className="tag tag-success">เปิดใช้งาน</span>)
        } else if(status_active === 'inactive') {
            return (<span className="tag tag-danger">ถูกระงับการใช้งาน</span>)
        }
    }
    
    const delUser = (id) => {
        Swal.fire({
            title: 'คุณต้องการลบข้อมูลใช่หรือไม่',
            confirmButtonColor: '#dc3545',
            showCancelButton: true,
            confirmButtonText: 'ใช่',
            cancelButtonText: 'ไม่ใช่'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.get(process.env.REACT_APP_API_URL + '/delUser/' + id)
                .then(res => {
                    console.log(res);
                    Swal.fire('สำเร็จ',res.data.success,'success')
                    getUser()
                }).catch(err=>{
                    console.log(err)
                    Swal.fire('Error','เกิดข้อผิดพลาดบางอย่าง','error')
                })
            } else if (result.isDenied) {
                Swal.fire('Changes are not saved', '', 'info')
            }
        })
    }

    useEffect(() => {
        getUser()
    }, [props.toggle])
    return (
        <>
            <div className="card">
                <div className="table-responsive">
                    <table className="table table-hover table-vcenter text-nowrap table-striped mb-0">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>บริษัท/ตัวแทน</th>
                                <th>ชื่อ</th>
                                <th>อีเมล</th>
                                <th>สถานะการใช้งาน</th>
                                <th>การตั้งค่า</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users && users.map((user, no) => (
                                <tr key={user._id}>
                                    <td className="w60">{no+1}</td>
                                    <td>
                                        <span className="text-muted">{user.user_detail.agent_name}</span>
                                    </td>
                                    <td><div className="font-15">{user.name}</div></td>
                                    <td>{user.email}</td>
                                    <td>{showStatus(user.status_active)}</td>
                                    <td>
                                        {/* <button type="button" className="btn btn-icon btn-sm" title="View"><i className="fa fa-eye"></i></button> */}
                                        <a className="nav-link dropdown-toggle text-dark" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false"><i className="fa fa-bars" aria-hidden="true"></i></a>
                                        <div className="dropdown-menu">
                                            {/* <a className="dropdown-item" href={"/manageUserPlatinum?userId=" + user._id}><i className="fa fa-user-o"></i> จัดการสมาชิก</a> */}
                                            <a className="dropdown-item" href={"/manageUser?manageRole=platinum&userId=" + user._id}><i className="fa fa-user-o"></i> จัดการสมาชิก</a>
                                            <a className="dropdown-item" href={"/commission/" + user._id}><i className="fa fa-money" aria-hidden="true"></i> Commission</a>
                                            <a className="dropdown-item" href={"edit/agent/" + user._id}><i className="fa fa-edit"></i> แก้ไขข้อมูล</a>
                                            <a className="dropdown-item" onClick={() => delUser(user._id)}><i className="fa fa-trash-o"></i> ลบข้อมูล</a>
                                        </div>
                                        {/* <a href={'/editUser/' + user.role + '/' + user._id} className="btn btn-icon btn-sm" title="Edit"><i className="fa fa-edit"></i></a>
                                        <button type="button" onClick={() => delUser(user._id)} className="btn btn-icon btn-sm js-sweetalert" title="Delete" data-type="confirm"><i className="fa fa-trash-o text-danger"></i></button> */}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default ListUserAgent
