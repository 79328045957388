import React, { useState } from 'react'
import axios from 'axios'
import Swal from 'sweetalert2'
import Nav from '../Layout/Nav'
import Header from '../Layout/Header'
import Footer from '../Layout/Footer'

function Test() {
    const [data, setData] = useState()
    const submit = () => {
        axios.post(process.env.REACT_APP_API_URL + '/testGetFile',
        {
            Url: "https://api-uat.muangthaiinsurance.com/MtiRestService/Files/Pdf/E-58108600-24NBK-00001.pdf"
        },).then(res => {
            console.log('file :', res);
            
        }).catch(err => console.log('error => ',err))
    }
    return (
        <>
        <Nav />
            <div className='page'>
                <Header />
                <div className="section-body">
                    <div className="container-fluid">
                        <div className="d-flex justify-content-between align-items-center ">
                            <div className="header-action">
                                <h1 className="page-title">ทดสอบ</h1>
                                <ol className="breadcrumb page-breadcrumb">
                                    <li className="breadcrumb-item"><a href="/dashboard">หน้าแรก</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">ทดสอบ</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section-body mt-4">
                    <div className="container-fluid">
                        <div className='row'>
                            <div className='col-md-12 text-center'>
                                <button className='btn btn-primary mb-3' onClick={submit}>Click</button>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default Test