import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { redirect, useNavigate, useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import '../../../assets/assets/css/styleByDev.css'

function FormEditBroker() {
    const params = useParams()
    const form = useRef()
    const file = useRef()
    const [brokerName, setBrokerName] = useState()
    const [statusCMI, setStatusCMI] = useState()
    const [statusVMI, setStatusVMI] = useState()
    const [logoImg, setLogoImg] = useState()
    const [statusActive, setStatusActive] = useState()
    const [CMISedan, setCMISedan] = useState()
    const [CMIPickup, setCMIPickup] = useState()
    const [CMIVan, setCMIVan] = useState()
    const [VMIClass1, setVMIClass1] = useState()
    const [VMIClass2Plus, setVMIClass2Plus] = useState()
    const [VMIClass2, setVMIClass2] = useState()
    const [VMIClass3Plus, setVMIClass3Plus] = useState()
    const [VMIClass3, setVMIClass3] = useState()

    const [checkedVMI, setCheckedVMI] = useState()
    const [checkedCMI, setCheckedCMI] = useState()
    const [checkedStatusActive, setCheckedStatusActive] = useState()

    const [broker, setBroker] = useState()
    const navigator = useNavigate()
    const onSubmit = (event) => {
        event.preventDefault() //ไม่ให้ Browser refresh
        // const maxSize = 300
        // if (file.current.files[0].size > maxSize * 1024) {
        //     Swal.fire('แจ้งเตือน',`ขนาดของไฟล์ต้องไม่เกิน ${maxSize} KB`,'error')
        // }
        
        const formData = new FormData(form.current)
        // const formEnt = Object.fromEntries(formData.entries())
        axios.post(process.env.REACT_APP_API_URL + '/updateBroker', formData)
        .then(response=>{
            form.current.reset()
            setBroker(response.data.broker)
            navigator('/broker')
            Swal.fire('สำเร็จ',response.data.success,'success')
        }).catch(err=>{
            Swal.fire('แจ้งเตือน',err.response.data.error,'error')
        })
    }
    const getBrokerID = () => {
        axios.get(process.env.REACT_APP_API_URL + '/getBroker/' + params.id)
        .then(res => {
            // res.data[0]
            setBrokerName(res.data[0].broker_name)
            setStatusCMI(res.data[0].status_cmi)
            setStatusVMI(res.data[0].status_vmi)
            setLogoImg(res.data[0].logo)
            setStatusActive(res.data[0].status_active)
            setCMISedan(res.data[0].commission[0].cmi_sedan)
            setCMIPickup(res.data[0].commission[0].cmi_pickup)
            setCMIVan(res.data[0].commission[0].cmi_van)
            setVMIClass1(res.data[0].commission[0].vmi_class1)
            setVMIClass2Plus(res.data[0].commission[0].vmi_class2plus)
            setVMIClass2(res.data[0].commission[0].vmi_class2)
            setVMIClass3Plus(res.data[0].commission[0].vmi_class3plus)
            setVMIClass3(res.data[0].commission[0].vmi_class3)
            if (res.data[0].status_active === 1) {
                setCheckedStatusActive(true)
            } else {
                setCheckedStatusActive(false)
            }

            if (res.data[0].status_cmi === 1) {
                setCheckedCMI(true)
            } else {
                setCheckedCMI(false)
            }
            if (res.data[0].status_vmi === 1) {
                setCheckedVMI(true)
            } else {
                setCheckedVMI(false)
            }
        })
        .catch(err => console.log(err))
    }
    const handelCheckedStatusActive = (e) => {
        if (e.target.checked === true) {
            setStatusActive(1)
            setCheckedStatusActive(true)
        } else {
            setStatusActive(0)
            setCheckedStatusActive(false)
        }
    }
    const handelCheckedCMI = (e) => {
        if (e.target.checked === true) {
            setStatusCMI(1)
            setCheckedCMI(true)
        } else {
            setStatusCMI(0)
            setCheckedCMI(false)
        }
    }
    const handelCheckedVMI = (e) => {
        if (e.target.checked === true) {
            setStatusVMI(1)
            setCheckedVMI(true)
        } else {
            setStatusVMI(0)
            setCheckedVMI(false)
        }
    }
    useEffect(() => {
        getBrokerID()
    },[broker])
    return (
        <>
            <div className="row clearfix">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">ข้อมูลบริษัทประกันภัย</h3>
                        </div>
                        <div className="card-body">
                            <form ref={form} onSubmit={onSubmit}>
                                <input type="hidden" name='id' value={params.id} />
                                <div className="row clearfix">
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <label>ชื่อบริษัทประกันภัย</label>
                                            <input type="text" name="broker_name" value={brokerName} onChange={(e) => {setBrokerName(e.target.value)}} className="form-control" required />
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <h3 className="card-title">ผลิตภัณฑ์</h3>
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="checkbox" name='status_cmi' id="inlineCheckbox1" value={statusCMI} onChange={handelCheckedCMI} checked={checkedCMI} />
                                                <label className="form-check-label" for="inlineCheckbox1">ประกันภาคบังคับ (พรบ.)</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="checkbox" name='status_vmi' id="inlineCheckbox2" value={statusVMI} onChange={handelCheckedVMI} checked={checkedVMI} />
                                                <label className="form-check-label" for="inlineCheckbox2">ประกันภาคสมัครใจ (ประเภท)</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-sm-6'>
                                        <div className='row'>
                                            <div className="col-sm-12">
                                                <div className="form-group">
                                                    <h3 className="card-title">ค่า Commission ประกันภาคบังคับ (พรบ.)</h3>
                                                </div>
                                            </div>
                                            <div className="col-10">
                                                <div className="form-group">
                                                    <label>รถเก๋ง</label>
                                                    <input type="number" name="sedan" value={CMISedan} onChange={(e) => {setCMISedan(e.target.value)}} className="form-control" placeholder='0.00%' required />
                                                </div>
                                            </div>
                                            <div className="col-10">
                                                <div className="form-group">
                                                    <label>รถกระบะ</label>
                                                    <input type="number" name="pickup" value={CMIPickup} onChange={(e) => {setCMIPickup(e.target.value)}} className="form-control" placeholder='0.00%' required />
                                                </div>
                                            </div>
                                            <div className="col-10">
                                                <div className="form-group">
                                                    <label>รถตู้</label>
                                                    <input type="number" name="van" value={CMIVan} onChange={(e) => {setCMIVan(e.target.value)}} className="form-control" placeholder='0.00%' required />
                                                </div>
                                            </div>        
                                        </div>
                                    </div>
                                    <div className='col-sm-6'>
                                        <div className='row'>
                                            <div className="col-sm-12">
                                                <div className="form-group">
                                                    <h3 className="card-title">ค่า Commission ประกันภาคสมัครใจ (ประเภท)</h3>
                                                </div>
                                            </div>
                                            <div className="col-10">
                                                <div className="form-group">
                                                    <label>ประกันชั้น 1</label>
                                                    <input type="number" name="class1" value={VMIClass1} onChange={(e) => {setVMIClass1(e.target.value)}} className="form-control" placeholder='0.00%' required />
                                                </div>
                                            </div>
                                            <div className="col-5">
                                                <div className="form-group">
                                                    <label>ประกันชั้น 2+</label>
                                                    <input type="number" name="class2plus" value={VMIClass2Plus} onChange={(e) => {setVMIClass2Plus(e.target.value)}} className="form-control" placeholder='0.00%' required />
                                                </div>
                                            </div>
                                            <div className="col-5">
                                                <div className="form-group">
                                                    <label>ประกันชั้น 2</label>
                                                    <input type="texnumbert" name="class2" value={VMIClass2} onChange={(e) => {setVMIClass2(e.target.value)}} className="form-control" placeholder='0.00%' required />
                                                </div>
                                            </div>
                                            <div className="col-5">
                                                <div className="form-group">
                                                    <label>ประกันชั้น 3+</label>
                                                    <input type="number" name="class3plus" value={VMIClass3Plus} onChange={(e) => {setVMIClass3Plus(e.target.value)}} className="form-control" placeholder='0.00%' required />
                                                </div>
                                            </div>
                                            <div className="col-5">
                                                <div className="form-group">
                                                    <label>ประกันชั้น 3</label>
                                                    <input type="number" name="class3" value={VMIClass3} onChange={(e) => {setVMIClass3(e.target.value)}} className="form-control" placeholder='0.00%' required />
                                                </div>
                                            </div>        
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className='form-group'>
                                            <label>Logo</label><br/>                        
                                            <img src={process.env.REACT_APP_URL_IMG_LOGO + logoImg} alt="Photo by Nathan Guerrero" className="rounded" />
                                        </div>
                                    </div>
                                    <div className="col-sm-9">
                                        <div className='form-group'>
                                            <label className='mt-80'>อัพโหลดภาพใหม่ของคุณที่นี่</label><br/>                        
                                            <input type="file" name='logo' ref={file}/>    
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <label>การใช้งานบริษัทประกันภัย</label><br />
                                            <label className="custom-switch">
                                                <span className="custom-switch-description mr-1">ปิด</span>
                                                <input type="checkbox" value={statusActive} name="status_active" 
                                                        className="custom-switch-input btn-iconcolor" 
                                                        // onChange={(e) => {console.log(e.target.checked)}}
                                                        onChange={handelCheckedStatusActive}
                                                        checked={checkedStatusActive}
                                                />
                                                <span className="custom-switch-indicator"></span>
                                                <span className="custom-switch-description">เปิด</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <button type="submit" className="btn btn-primary mr-2">บันทึก</button>
                                        <button type="reset" className="btn btn-outline-secondary">ยกเลิก</button>
                                    </div> 
                                </div>    
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FormEditBroker

