import React, { useRef, useState } from 'react'
import { useAuthUser } from 'react-auth-kit'
import axios from 'axios'
import Swal from 'sweetalert2'

function FormDepositHead(props) {
    const auth = useAuthUser()
    const form = useRef()
    const selectMoney50 = useRef()
    const selectMoney100 = useRef()
    const selectMoney500 = useRef()
    const selectMoney1000 = useRef()
    const selectMoney5000 = useRef()
    const selectMoney10000 = useRef()
    const [amount, setAmount] = useState()
    const onSubmit = (e) => {
        e.preventDefault() //ไม่ให้ Browser refresh
        const formData = new FormData(form.current)
        const formEnt = Object.fromEntries(formData.entries())
        axios.post(process.env.REACT_APP_API_URL + '/manageCreditByHead', formEnt)
        .then(response=>{
            form.current.reset()
            setAmount()
            Swal.fire('สำเร็จ',response.data.success,'success').then((result) => {
                if (result.isConfirmed) {
                    window.location.reload()
                }
            })
        }).catch(err=>{
            console.log(err.response);
            Swal.fire('แจ้งเตือน',err.response.data.error,'error')
        })
    }
    const handleMoney = (e) => {
        setAmount(e.target.value)
    }
    const handleAmont = (e) => {
        setAmount(e.target.value)
        console.log(selectMoney50.current.checked);
        selectMoney50.current.checked = false
        selectMoney100.current.checked = false
        selectMoney500.current.checked = false
        selectMoney1000.current.checked = false
        selectMoney5000.current.checked = false
        selectMoney10000.current.checked = false
    }
    return (
        <>
            <div className='card'>
                <div className="card-header">
                    <h3 className="card-title">รายละเอียดการฝากเงิน</h3>
                    <div className="card-options ">
                        <a href="#" className="card-options-collapse" data-toggle="card-collapse"><i className="fe fe-chevron-up"></i></a>
                    </div>
                </div>
                <div className='card-body'>
                    <form ref={form} onSubmit={onSubmit}>
                        <div className="row clearfix">
                            <div className="col-sm-12">
                                <label>เลือกจำนวนเงิน</label>
                                <div className='form-group'>
                                    <div className="button-radio-money">
                                        <input type="radio" id="selectMoney" name="selectMoney" value="50" onClick={handleMoney} ref={selectMoney50} />
                                        <label className="btn btn-default" for="a25">50</label>
                                    </div>
                                    <div className="button-radio-money">
                                        <input type="radio" id="selectMoney" name="selectMoney" value="100" onClick={handleMoney} ref={selectMoney100} />
                                        <label className="btn btn-default" for="a25">100</label>
                                    </div>
                                    <div className="button-radio-money">
                                        <input type="radio" id="selectMoney" name="selectMoney" value="500" onClick={handleMoney} ref={selectMoney500} />
                                        <label className="btn btn-default" for="a25">500</label>
                                    </div>
                                    <div className="button-radio-money">
                                        <input type="radio" id="selectMoney" name="selectMoney" value="1000" onClick={handleMoney} ref={selectMoney1000} />
                                        <label className="btn btn-default" for="a25">1,000</label>
                                    </div>
                                    <div className="button-radio-money">
                                        <input type="radio" id="selectMoney" name="selectMoney" value="5000" onClick={handleMoney} ref={selectMoney5000} />
                                        <label className="btn btn-default" for="a25">5,000</label>
                                    </div>
                                    <div className="button-radio-money">
                                        <input type="radio" id="selectMoney" name="selectMoney" value="10000" onClick={handleMoney} ref={selectMoney10000} />
                                        <label className="btn btn-default" for="a25">10,000</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <label>ระบุจำนวนเงิน <span className='text-danger'>*</span></label>
                                    <input type="nummber" name='amount' value={amount} onChange={handleAmont} className="form-control" required />
                                </div>
                            </div>
                            <div className="col-sm-12">
                                <input type="hidden" name='type' value="deposit" />
                                <input type="hidden" name='channel' value="cash" />
                                <input type="hidden" name='filler' value={auth().id} />
                                <input type="hidden" name='user_id' value={props.userId} />
                                <div className='form-group'>
                                    <button type='submit' className="btn btn-primary btn-block">เพิ่มเครดิต</button>
                                </div>
                            </div> 
                        </div>
                    </form>    
                </div>
            </div>
        </>
    )
}

export default FormDepositHead
