import React, { useEffect, useState } from 'react'
import {useAuthUser, withAuthUser} from 'react-auth-kit'
import axios from 'axios'
import Swal from 'sweetalert2'

function ListUserSilver(props) {
    const [users, setUsers] = useState()
    const no = 1
    const getUser = () => {
        axios.get(process.env.REACT_APP_API_URL + "/getUser/gold/" + props.authid)
        .then(res => res.data)
        .then((users) => setUsers(users))
        .catch(err => console.log(err))
    }
    
    const showRole = (role) => {
        switch (role) {
            case 'administrator':
                return 'ผู้ดูแลระบบ'
                break;
            case 'agent':
                return 'ตัวแทน'
                break;
            case 'platinum':
                return 'Platinum'
                break;
            case 4:
                return 'พนักงานขายรายย่อย'
                break;
            default:
                return 'เกิดข้อผิดพลาด'
                break;
        }
    }

    const showStatus = (status_active) => {
        if (status_active === 'active') {
            return (<span className="tag tag-success">เปิดใช้งาน</span>)
        } else if(status_active === 'inactive') {
            return (<span className="tag tag-danger">ถูกระงับการใช้งาน</span>)
        }
    }

    const delUser = (id) => {
        axios.get(process.env.REACT_APP_API_URL + '/delUser/' + id)
        .then(res => {
            console.log(res);
            Swal.fire('สำเร็จ',res.data.success,'success')
        }).catch(err=>{
            console.log(err)
            Swal.fire('Error','เกิดข้อผิดพลาดบางอย่าง','error')
        })
    }

    useEffect(() => {
        getUser()
    }, [props.toggle])
    return (
        <>
            <div className="card">
                <div className="table-responsive">
                    <table className="table table-hover table-vcenter text-nowrap table-striped mb-0">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>ชื่อ</th>
                                <th>อีเมล</th>
                                <th>ระดับผู้ขาย</th>
                                <th>สถานะการใช้งาน</th>
                                <th>การตั้งค่า</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users && users.map((user,no) => (
                                <tr key={user._id}>
                                    <td className="w60">{no+1}</td>
                                    <td><div className="font-15">{user.user_detail.firstname} {user.user_detail.lastname}</div></td>
                                    <td>{user.email}</td>
                                    <td><span className="text-muted">{showRole(user.role)}</span></td>
                                    <td>{showStatus(user.status_active)}</td>
                                    <td>
                                        <a className="nav-link dropdown-toggle text-dark" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false"><i className="fa fa-bars" aria-hidden="true"></i></a>
                                        <div className="dropdown-menu">
                                            <a className="dropdown-item" href={"/commission/" + user._id}><i className="fa fa-money" aria-hidden="true"></i> Commission</a>
                                            <a className="dropdown-item" href={"/credit?userId=" + user._id}><img src={`https://cdn-icons-png.flaticon.com/128/2953/2953363.png`} width={18} /> จัดการเครดิต</a>
                                            <a className="dropdown-item" href={"edit/subseller/" + user._id}><i className="fa fa-edit"></i> แก้ไขข้อมูล</a>
                                            <a className="dropdown-item" onClick={() => delUser(user._id)}><i className="fa fa-trash-o"></i> ลบข้อมูล</a>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default ListUserSilver
