import React, {useState, createContext} from 'react'
import moment from 'moment'

//สร้าง Store Context
export const ContextCMI = createContext({})

export const ContextCMIProvider =  ({ children }) => {
    // initail State
    const [broker, setBroker] = useState({broker: undefined})
    const [carType, setCarType] = useState({carType: undefined, carBody: undefined})
    const [carModel, setCarModel] = useState({makeCode: undefined, indexMakeCode: undefined, modelCode: undefined, defaultModel: undefined, year: undefined, carSize: undefined, seat: undefined, weight: undefined, color: undefined, defaultColor: undefined, colorDes: undefined})
    const [carDetail, setCarDetail] = useState({registrationType: undefined, registrationNo: undefined, registrationProvince: undefined, registrationProvinceDisable: undefined, registrationYear: undefined, carCountry: undefined, carCountryDisable: undefined, chassisNumber: undefined, engineNumber: undefined})
    const [insured, setInsured] = useState({
                                                insuredType: undefined,
                                                insuredPrefix: undefined, indexInsuredPrefix: undefined,
                                                insuredName: undefined, 
                                                insuredLastname: undefined,
                                                insuredUniqueID: undefined,
                                                insuredIdType: undefined, indexInsuredIdType: undefined,
                                                nationality:undefined, indexNationality: undefined,
                                                occupation:undefined, indexOccupation: undefined,
                                                address: undefined,
                                                moo: undefined,
                                                soi: undefined,
                                                road: undefined,
                                                subdistrict: undefined, indexSubdistrict: undefined,
                                                district: undefined, indexDistrict: undefined,
                                                province: undefined, indexProvince: undefined,
                                                zipcode: undefined, indexZipcode: undefined,
                                                mobilePhoneNumber: undefined,
                                                phoneNumber: undefined,
                                                officePhoneNumber: undefined,
                                                gender: undefined, indexGender: undefined,
                                                emailAddr: 'auausana@hotmail.com',
                                                birthDay: '1999-01-01',
                                                driverPrefix: undefined,
                                                driverName: undefined,
                                                driverLastname: undefined,
                                                branchNo: undefined
                                            })
    const [purchase, setPurchase] = useState({dateStart: moment().startOf('day').format("YYYY-MM-DD"), 
                                                dateStop: moment().startOf('day').add(1, 'years').format("YYYY-MM-DD"), 
                                                price: 0, stamp: 0, vat: 0, priceNet: 0})
    //value สำหรับ return ไปให้หน้าต่าง ๆ ใช้ 
    const store = {
        broker : [broker, setBroker],
        carType : [carType, setCarType],
        carModel: [carModel, setCarModel],
        carDetail: [carDetail, setCarDetail],
        insured: [insured, setInsured],
        purchase: [purchase, setPurchase]
    }
    
    return <ContextCMI.Provider value={store}>{children}</ContextCMI.Provider>
  }
