import React, { useEffect, useState } from 'react'
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import axios from 'axios';
import Nav from '../Layout/Nav';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';

function DetailCommission() {
    const search = window.location.search
    let params = new URLSearchParams(search)
    const userId = params.get('userId')
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        'country.name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        status: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] }
    });

    const onGlobalFilterChange = (event) => {
        const value = event.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;
        setFilters(_filters);
    };
    const renderHeader = () => {
        const value = filters['global'] ? filters['global'].value : '';

        return (
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" value={value || ''} onChange={(e) => onGlobalFilterChange(e)} placeholder="ค้นหา..." />
            </span>
        );
    };
    const header = renderHeader();

    const brokerBodyTemplate = (rowData) => {
        return (
            <div className='text-center'>
                <img className="avatar" src={process.env.REACT_APP_URL_IMG_LOGO + rowData.data.logo} alt="" />
            </div>
        )
    }

    const statusBodyTemplate = (rowData) => {
        if (rowData.data.status === "paid") {
            return <span className="tag tag-green">จ่ายแล้ว</span>
        } else {
            return <span className="tag tag-danger">ค้างจ่าย</span>
        }
    }

    const nameBodyTemplate = (rowData) => {
        if (rowData.data.name === name) {
            return 'ขายเอง'
        } else {
            return rowData.data.name
        }
    }

    const commissionBodyTemplate = (rowData) => {
        return rowData.data.commission_net.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits:2})
    }

    const [commission, setCommission] = useState()
    const getCommissionRecord = () => {
        axios.get(process.env.REACT_APP_API_URL + '/getCommissionRecord/' + userId)
        .then(res => res.data)
        .then((data) => setCommission(data))
        .then((err) => console.log(err))
    }
    const [name, setName] = useState()
    const getDetailUser = () => {
        axios.get(process.env.REACT_APP_API_URL + "/getDetailUser/" + userId)
        .then(res => {
            setName(res.data.name)
        })
        .catch(err => console.log(err))
    }
    useEffect(() => {
        getCommissionRecord()
        getDetailUser()
    }, [])
    return (
        <>
            <Nav />
            <div className='page'>
                <Header />
                <div className="section-body">
                    <div className="container-fluid">
                        <div className="d-flex justify-content-between align-items-center ">
                            <div className="header-action">
                                <h1 className="page-title">รายการคืนคอมมิชชั่น</h1>
                                <ol className="breadcrumb page-breadcrumb">
                                    <li className="breadcrumb-item"><a href="/dashboard">หน้าแรก</a></li>
                                    <li className="breadcrumb-item active" aria-current="page"><a href='/redeemcommission'>รายการคืนคอมมิชชั่น</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">รายการคอมมิชชั่น</li>
                                </ol>
                            </div>
                            <ul className="nav nav-tabs page-header-tab">
                                <li className="nav-item"><a className="nav-link active" data-toggle="tab" href="#Staff-all">รายการคืนคอมมิชชั่น</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="section-body mt-4">
                    <div className="container-fluid">
                        <div className="tab-content">
                            <div className="tab-pane active" id="listVMI">
                                <h3>รายการคอมมัชชั่นของ {name}</h3>             
                                {/* <DataTable value={insurance} paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ minWidth: '50rem' }} > */}
                                <DataTable value={commission} paginator showGridlines rows={10}  dataKey="_id" filters={filters}
                                    globalFilterFields={['data.commission_type', 'data.policy_no' ]}
                                    header={header} emptyMessage="ไม่พบข้อมูล">
                                    <Column field="data.commission_type" header="ประเภท"></Column>
                                    <Column field="data.logo" header="โบรคเกอร์" body={brokerBodyTemplate}></Column>
                                    <Column field="data.broker_name" header="Broker Name" hidden></Column>
                                    <Column field="data.policy_no" header="Polycy No." ></Column>
                                    <Column field="data.commission_net" header="คอมมิชชั่น" body={commissionBodyTemplate} ></Column>
                                    <Column field="data.name" header="ได้รับคอมมิชชั่นจาก" body={nameBodyTemplate}></Column>
                                    <Column field="data.status" header="สถานะ" body={statusBodyTemplate}></Column>
                                </DataTable>
                            </div>    
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
            
        </>
    )
}

export default DetailCommission
