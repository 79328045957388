import React from 'react'
import { useAuthUser } from 'react-auth-kit'
import FormUserAgent from './form/FormUserAgent';
import FormUserPlatinum from './form/FormUserPlatinum';
import FormUserGold from './form/FormUserGold';
import FormUserSilver from './form/FormUserSilver'

function FormUser() {
    const auth = useAuthUser()
    const showForm = (role) => {
        switch (role) {
            case "administrator":
                return <FormUserAgent authid={auth().id} />
            case 'agent':
                return <FormUserPlatinum authid={auth().id} />
            case 'platinum':
                return <FormUserGold authid={auth().id} />
            case 'gole':
                return <FormUserSilver authid={auth().id} />
            default:
                return 'เกิดข้อผิดพลาด'
        }
    }
    return (
        <>
            <div className="tab-pane" id="Staff-add">
                {showForm(auth().role)}
            </div>

        </>
    )
}

export default FormUser
