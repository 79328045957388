import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from 'primereact/dropdown';
import { Tag } from 'primereact/tag';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import SaleDashboard from './SaleDashboard';
import moment from 'moment'
import Swal from 'sweetalert2';
        

function ListVMI(props) {
    const [insurance, setInsurance] = useState()
    const [brokers, setBrokers] = useState()
    const [dateStartState, setDateStartState] = useState()
    const [dateStopState, setDateStopState] = useState()
    const formFilter = useRef()
    const getBrokerVMI = () => {
        axios.get(process.env.REACT_APP_API_URL + "/getBrokerVMI")
        .then(res => res.data)
        .then((brokers) => setBrokers(brokers))
        .catch(err => console.log(err))
    }
    const getInsuranceVMI = () => {
        axios.get(process.env.REACT_APP_API_URL + '/getInsuranceVMI/' + props.userAuth)
        .then(res => res.data)
        .then((data) => setInsurance(data))
        .then((err) => console.log(err))
    }
    const getInsuranceVMIFilter = (event) => {
        event.preventDefault()
        const formData = new FormData(formFilter.current)
        const formEnt = Object.fromEntries(formData.entries())
        console.log(formEnt);
        axios.post(process.env.REACT_APP_API_URL + '/getInsuranceVMIFilter/', formEnt)
        .then(res => res.data)
        .then((data) => setInsurance(data))
        .then((err) => console.log(err))
    }
    useEffect(() => {
        getBrokerVMI()
        getInsuranceVMI()
    }, [])
    const [customers, setCustomers] = useState(null);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        'country.name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        status: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] }
    });

    const onGlobalFilterChange = (event) => {
        const value = event.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
    };

    const renderHeader = () => {
        const value = filters['global'] ? filters['global'].value : '';

        return (
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" value={value || ''} onChange={(e) => onGlobalFilterChange(e)} placeholder="ค้นหา..." />
            </span>
        );
    };

    const priceBodyTemplate = (rowData) => {
        return parseFloat(rowData.data.price).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits:2});
    };

    const priceNetBodyTemplate = (rowData) => {
        return parseFloat(rowData.data.price_net).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits:2});
    };

    const stampBodyTemplate = (rowData) => {
        return parseFloat(rowData.data.stamp).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits:2});
    };

    const vatBodyTemplate = (rowData) => {
        return parseFloat(rowData.data.vat).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits:2});
    };

    const fileBodyTemplate = (rowData) => {
        if (rowData.data.file.file_store) {
            return <a target='_blank' href={rowData.data.file.file_store} className='btn btn-light btn-sm'><i className="fa fa-file-text-o" aria-hidden="true"></i></a>
        } else {
            return <a target='_blank' href={rowData.data.file} className='btn btn-light btn-sm'><i className="fa fa-file-text-o" aria-hidden="true"></i></a>
        }
    };

    const [requestRemark, setRequestRemark] = useState()
    const [requiredRemark, setRequiredRemark] = useState()
    const [visible, setVisible] = useState(false);
    const formSendRequestCancle = useRef()
    const formCancleRequest = useRef()
    const cancleBodyTemplate = (rowData) => {
        console.log(rowData.data.broker_short_name);
        if (rowData.data.status === 'success') {
            if (rowData.data.date_start === moment().format("YYYY-MM-DD")) {
                return (<>
                    <button type="button" className="btn btn-outline-danger btn-sm" data-toggle="modal" data-target={"#exampleModal"+rowData.data._id}>
                        ส่งคำขอ
                    </button>
                    <div className="modal fade" id={"exampleModal"+rowData.data._id} tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">ส่งคำขอยกเลิกกรมธรรม์</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <form ref={formSendRequestCancle} onSubmit={handleSendRequestCancle}>
                                    <div className="modal-body">
                                        <div className='form-group text-left'>
                                            <label>กรมธรรม์เลขที่  <span className='text-danger'>*</span></label>
                                            <input type="text" className="form-control" value={rowData.data.policy_no} disabled/>
                                            <input type='hidden' name='policyNo' value={rowData.data.policy_no} />
                                        </div>
                                        <div className='form-group text-left'>
                                            <label>เหตุผลในการยกเลิกกรมธรรม์ <span className='text-danger'>*</span></label>
                                            <input type="text" className="form-control" name='requestRemark' required/>    
                                        </div>
                                        <input type='hidden' name='broker' value={rowData.data.broker_short_name} />
                                        <input type='hidden' name='_id' value={rowData.data._id} />
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-dismiss="modal">ปิด</button>
                                        <button type="submit" className="btn btn-primary">ส่งคำขอยกเลิกกรมธรรม์</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </>)     
            }               
        } else {
            if(rowData.data.status.status === 'sendRequest') {
                return (<>
                    <form ref={formCancleRequest} onSubmit={handleCancleRequest}>
                        <input type='hidden' name='_id' value={rowData.data._id} />
                        <button type="submit" className="btn btn-outline-warning btn-sm"> ยกเลิกคำขอกรมธรรม์ </button>
                    </form>
                </>)
            } else if(rowData.data.status.status === 'cancle') {
                return ''
            }
        }
    };

    const handleSendRequestCancle = (event) => {
        event.preventDefault() //ไม่ให้ Browser refresh
        const formData = new FormData(formSendRequestCancle.current)
        const formEnt = Object.fromEntries(formData.entries())
        axios.post(process.env.REACT_APP_API_URL + "/sendRequestCanclePolicy", formEnt)
        .then(response=>{
            Swal.fire("สำเร็จ", response.data.success, 'success').then((result) => {
                if (result.isConfirmed) {
                    window.location.reload()
                    getInsuranceVMI()
                }
            })
        }).catch(err=>{
            Swal.fire('แจ้งเตือน',err.response.data.error,'error')
        })
    }
    const handleCancleRequest = (event) => {
        event.preventDefault() //ไม่ให้ Browser refresh
        const formData = new FormData(formCancleRequest.current)
        const formEnt = Object.fromEntries(formData.entries())
        axios.post(process.env.REACT_APP_API_URL + "/cancleSendRequestCanclePolicy", formEnt)
        .then(response=>{
            Swal.fire("สำเร็จ", response.data.success, 'success').then((result) => {
                if (result.isConfirmed) {
                    getInsuranceVMI()
                }
            })
        }).catch(err=>{
            Swal.fire('แจ้งเตือน',err.response.data.error,'error')
        })
    }

    const brokerBodyTemplate = (rowData) => {
        return (
            <div className='text-center'>
                <img className="avatar" src={process.env.REACT_APP_URL_IMG_LOGO + rowData.data.logo} alt="" />
            </div>
        )
    }

    const nameBodyTemplate = (rowData) => {
        if (rowData.data.lastname) {
            if (rowData.data.lastname === undefined) {
                return rowData.data.insured_prefix+rowData.data.name
            } else {
                return rowData.data.name+' '+rowData.data.lastname
            }
        } else {
            if (rowData.data.insured_prefix === 'ว่าง') {
                return rowData.data.name
            } else {
                return rowData.data.insured_prefix+rowData.data.name
            }
        }
    };

    const actuallyPaidBodyTemplate = (rowData) => {
        return parseFloat(rowData.data.actually_paid).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits:2});
    };

    const statusBodyTemplate = (rowData) => {
        if (rowData.data.status === "success") {
            return <span className="tag tag-green">สำเร็จ</span>
        } else {
            if (rowData.data.status.status === 'sendRequest') {
                return <span className="tag tag-warning">ส่งคำขอยกเลิก</span>
            } else {
                return <span className="tag tag-danger">ยกเลิก</span>
            }
            
        }
    }

    const commissionBodyTemplate = (rowData) => {
        const commission =  rowData.data.price_net-rowData.data.actually_paid
        return (<>
            {parseFloat(commission).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits:2})}
            <small className='ml-1 text-success'><span className=" badge badge-light text-success rounded-pill">{rowData.data.commission_percent}%</span></small>
        </>);
    };

    const header = renderHeader();
    console.log('insurance => ', insurance);
    return (
        <>
            <div className="tab-pane active" id="listVMI">
                {/* <SaleDashboard userAuth={props.userAuth} /> */}
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <form ref={formFilter} onSubmit={getInsuranceVMIFilter}>
                                    <div className="row">
                                        <div className='col-3'>
                                            <div className="form-group">
                                                <label>วันที่</label>
                                                <input type="date" className="form-control" name="dateStart" value={dateStartState}
                                                    onChange={(e) => {
                                                        setDateStartState(e.target.value)
                                                    }} />
                                            </div>
                                        </div>
                                        <div className='col-3'>
                                            <div className="form-group ml-3">
                                                <label>ถึง วันที่</label>
                                                <input type="date" className="form-control" name="dateStop" value={dateStopState}
                                                    onChange={(e) => {
                                                        setDateStopState(e.target.value)
                                                    }} />
                                            </div>
                                        </div>
                                        <div className='col-3'>
                                            <div className="form-group ml-3">
                                                <label>บริษัท</label>
                                                <select className="form-control show-tick" name='brokerName'>
                                                    <option value="all">-- ทั้งหมด --</option>
                                                    {brokers && brokers.map((broker) => (
                                                        <option value={broker.broker_short_name}>{broker.broker_name}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className='col-3'>
                                            <div className='form-group ml-3 mt-2'>
                                                <br/>
                                                <input type='hidden' name='userId' value={props.userAuth}/>
                                                <button type="submit" className="btn btn-primary btn-block">ค้นหากรมธรรม์</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <DataTable value={insurance} paginator showGridlines rows={10}  dataKey="data._id" filters={filters}
                    globalFilterFields={['data.logo', 'data.broker_name', 'data.policy_no', 'data.contract_number', 'data.name', 'data.lastname', 'data.registration_no', 'data.car_type', 'data.price', 'data.stamp', 'data.vat', 'data.price_net' ]}
                    header={header} emptyMessage="ไม่พบข้อมูล">
                    <Column className='font-prompt' field="data.logo" header="โบรคเกอร์" body={brokerBodyTemplate} sortable></Column>
                    <Column className='font-prompt' field="data.broker_name" header="Broker Name" hidden sortable></Column>
                    <Column className='font-prompt' field="data.created_at" header="วันที่แจ้งงาน" sortable></Column>
                    <Column className='font-prompt' field="data.policy_no" header="เลขกรมธรรม์" style={{ minWidth: '150px' }} sortable></Column>
                    <Column className='font-prompt' field="data.contract_number" header="เลขอ้างอิง" style={{ minWidth: '150px' }} sortable></Column>
                    <Column className='font-prompt' field="data.name, data.lastname" header="ชื่อ" body={nameBodyTemplate} style={{ minWidth: '200px' }} sortable></Column>
                    <Column className='font-prompt' field="data.registration_no" header="ทะเบียนรถ" sortable></Column>
                    <Column className='font-prompt' field="data.car_type" header="ประเภทรถ" sortable></Column>
                    <Column className='font-prompt' field="data.price_net" header="เบี้ยสุทธิ" body={priceNetBodyTemplate} sortable></Column>
                    <Column className='font-prompt' field="data.actually_paid" header="เบี้ยนำส่ง" body={actuallyPaidBodyTemplate} sortable></Column>
                    <Column className='font-prompt' field="data.actually_paid, data.price_net, data.commission_percent" header="คอมมิชชั่น" body={commissionBodyTemplate} sortable></Column>
                    <Column className='font-prompt text-center' field="data.file" header="กรมธรรม์" body={fileBodyTemplate} sortable></Column>
                    <Column className='font-prompt' field="data.status" header="สถานะ" body={statusBodyTemplate} sortable></Column>
                    {/* <Column className='font-prompt text-center' field="data.policy_no, data.status, data._id, data.date_start data.broker_short_name" header="จัดการกรมธรรม์" body={cancleBodyTemplate} style={{ minWidth: '150px' }} sortable></Column> */}
                </DataTable>
            </div>
            
        </>
    )
}

export default ListVMI
