import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Nav from '../Layout/Nav'
import Header from '../Layout/Header'
import Footer from '../Layout/Footer'
import ViewCreadit from './ViewCreadit'
import Deposit from './Deposit'
import Withdraw from './Withdraw'
import { useAuthUser } from 'react-auth-kit'

function Credit() {
    const auth = useAuthUser()
    const search = window.location.search
    let params = new URLSearchParams(search)
    const userId = params.get('userId')
    const [creditTotal, setCreditTotal] = useState()
    const getCreditTotal = () => {
        axios.get(process.env.REACT_APP_API_URL + "/getCreditTotal/" + userId)
        .then(res => res.data)
        .then((creditTotal) => setCreditTotal(creditTotal))
        .catch(err => console.log(err))
    }
    useEffect(() => {
        getCreditTotal()
    }, [])
    return (
        <>
            <Nav />
            <div className='page'>
                <Header />
                <div className="section-body">
                    <div className="container-fluid">
                        <div className="d-flex justify-content-between align-items-center ">
                            <div className="header-action">
                                <h1 className="page-title">เครดิต</h1>
                                <ol className="breadcrumb page-breadcrumb">
                                    <li className="breadcrumb-item"><a href="/dashboard">หน้าแรก</a></li>
                                    {auth().id !== userId && <li className="breadcrumb-item"><a href="/user">ผู้ใช้งาน</a></li>}
                                    <li className="breadcrumb-item active" aria-current="page">เครดิต</li>
                                </ol>
                            </div>
                            <ul className="nav nav-tabs page-header-tab">
                                <li className="nav-item"><a className="nav-link active" data-toggle="tab" href="#viewCreadit">บันทึกรายการ</a></li>
                                <li className="nav-item"><a className="nav-link" data-toggle="tab" href="#deposit">ฝาก</a></li>
                                {auth().role === 'administrator' && 
                                    <li className="nav-item"><a className="nav-link" data-toggle="tab" href="#withdraw">ถอน</a></li>
                                }
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="section-body mt-4">
                    <div className="container-fluid">
                        <div className="tab-content">
                            <ViewCreadit userId={userId} creditTotal={creditTotal} authName={auth().name} />
                            <Deposit userId={userId} creditTotal={creditTotal} />
                            {auth().role === 'administrator' && 
                                <Withdraw userId={userId} creditTotal={creditTotal} />
                            }
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default Credit
