import axios from 'axios'
import React, { useRef } from 'react'
import Swal from 'sweetalert2'
import { useAuthUser } from 'react-auth-kit'

function FormUserAdmin() {
    const form = useRef()
    const roleSelectFeild = useRef()
    const [selectRole, setSelectRole] = React.useState()
    const onSubmit = (event) => {
        event.preventDefault() //ไม่ให้ Browser refresh
        const formData = new FormData(form.current)
        const formEnt = Object.fromEntries(formData.entries())
        axios.post(process.env.REACT_APP_API_URL + '/saveUser', formEnt)
        .then(response=>{
            form.current.reset()
            setSelectRole()
            Swal.fire('สำเร็จ','เพิ่มผู้ใช้งานสำเร็จ','success')
        }).catch(err=>{
            console.log(err.response);
            Swal.fire('แจ้งเตือน',err.response.data.error,'error')
        })
    }
    const setRole = () => {
        if (roleSelectFeild.current.value === '2') {
            setSelectRole(2)
        } else {
            setSelectRole()
        }
        
    }
    return (
        <>
            <div className="tab-pane" id="formuseradmin">
                <div className="row clearfix">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <form ref={form} onSubmit={onSubmit}>
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">ข้อมูลผู้ดูแลระบบ</h3>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="form-group">
                                                <label>ชื่อผู้ใช้งาน</label>
                                                <input type="text" name="name" className="form-control" required />
                                            </div>
                                        </div>
                                        <div className="col-sm-12">
                                            <div className="form-group">
                                                <label>อีเมล</label>
                                                <input name='email' type="email" className="form-control" required />
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label>รหัสผ่าน</label>
                                                <input name='password' type="password" className="form-control" required />
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label>ยืนยันรหัสผ่าน</label>
                                                <input name='confirmPassword' type="password" className="form-control" required />
                                            </div>
                                        </div>
                                    </div>
                                    <input type="hidden" name='role' value="administrator" />
                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className="col-sm-12">
                                    <button type="submit" className="btn btn-primary mr-2">บันทึก</button>
                                    <button type="reset" className="btn btn-outline-secondary">ยกเลิก</button>
                                </div> 
                            </div>
                        </form>      
                    </div>
                </div>
            </div>
            
        </>
    )
}

export default FormUserAdmin
