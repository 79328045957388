import React from 'react'
import { useAuthUser } from 'react-auth-kit'
import ListUserAgent from './listUser/ListUserAgent'
import ListUserLevel from './listUser/ListUserLevel'
import ListUserPlatinum from './listUser/ListUserPlatinum'
import ListUserGold from './listUser/ListUserGold'
import ListUserSilver from './listUser/ListUserSilver'

function ListUser(props) {
    const auth = useAuthUser()
    const showListUser = (role) => {
        switch (role) {
            case "administrator":
                return <ListUserAgent toggle={props.toggle} authid={auth().id} role="agent" />
            case 'agent':
                return <ListUserPlatinum toggle={props.toggle} authid={auth().id} role="platinum" />
            case 'platinum':
                return <ListUserGold toggle={props.toggle} authid={auth().id} role="gold" />
            case 'gole':
                return <ListUserSilver toggle={props.toggle} authid={auth().id} role="silver" />
            default:
                return 'เกิดข้อผิดพลาด'
        }
    }

    return (
        <>
            <div className="tab-pane active" id="Staff-all">
                {showListUser(auth().role)}
            </div>
        </>
    )
}

export default ListUser
