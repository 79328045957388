import React, { useEffect, useState } from 'react'
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import axios from 'axios';

function ListRedeemCommission(props) {
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        'country.name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        status: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] }
    });

    const onGlobalFilterChange = (event) => {
        const value = event.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;
        setFilters(_filters);
    };
    const renderHeader = () => {
        const value = filters['global'] ? filters['global'].value : '';

        return (
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" value={value || ''} onChange={(e) => onGlobalFilterChange(e)} placeholder="ค้นหา..." />
            </span>
        );
    };
    const header = renderHeader();

    const manageBodyTemplate = (rowData) => {
        if (rowData.data.amount_unpaid > 0) {
            return (
                <>
                    <a className="nav-link dropdown-toggle text-dark" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false"><i className="fa fa-bars" aria-hidden="true"></i></a>
                    <div className="dropdown-menu">
                        <a className="dropdown-item" href={"/detailcommission?userId=" + rowData.data._id}>ดูรายละเอียดคอมมิชชั่น</a>
                        <a className="dropdown-item" onClick={() => paid(rowData.data._id)}>ทำเครื่องหมายว่าจ่ายแล้ว</a>
                    </div>
                </>
            )    
        }  else {
            return (
                <>
                    <a className="nav-link dropdown-toggle text-dark" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false"><i className="fa fa-bars" aria-hidden="true"></i></a>
                    <div className="dropdown-menu">
                        <a className="dropdown-item" href={"/detailcommission?userId=" + rowData.data._id}>ดูรายละเอียดคอมมิชชั่น</a>
                    </div>
                </>
            ) 
        }  
        
    }

    const paidBodyTemplate = (rowData) => {
        return <span className='text-success'>{parseFloat(rowData.data.amount_paid).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits:2})}</span>
    }
    const unpaidBodyTemplate = (rowData) => {
        return <span className='text-danger'>{parseFloat(rowData.data.amount_unpaid).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits:2})}</span>
    }
    const sellerBodyTemplate = (rowData) => {
        let role
        switch (rowData.data.user[0].role) {
            case "administrator":
                role = <span className=' badge badge-primary'>administrator</span>
                break;
            case "agent":
                role = <span className=' badge badge-primary'>agent</span>
                break;
            case "platinum":
                role = <span className=' badge badge-default'>platinum</span>
                break;
            case "gold":
                role = <span className=' badge badge-warning'>gold</span>
                break;
            case "silver":
                role = <span className=' badge bg-light-gray text-dark'>silver</span>
                break;
        
            default:
                break;
        }
        return <>
            {rowData.data.name} <br/>
            {role}
        </>
    }

    const paid = (id) => {
        axios.get(process.env.REACT_APP_API_URL + '/updateStatusPaid/'+ id)
        .then(res =>{ 
            console.log(res.data)
            getCommissionUnpaid()
        })
        .then((err) => console.log(err))
    }

    const [commissionUnpaid, setCommissionUnpaid] = useState()
    const getCommissionUnpaid = () => {
        axios.get(process.env.REACT_APP_API_URL + '/getCommissionUnpaid')
        .then(res => res.data)
        .then((data) => {
            setCommissionUnpaid(data)
            console.log(data);
        })
        .then((err) => console.log(err))
    }
    useEffect(() => {
        getCommissionUnpaid()
    }, [])
    return (
        <>
            <div className="tab-pane active" id="listVMI">              
                {/* <DataTable value={insurance} paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ minWidth: '50rem' }} > */}
                <DataTable value={commissionUnpaid} paginator showGridlines rows={10}  dataKey="data._id" filters={filters}
                    globalFilterFields={['data.name', 'data.amount_paid', 'data.amount_unpaid']}
                    header={header} emptyMessage="ไม่พบข้อมูล">
                    <Column field="data.name" header="ผู้ขาย" body={sellerBodyTemplate} sortable></Column>
                    <Column field="data.amount_paid" header="คอมมิชชั่นจ่ายแล้ว" body={paidBodyTemplate} sortable></Column>
                    <Column field="data.amount_unpaid" header="คอมมิชชั่นค้างจ่าย" body={unpaidBodyTemplate} sortable></Column>
                    <Column field="data._id, data.amount_unpaid" header="จัดการ" body={manageBodyTemplate} sortable></Column>
                </DataTable>
            </div>
        </>
    )
}

export default ListRedeemCommission
