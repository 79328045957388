import React, { useEffect, useState } from 'react'
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import axios from 'axios';

function ListCommission(props) {
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        'country.name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        status: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] }
    });

    const onGlobalFilterChange = (event) => {
        const value = event.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;
        setFilters(_filters);
    };
    const renderHeader = () => {
        const value = filters['global'] ? filters['global'].value : '';

        return (
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" value={value || ''} onChange={(e) => onGlobalFilterChange(e)} placeholder="ค้นหา..." />
            </span>
        );
    };
    const header = renderHeader();

    const brokerBodyTemplate = (rowData) => {
        return (
            <div className='text-center'>
                <img className="avatar" src={process.env.REACT_APP_URL_IMG_LOGO + rowData.data.logo} alt="" />
            </div>
        )
    }

    const statusBodyTemplate = (rowData) => {
        if (rowData.data.status === "paid") {
            return <span className="tag tag-green">จ่ายแล้ว</span>
        } else if(rowData.data.status === "canclePolicy") {
            return <span className="tag tag-danger">ยกเลิกกรมธรรม์</span>
        } else if(rowData.data.status === "unpaid") {
            return <span className="tag tag-danger">ค้างจ่าย</span>
        }
    }
    const commissionTypeBodyTemplate = (rowData) => {
        if (rowData.data.commission_type === "CMI") {
            return <span className="tag tag-azure">พรบ.</span>
        } else {
            return <span className="tag tag-azure">ประกันภาคสมัครใจ</span>
        }
    }

    const commissionBodyTemplate = (rowData) => {
        return rowData.data.commission_net.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits:2})
    }

    const [commission, setCommission] = useState()
    const getCommissionRecord = () => {
        axios.get(process.env.REACT_APP_API_URL + '/getCommissionRecord/' + props.authId)
        .then(res => res.data)
        .then((data) => setCommission(data))
        .then((err) => console.log(err))
    }
    useEffect(() => {
        getCommissionRecord()
    }, [])
    return (
        <>
            <div className="tab-pane active" id="listVMI">              
                {/* <DataTable value={insurance} paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ minWidth: '50rem' }} > */}
                <DataTable value={commission} paginator showGridlines rows={10}  dataKey="id" filters={filters}
                    globalFilterFields={['data.commission_type' ]}
                    header={header} emptyMessage="ไม่พบข้อมูล">
                    <Column field="data.commission_type" header="ประเภท" body={commissionTypeBodyTemplate} sortable></Column>
                    <Column field="data.logo" header="โบรคเกอร์" body={brokerBodyTemplate} sortable></Column>
                    <Column field="data.broker_name" header="Broker Name" hidden sortable></Column>
                    <Column field="data.policy_no" header="Polycy No."  sortable></Column>
                    <Column field="data.commission_net" header="คอมมิชชั่น" body={commissionBodyTemplate}  sortable></Column>
                    <Column field="data.name" header="ได้รับคอมมิชชั่นจาก"  sortable></Column>
                    <Column field="data.status" header="สถานะ" body={statusBodyTemplate} sortable></Column>
                </DataTable>
            </div>
        </>
    )
}

export default ListCommission
