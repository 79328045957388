import React from 'react'
import Nav from '../Layout/Nav'
import Header from '../Layout/Header'
import Footer from '../Layout/Footer'

function DashboardSubSeller() {
    return (
        <>
            <Nav />
            <div className='page'>   
                <Header />
                <div className="section-body">
                    <div className="container-fluid">
                        <div className="d-flex justify-content-between align-items-center ">
                            <div className="header-action">
                                <h1 className="page-title">Dashboard</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section-body mt-4">
                    <div className="container-fluid">
                        <div className='row'>
                            <div className='col-xl-4'>
                                <div className='card'>
                                    <div className='card-header'>
                                        <span className="card-title">คอมมิชชั่นทั้งหมด (บาท)</span>
                                    </div>
                                    <div className='card-body text-right'>
                                        <h1>{parseInt(0).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits:2})}</h1>
                                    </div>
                                </div>        
                            </div>
                            <div className='col-xl-4'>
                                <div className='card'>
                                    <div className='card-header'>
                                        <span className="card-title">ยอดขายทั้งหมด (บาท)</span>
                                    </div>
                                    <div className='card-body text-right'>
                                        <h1>{parseInt(0).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits:2})}</h1>
                                    </div>
                                </div>        
                            </div>
                            <div className='col-xl-4'>
                                <div className='card'>
                                    <div className='card-header'>
                                        <span className="card-title">จำนวนกรมธรรม์ (ฉบับ)</span>
                                    </div>
                                    <div className='card-body text-right'>
                                        <h1>0</h1>
                                    </div>
                                </div>        
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-xl-6 col-lg-6 col-md-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title">ประกันภาคบังคับ (พรบ.)</h3>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table card-table table-vcenter text-nowrap table-striped mb-0">
                                            <tbody>
                                                <tr>
                                                    <td>ยอดขายทั้งหมด</td>
                                                    <td className='text-right'>99.00</td>
                                                </tr>
                                                <tr>
                                                    <td>คอมมิชชั่นทั้งหมด</td>
                                                    <td className='text-right'>98.00</td>
                                                </tr>
                                                <tr>
                                                    <td>จำนวนกรมธรรม์</td>
                                                    <td className='text-right'>197</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title">ประกันภาคสมัครใจ (ประกันชั้น)</h3>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table card-table table-vcenter text-nowrap table-striped mb-0">
                                            <tbody>
                                                <tr>
                                                    <td>ยอดขายทั้งหมด</td>
                                                    <td className='text-right'>99.00</td>
                                                </tr>
                                                <tr>
                                                    <td>คอมมิชชั่นทั้งหมด</td>
                                                    <td className='text-right'>98.00</td>
                                                </tr>
                                                <tr>
                                                    <td>จำนวนกรมธรรม์</td>
                                                    <td className='text-right'>197</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>    
                    
            
        </>
    )
}

export default DashboardSubSeller
