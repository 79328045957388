import axios from 'axios'
import React, { useEffect, useState } from 'react'
import moment from 'moment'

function ViewCreadit(props) {
    const [creditTotal, setCreditTotal] = useState()
    const [credit, setCredit] = useState()
    const [no, setNo] = useState(1)
    const getCredit = () => {
        axios.get(process.env.REACT_APP_API_URL + "/getCredit/" + props.userId)
        .then(res => res.data)
        .then((credit) => setCredit(credit))
        .catch(err => console.log(err))
    }
    const toThaiDateString = (date) => {
        const dateConvert = moment(date).format("YYYY-MM-DD hh:mm")
        let monthNames = ["",
            "ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.",
            "พ.ค.", "มิ.ย.", "ก.ค.", "ส.ค..",
            "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."
        ];
    
        const yearConvertToInt = parseInt(moment(date).format("YYYY"), 10)
        const monthConvertToInt = parseInt(moment(date).format("MM"), 10)
        let year = yearConvertToInt + 543;
        let month = monthNames[monthConvertToInt];
        let numOfDay = parseInt(moment(date).format("DD"), 10)
        let time = moment(date).format("HH:mm")
    
        return `${numOfDay} ${month} ${year}`;
    }

    const typeList = (type, channel) => {
        if (type === 'deposit') {
            if (channel === 'cash') {
                return <span className="tag tag-green">ฝากโดยผู้ดูแลระบบ</span>
            } else {
                return <span className="tag tag-green">เติมเครดิต</span>
            }
        } else {
            if (channel === 'cash') {
                return <span className="tag tag-red">ถอนเงิน</span>
            } else if(channel === 'buyInsured') {
                return <span className="tag tag-blue">ซื้อประกัน</span>
            } else {
                return <span className="tag tag-warning">{channel}</span>
            }
        }
    }
    useEffect(() => {
        getCredit()
    }, [])
    return (
        <>
            <div className="tab-pane active" id="viewCreadit">
                <div className="row">
                    <div className="col-xl-4 col-md-12">
                        <div className='card'>
                            <div className='card-header'>
                                <span className="card-title">เครดิตคงเหลือ</span>
                            </div>
                            <div className='card-body text-right'>
                                <h1>{parseFloat(props.creditTotal).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits:2})}</h1>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="table-responsive">
                                    {/* <table className="table table-hover text-nowrap js-basic-example dataTable table-striped table_custom border-style spacing5"> */}
                                    <table className="table table-hover text-nowrap table-striped table_custom border-style spacing5">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>วันที่ทำรายการ</th>
                                                <th>เวลาทำรายการ</th>
                                                <th>ประเภทรายการ</th>
                                                <th>จำนวนเงิน</th>
                                                <th>ผู้ทำรายการ</th>
                                                <th>สถานะ</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {credit && credit.map((cre, no)=>(
                                                <tr key={cre._id}>
                                                    <td>{no+1}</td>
                                                    {/* <td><Moment>{cre.created_at}</Moment></td> */}
                                                    <td>{toThaiDateString(cre.created_at)}</td>
                                                    <td>{moment(cre.created_at).format("HH:mm")} น.</td>
                                                    <td>
                                                        {typeList(cre.type, cre.channel)}
                                                        
                                                    </td>
                                                    <td>{cre.amount.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits:2})}</td>
                                                    <td>{props.authName === cre.userJoin[0].name ? <p>ฉัน</p>  : cre.userJoin[0].name}</td>
                                                    <td>
                                                        {cre.status === 'success' && <span className="tag tag-green">สำเร็จ</span>}
                                                        {cre.status === 'fail' && <span className="tag tag-red">ไม่สำเร็จ</span>}
                                                        {cre.status === 'pending' && <span className="tag tag-warning">รอการบันทึก</span>}
                                                    </td>
                                                </tr>    
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        </>
    )
}

export default ViewCreadit
