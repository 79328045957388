
import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import Select from 'react-select'
import Swal from 'sweetalert2'
import { Box, CircularProgress, LinearProgress } from '@mui/material'

function Package({ formProps: { register, formState: {errors}, setValue }, data}) {
    const {broker} = data[0][0]
    const { carType, carBody } = data[1][0]
    const { makeCode, indexMakeCode, modelCode, defaultModel, subModelCode, defaultSubModel, year, carSize, seat, weight, color, defaultColor } = data[2][0]
    const { registrationType, registrationNo, registrationProvince, registrationProvinceDisable, registrationYear, 
            carCountry, carCountryDisable, chassisNumber, engineNumber } = data[3][0]
    const { packageId, packageDetail, campaignCode, packageType, net, scale, stamp, sumInsured, total, vat } = data[4][0]
    const [selectPackage, setSelectPackage] = useState()
    const [packageSelectedTotal, setPackageSelectedTotal] = useState(total)
    const [packageSelectedInsuredSum, setPackageSelectedInsuredSum] = useState(sumInsured)
    const [thisPackage, setThisPackage] = useState()
    const [loading, setLoading] = useState('none')
    const getPackageAll = () => {
        setLoading()
        axios.post(process.env.REACT_APP_API_URL + "/getPackageAll",
        {
            "broker": broker,
            "carType": carType,
            "weight": weight,
            "carSize": carSize,
            "makeCode": makeCode,
            "modelCode": modelCode,
            "subModelCode": subModelCode,
            "year": year,
            "registrationProvince": registrationProvince
        })
        .then(res => res.data)
        .then((thisPackage) => {
            console.log(thisPackage);
            setLoading('none')
            if (thisPackage === null) {
                Swal.fire({
                    title: 'แจ้งเตือน',
                    icon: 'warning',
                    text: 'ไม่พบแพ็คเกจที่เหมาะสมกับรถยนต์',
                    allowOutsideClick: false,
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload()
                    }
                })
            } else {
                setThisPackage(thisPackage)
            }
        })
        .catch(err => console.log(err))
        console.log('getPackage');
        console.log(thisPackage);
    }

    const getPackage = (pack) => {
        setLoading()
        setThisPackage([])
        axios.post(process.env.REACT_APP_API_URL + "/getPackage",
        {
            "broker": broker,
            "carType": carType,
            "weight": weight,
            "carSize": carSize,
            "package": pack,
            "makeCode": makeCode,
            "modelCode": modelCode,
            "subModelCode": subModelCode,
            "year": year,
            "registrationProvince": registrationProvince
        })
        .then(res => res.data)
        .then((thisPackage) => {
            setThisPackage(thisPackage)
            setLoading('none')
        })
        .catch(err => console.log(err))
        console.log(thisPackage);
    }

    const handleFilter = (e) => {
        if (e.target.value === 'all') {
            console.log('all');
            console.log(e.target.value);
            getPackageAll()
        } else {
            getPackage(e.target.value)
            console.log('other');
        }
    }

    const handlePackage = (pack) => {
        setValue('packageType', pack.package_type)
        setValue('sumInsured', pack.sum_insured)
        setValue('net', pack.net)
        setValue('stamp', pack.stamp)
        setValue('vat', pack.vat)
        setValue('total', pack.total)
        setValue('campaignCode', pack.campaign_code)
        setValue('packageId', pack.package_id)
        setValue('packageDetail', pack.package_detail)
        console.log(pack);
    }

    const showPackageType = (packageType) => {
        switch (packageType) {
            case 'VMI1':
                return '1'
                break;
            case 'VMI2+':
                return '2+'
                break;
            case 'VMI2':
                return '2'
                break;
            case 'VMI3+':
                return '3+'
                break;
            case 'VMI3':
                return '3'
                break;
            default:
                return packageType
                break;
        }
    }
    useEffect(() => {
        getPackageAll()
    }, [])
    console.log(thisPackage);
    return (
        <>
            <div className="card">
                <div className="card-header">
                    <h3 className="card-title">ค้นหาแผนประกัน</h3>
                </div>
                <div className="card-body">
                    <div className="row clearfix">
                        <div className="col-12">
                            <div className="card">
                                <div className='card-body'>
                                    <div className="row">
                                        <div className='col-lg-12'>
                                            <label>ประเภทประกัน</label>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className='form-group'>
                                                <div className="button-radio-insured">
                                                    <input type="radio" id="insuredAll" name="packageType" value="all" onClick={(e) => handleFilter(e)} />
                                                    <label className="btn btn-default" for="insuredAll">ทั้งหมด</label>
                                                </div>
                                                {broker === 'deves' && 
                                                    <div className="button-radio-insured">
                                                        <input type="radio" id="insured2plus" name="packageType" value="1+" onClick={(e) => handleFilter(e)} />
                                                        <label className="btn btn-default" for="insured2plus">ประกันชั้น 1+</label>
                                                    </div>
                                                }
                                                <div className="button-radio-insured">
                                                    <input type="radio" id="insured1" name="packageType" value="1" onClick={(e) => handleFilter(e)} />
                                                    <label className="btn btn-default" for="insured1">ประกันชั้น 1</label>
                                                </div>
                                                {broker === 'deves' &&
                                                    <>
                                                        <div className="button-radio-insured">
                                                            <input type="radio" id="insured2plus" name="packageType" value="2++flood" onClick={(e) => handleFilter(e)} />
                                                            <label className="btn btn-default" for="insured2plus">2++ น้ำท่วม</label>
                                                        </div>
                                                        <div className="button-radio-insured">
                                                            <input type="radio" id="insured2plus" name="packageType" value="2++" onClick={(e) => handleFilter(e)} />
                                                            <label className="btn btn-default" for="insured2plus">ประกันชั้น 2++</label>
                                                        </div>
                                                        <div className="button-radio-insured">
                                                            <input type="radio" id="insured2plus" name="packageType" value="2+flood" onClick={(e) => handleFilter(e)} />
                                                            <label className="btn btn-default" for="insured2plus">2+ น้ำท่วม</label>
                                                        </div>
                                                    </>
                                                }
                                                <div className="button-radio-insured">
                                                    <input type="radio" id="insured2plus" name="packageType" value="2+" onClick={(e) => handleFilter(e)} />
                                                    <label className="btn btn-default" for="insured2plus">ประกันชั้น 2+</label>
                                                </div>
                                                {broker === 'deves' && 
                                                    <div className="button-radio-insured">
                                                        <input type="radio" id="insured3" name="packageType" value="2safeguard" onClick={(e) => handleFilter(e)} />
                                                        <label className="btn btn-default" for="insured3">2 คุ้มกัน</label>
                                                    </div>
                                                }
                                                <div className="button-radio-insured">
                                                    <input type="radio" id="insured2" name="packageType" value="2" onClick={(e) => handleFilter(e)} />
                                                    <label className="btn btn-default" for="insured2">ประกันชั้น 2</label>
                                                </div>
                                                {broker === 'deves' &&
                                                    <>
                                                        <div className="button-radio-insured">
                                                            <input type="radio" id="insured2plus" name="packageType" value="3++flood" onClick={(e) => handleFilter(e)} />
                                                            <label className="btn btn-default" for="insured2plus">3++ น้ำท่วม</label>
                                                        </div>
                                                        <div className="button-radio-insured">
                                                            <input type="radio" id="insured2plus" name="packageType" value="3++" onClick={(e) => handleFilter(e)} />
                                                            <label className="btn btn-default" for="insured2plus">ประกันชั้น 3++</label>
                                                        </div>
                                                        <div className="button-radio-insured">
                                                            <input type="radio" id="insured2plus" name="packageType" value="3+flood" onClick={(e) => handleFilter(e)} />
                                                            <label className="btn btn-default" for="insured2plus">3+ น้ำท่วม</label>
                                                        </div>
                                                    </>
                                                    
                                                }
                                                <div className="button-radio-insured">
                                                    <input type="radio" id="insured3plus" name="packageType" value="3+" onClick={(e) => handleFilter(e)} />
                                                    <label className="btn btn-default" for="insured3plus">ประกันชั้น 3+</label>
                                                </div>
                                                {broker === 'deves' && 
                                                    <div className="button-radio-insured">
                                                        <input type="radio" id="insured3" name="packageType" value="3safeguard" onClick={(e) => handleFilter(e)} />
                                                        <label className="btn btn-default" for="insured3">3 คุ้มกัน</label>
                                                    </div>
                                                }
                                                <div className="button-radio-insured">
                                                    <input type="radio" id="insured3" name="packageType" value="3" onClick={(e) => handleFilter(e)} />
                                                    <label className="btn btn-default" for="insured3">ประกันชั้น 3</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="row clearfix">
                        <Box sx={{ width: '100%', display: loading }} >
                            <LinearProgress />
                        </Box>    
                        {thisPackage && thisPackage.map((p) => (
                            <div className="col-xl-4 col-lg-4 col-md-6">
                                <label className='card-element' key={p._id}>
                                    <input type="radio" name="packageId" className="card-input-element" value={p.package_id} onClick={() => handlePackage(p)} {...register('packageId', {required: "กรุณาเลือกแพ็คเกจประกันภัย"})} />
                                    <div className="card card-input">
                                        <div className="card-body d-flex flex-column">
                                            <div className="d-flex align-items-center mt-auto">
                                                <div>
                                                    <h5>{p.package_name} {p.package_id}</h5>
                                                    <small className="text-primary">ประกันชั้น {showPackageType(p.package_type)}</small>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="table-responsive">
                                            <table className="table table-striped table-vcenter mb-0">
                                                <tbody>
                                                    <tr>
                                                        <td colSpan={2} className="text-center text-primary">
                                                            {broker === 'deves' ? <h2>{ parseInt(Math.ceil(p.total)).toLocaleString()}</h2>
                                                                                : <h2>{parseFloat(p.total).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits:2})}</h2>}
                                                            <small className='text-muted'>(ราคาสุทธิ)</small>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="tx-medium"><img src={`https://cdn-icons-png.flaticon.com/128/1786/1786607.png`} width={15}/> ทุนประกัน</td>
                                                        <td className="text-right"><h5 className='text-blue'>฿{parseInt(p.sum_insured).toLocaleString()}</h5></td>
                                                    </tr>
                                                    {broker === 'deves' &&
                                                        <tr>
                                                            <td className="tx-medium"><img src={`https://cdn-icons-png.flaticon.com/512/8430/8430831.png`} width={15}/> ทุน สูญหายไฟไหม้</td>
                                                            <td className="text-right"><h5 className='text-blue'>฿{parseInt(p.dataAll.motor.extra.TFSI).toLocaleString()}</h5></td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                        <div>
                                            <div className="card-footer bg-primary text-white text-center">
                                                เลือกแผนประกัน
                                            </div>
                                        </div>
                                    </div>    
                                </label>
                            </div>    
                        ))}
                        <div className='col-xl-12'>
                            {errors.packageId && 
                                <span className='text-danger'>{errors.packageId.message}</span>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Package