import axios from 'axios'
import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import { withRouter } from '../../../hook/withRouter'
import { useParams } from 'react-router-dom'

function ListCommission() {
    const params = useParams()
    const [brokers, setBrokers] = useState()
    const no = 1

    const getCommission = () => {
        axios.get(process.env.REACT_APP_API_URL + "/getCommissionForUser/" + params.id)
        .then(res => res.data)
        .then((brokers) => setBrokers(brokers))
        .catch(err => console.log(err))
    }

    const delCommissionUser = (brokerId, userId) => {
        Swal.fire({
            title: 'คุณต้องการลบข้อมูลใช่หรือไม่',
            confirmButtonColor: '#dc3545',
            showCancelButton: true,
            confirmButtonText: 'ใช่',
            cancelButtonText: 'ไม่ใช่'
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                axios.get(process.env.REACT_APP_API_URL + '/delCommissionUser/' + brokerId + "/" + userId)
                .then(res => {
                    Swal.fire('สำเร็จ',res.data.success,'success')
                }).catch(err=>{
                    console.log(err)
                    Swal.fire('Error','เกิดข้อผิดพลาดบางอย่าง','error')
                })
            } else if (result.isDenied) {
                Swal.fire('Changes are not saved', '', 'info')
            }
        })
    }

    useEffect(() => {
        getCommission()
    }, [])
    return (
        <>
            <div className="card">
                <div className="card-header">
                    <h3 className="card-title">ค่า Commission</h3>
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table table-hover table-vcenter text-nowrap table-striped mb-0">
                            <thead>
                                <tr>
                                    <th rowSpan="2">#</th>
                                    <th rowSpan="2">บริษัทประกันภัย</th>
                                    <th colSpan="3" className='text-center'>รถเก๋ง</th>
                                    <th colSpan="5" className='text-center'>ประกันชั้น 1</th>
                                </tr>
                                <tr>
                                    <th>รถเก๋ง</th>
                                    <th>รถกระบะ</th>
                                    <th>รถตู้</th>
                                    <th>ประกันชั้น 1</th>
                                    <th>ประกันชั้น 2+</th>
                                    <th>ประกันชั้น 2</th>
                                    <th>ประกันชั้น 3</th>
                                    <th>ประกันชั้น 3+</th>
                                </tr>
                            </thead>
                            <tbody>
                                {brokers && brokers.map((broker, no) => (
                                    <tr key={broker._id}>
                                        <td className="w60">{no+1}</td>
                                        <td>
                                            <div className="font-15">{broker.brokerJoin[0].broker_name}</div>
                                        </td>
                                        <td>{broker.cmi_sedan}</td>
                                        <td>{broker.cmi_pickup}</td>
                                        <td>{broker.cmi_van}</td>
                                        <td>{broker.vmi_class1}</td>
                                        <td>{broker.vmi_class2plus}</td>
                                        <td>{broker.vmi_class2}</td>
                                        <td>{broker.vmi_class3plus}</td>
                                        <td>{broker.vmi_class3}</td>
                                        <td>
                                            <a href={"/commissionEdit/" + broker.user_id + "/" + broker.brokerJoin[0]._id} className="btn btn-icon btn-sm" title="Edit"><i className="fa fa-edit"></i></a>
                                            <button type="button" onClick={() => {delCommissionUser(broker.broker_id, broker.user_id)}} className="btn btn-icon btn-sm js-sweetalert" title="Delete" data-type="confirm"><i className="fa fa-trash-o text-danger"></i></button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>    
                </div>
            </div>
        </>
    )
}

export default ListCommission
