import React, { useState } from 'react'
import Footer from '../../Layout/Footer'
import Header from '../../Layout/Header'
import Nav from '../../Layout/Nav'
import FormBroker from './FormBroker'
import ListBroker from './ListBroker'

function Broker() {
    const [toggle, setToggle] = useState(true)
    const handleToggle = () => {
        console.log('handleToggle');
        if (toggle === true) {
            setToggle(false)
        } else {
            setToggle(true)
        }
    }
    return (
        <>
            <Nav />
            <div className='page'>
                <Header />
                <div className="section-body">
                    <div className="container-fluid">
                        <div className="d-flex justify-content-between align-items-center ">
                            <div className="header-action">
                                <h1 className="page-title">ผู้ใช้งาน</h1>
                                <ol className="breadcrumb page-breadcrumb">
                                    <li className="breadcrumb-item"><a href="/dashboard">หน้าแรก</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">บริษัทประกันภัย</li>
                                </ol>
                            </div>
                            <ul className="nav nav-tabs page-header-tab">
                                <li className="nav-item"><a className="nav-link active" onClick={handleToggle} data-toggle="tab" href="#list">รายการ</a></li>
                                <li className="nav-item"><a className="nav-link" data-toggle="tab" href="#form">เพิ่มข้อมูล</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="section-body mt-4">
                    <div className="container-fluid">
                        <div className="tab-content">
                            <ListBroker toggle={toggle} />
                            <FormBroker />
                        </div>
                    </div>
                </div>
                <Footer />   
            </div>
        </>
    )
}

export default Broker

