import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import Swal from 'sweetalert2'

function FormCommission(props) {
    const form = useRef()
    const params = useParams()
    const refSedan = useRef()
    const refPickup = useRef()
    const refVan = useRef()
    const refClass1 = useRef()
    const refClass2Plus = useRef()
    const refClass2 = useRef()
    const refClass3Plus = useRef()
    const refClass3 = useRef()
    const [idBroker, setIdBroker] = useState()
    const [comSedan, setComSedan] = useState()
    const [comPickup, setComPickup] = useState()
    const [comVan, setComVan] = useState()
    const [comClass1, setComClass1] = useState()
    const [comClass2Plus, setComClass2Plus] = useState()
    const [comClass2, setComClass2] = useState()
    const [comClass3Plus, setComClass3Plus] = useState()
    const [comClass3, setComClass3] = useState()
    
    const onSubmit = (event) => {
        event.preventDefault() //ไม่ให้ Browser refresh
        const formData = new FormData(form.current)
        const formEnt = Object.fromEntries(formData.entries())
        axios.post(process.env.REACT_APP_API_URL + '/saveCommissionForUser', formEnt)
        .then(response=>{
            form.current.reset()
            Swal.fire('สำเร็จ','กำหนด Commission สำเร็จ','success')
        }).catch(err=>{
            Swal.fire('แจ้งเตือน',err.response.data.error,'error')
        })
    }
    const handleIdBroker = (event) => {
        console.log('handle id broker => ', event.target.value);
        if (event.target.value) {
            setIdBroker(event.target.value)
            refSedan.current.disabled = false
            refPickup.current.disabled = false
            refVan.current.disabled = false
            refClass1.current.disabled = false
            refClass2Plus.current.disabled = false
            refClass2.current.disabled = false
            refClass3Plus.current.disabled = false
            refClass3.current.disabled = false    
        } else {
            setIdBroker()
            refSedan.current.disabled = true
            refPickup.current.disabled = true
            refVan.current.disabled = true
            refClass1.current.disabled = true
            refClass2Plus.current.disabled = true
            refClass2.current.disabled = true
            refClass3Plus.current.disabled = true
            refClass3.current.disabled = true        
        }
        getCommissionHead(event.target.value)
    }
    const getCommissionHead = (brokerId) => {
        axios.get(process.env.REACT_APP_API_URL + "/getCommissionHead/" + params.id + "/" + brokerId)
        .then(res => {
            setComSedan(res.data.cmi_sedan)
            setComPickup(res.data.cmi_pickup)
            setComVan(res.data.cmi_van)
            setComClass1(res.data.vmi_class1)
            setComClass2Plus(res.data.vmi_class2plus)
            setComClass2(res.data.vmi_class2)
            setComClass3Plus(res.data.vmi_class3plus)
            setComClass3(res.data.vmi_class3)
            console.log('commission head => ', res.data);
        })
        .catch(err => console.log(err))
    }
    const handelCommission = (event) => {
        console.log('handle commission => ', event);
        const fieldName = event.target.name
        if (fieldName === 'sedan') {
            if (event.target.value > (comSedan - 1)) {
                Swal.fire('ข้อควรระวัง','ค่าคอมมิชชั่นรถเก๋งต้องไม่เกิน หรือไม่เท่ากับ ' + comSedan,'warning')
                event.target.value = ''
            }
        } else if(fieldName === 'pickup') {
            if (event.target.value > (comPickup - 1)) {
                Swal.fire('ข้อควรระวัง','ค่าคอมมิชชั่นรถกระบะต้องไม่เกิน หรือไม่เท่ากับ ' + comPickup,'warning')
                event.target.value = ''
            }
        } else if(fieldName === 'van') {
            if (event.target.value > (comVan - 1)) {
                Swal.fire('ข้อควรระวัง','ค่าคอมมิชชั่นรถตู้ต้องไม่เกิน หรือไม่เท่ากับ ' + comVan,'warning')
                event.target.value = ''
            }
        } else if(fieldName === 'class1') {
            console.log(fieldName);
            if (event.target.value > (comClass1 - 1)) {
                Swal.fire('ข้อควรระวัง','ค่าคอมมิชชั่นประกันชั้น 1 ต้องไม่เกิน หรือไม่เท่ากับ ' + comClass1,'warning')
                event.target.value = ''
            }
        } else if(fieldName === 'class2plus') {
            if (event.target.value > (comClass2Plus - 1)) {
                Swal.fire('ข้อควรระวัง','ค่าคอมมิชชั่นประกันชั้น 2+ ต้องไม่เกิน หรือไม่เท่ากับ ' + comClass2Plus,'warning')
                event.target.value = ''
            }
        } else if(fieldName === 'class2') {
            if (event.target.value > (comClass2 - 1)) {
                Swal.fire('ข้อควรระวัง','ค่าคอมมิชชั่นประกันชั้น 2 ต้องไม่เกิน หรือไม่เท่ากับ ' + comClass2,'warning')
                event.target.value = ''
            }
        } else if(fieldName === 'class3plus') {
            if (event.target.value > (comClass3Plus - 1)) {
                Swal.fire('ข้อควรระวัง','ค่าคอมมิชชั่นประกันชั้น 3+ ต้องไม่เกิน หรือไม่เท่ากับ ' + comClass3Plus,'warning')
                event.target.value = ''
            }
        } else if(fieldName === 'class3') {
            if (event.target.value > (comClass3 - 1)) {
                Swal.fire('ข้อควรระวัง','ค่าคอมมิชชั่นประกันชั้น 3ต้องไม่เกิน หรือไม่เท่ากับ ' + comClass3,'warning')
                event.target.value = ''
            }
        } else {
            console.log('ไม่พบ Field');
        }
    }
    const [name, setName] = useState()
    const [role, setRole] = useState()
    const getDetailUser = () => {
        axios.get(process.env.REACT_APP_API_URL + "/getDetailUser/" + params.id)
        .then(res => res.data)
        .then((data) => {
            if (data.role === 'agent') {
                setName(data.user_detail.agent_name)
            } else {
                setName(data.user_detail.firstname + ' ' + data.user_detail.lastname)
            }
            setRole(data.role)
        })
        .catch(err => console.log(err))
    }

    const showRole = (role) => {
        switch (role) {
            case 'agent':
                return (<span class="tag">Agent</span>)
            case 'platinum':
                return (<span className="tag">Platinum</span>)
                break;
            case 'gold':
                return (<span className="tag">Gold</span>)
                break;
            case 'silver':
                return (<span className="tag">Silver</span>)
                break;
            default:
                return 'เกิดข้อผิดพลาด'
                break;
        }
    }

    useEffect(() => {
        getDetailUser()
    }, [idBroker])
    return (
        <>
            <div className="row clearfix">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">กำหนด Commission ของ {name} {showRole(role)}</h3>
                        </div>
                        <form ref={form} onSubmit={onSubmit}>
                            <div className="card-body">
                                <input type="hidden" name='user_id' value={params.id}/>
                                <div className='row'>
                                    <div className='col-sm-12'>
                                        <div className="form-group">
                                            <label className="col-form-label">บริษัทประกันภัย <span className="text-danger">*</span></label>
                                            <select className="form-control input-height" name="broker_id" onChange={handleIdBroker}>
                                                <option value="">เลือก...</option>
                                                {props.brokers && props.brokers.map(broker => 
                                                    <option key={broker._id} value={broker._id}>{broker.broker_name}</option>    
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <h3 className="card-title">ค่า Commission ประกันภาคบังคับ (พรบ.)</h3>
                                        </div>
                                    </div>
                                    <div className="col-2">
                                        <div className="form-group">
                                            <label>รถเก๋ง</label> 
                                            <input type="number" name="sedan" className="form-control" placeholder='0.00%' onChange={handelCommission} required disabled={true} ref={refSedan} />
                                        </div>
                                    </div>
                                    <div className="col-2">
                                        <div className="form-group">
                                            <label>รถกระบะ</label>
                                            <input type="number" name="pickup" className="form-control" placeholder='0.00%' onChange={handelCommission} required disabled={true} ref={refPickup} />
                                        </div>
                                    </div>
                                    <div className="col-2">
                                        <div className="form-group">
                                            <label>รถตู้</label>
                                            <input type="number" name="van" className="form-control" placeholder='0.00%' onChange={handelCommission} required disabled={true} ref={refVan} />
                                        </div>
                                    </div>        
                                </div>
                                <div className='row'>
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <h3 className="card-title">ค่า Commission ประกันภาคสมัครใจ (ประเภท)</h3>
                                        </div>
                                    </div>
                                    <div className="col-2">
                                        <div className="form-group">
                                            <label>ประกันชั้น 1</label>
                                            <input type="number" name="class1" className="form-control" placeholder='0.00%' onChange={handelCommission} required disabled={true} ref={refClass1} />
                                        </div>
                                    </div>
                                    <div className="col-2">
                                        <div className="form-group">
                                            <label>ประกันชั้น 2+</label>
                                            <input type="number" name="class2plus" className="form-control" placeholder='0.00%' onChange={handelCommission} required disabled={true} ref={refClass2Plus} />
                                        </div>
                                    </div>
                                    <div className="col-2">
                                        <div className="form-group">
                                            <label>ประกันชั้น 2</label>
                                            <input type="texnumbert" name="class2" className="form-control" placeholder='0.00%' onChange={handelCommission} required disabled={true} ref={refClass2} />
                                        </div>
                                    </div>
                                    <div className="col-2">
                                        <div className="form-group">
                                            <label>ประกันชั้น 3+</label>
                                            <input type="number" name="class3plus" className="form-control" placeholder='0.00%' onChange={handelCommission} required disabled={true} ref={refClass3Plus} />
                                        </div>
                                    </div>
                                    <div className="col-2">
                                        <div className="form-group">
                                            <label>ประกันชั้น 3</label>
                                            <input type="number" name="class3" className="form-control" placeholder='0.00%' onChange={handelCommission} required disabled={true} ref={refClass3} />
                                        </div>
                                    </div>        
                                </div>
                                <div className='row'>
                                    <div className="col-sm-12">
                                        <button type="submit" className="btn btn-primary mr-2">บันทึก</button>
                                        <button type="reset" className="btn btn-outline-secondary">ยกเลิก</button>
                                    </div> 
                                </div>
                            </div>    
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FormCommission
