import axios from 'axios'
import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'

function ListBroker(props) {
    const [broker, setBroker] = useState()
    const [toggle, setToggle] = useState(props.toggle)

    const no = 1

    const getBroker = () => {
        console.log('get broker');
        axios.get(process.env.REACT_APP_API_URL + "/getBroker")
        .then(res => res.data)
        .then((broker) => setBroker(broker))
        .catch(err => console.log(err))
    }

    const delBroker = (id) => {
        Swal.fire({
            title: 'คุณต้องการลบข้อมูลใช่หรือไม่',
            confirmButtonColor: '#dc3545',
            showCancelButton: true,
            confirmButtonText: 'ใช่',
            cancelButtonText: 'ไม่ใช่'
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                axios.get(process.env.REACT_APP_API_URL + '/delBroker/' + id)
                .then(res => {
                    console.log(res);
                    Swal.fire('สำเร็จ',res.data.success,'success')
                    getBroker()  
                }).catch(err=>{
                    console.log(err)
                    Swal.fire('Error','เกิดข้อผิดพลาดบางอย่าง','error')
                })
            } else if (result.isDenied) {
                Swal.fire('Changes are not saved', '', 'info')
            } 
        })
    }

    useEffect(() => {
        getBroker()
    }, [props.toggle])

    console.log('toggle : ', toggle);
    console.log('props : ', props.toggle);
    return (
        <>
            <div className="tab-pane active" id="list">
                <div className='row clearfix'>
                    <div className='col-sm-12'>
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">รายการบริษัทประกันภัย</h3>
                            </div>
                            <div className="table-responsive" style={{height: "310px;"}}>
                                <table className="table card-table table-vcenter text-nowrap table-striped mb-0">
                                    <thead>
                                        <tr>
                                            <th>ลำดับ</th>                                                    
                                            <th>โลโก้</th>                                                    
                                            <th>บริษัทประกันภัย</th>
                                            <th>ผลิตภัณฑ์</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {broker && broker.map((b, no) => (
                                            <tr key={b._id}>
                                                <td>{no+1}</td>
                                                <td className="w60">
                                                    <img className="avatar" src={process.env.REACT_APP_URL_IMG_LOGO + b.logo} alt="" />
                                                </td>
                                                <td>
                                                    <div>{b.broker_name}</div>
                                                </td>
                                                <td>
                                                    <div>
                                                        {b.status_cmi === 1 && <span className="tag tag-primary mr-1">พรบ.</span>}
                                                        {b.status_vmi === 1 && <span className="tag tag-primary">ประกันชั้น</span>}
                                                    </div>
                                                </td>
                                                <td>
                                                    <a href={'/editBroker/' + b._id} className="btn btn-icon btn-sm" title="Edit"><i className="fa fa-edit"></i></a>
                                                    <button type="button" onClick={() => delBroker(b._id)} className="btn btn-icon btn-sm js-sweetalert" title="Delete" data-type="confirm"><i className="fa fa-trash-o text-danger"></i></button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>    
            </div>
        </>
    )
}

export default ListBroker
