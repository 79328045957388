import React, { useEffect, useRef, useState } from 'react'
import logo from '../../assets/assets/images/logo-saifon-cricle.png'
import { QRCodeCanvas, QRCodeSVG } from 'qrcode.react';
import { useAuthUser } from 'react-auth-kit'
import axios from 'axios'
import Swal from 'sweetalert2'
import promptpayJs from 'promptpay-js'
import moment from 'moment/moment'

function FormDepositHead(props) {
    const auth = useAuthUser()
    const form = useRef()
    const selectMoney50 = useRef()
    const selectMoney100 = useRef()
    const selectMoney500 = useRef()
    const selectMoney1000 = useRef()
    const selectMoney5000 = useRef()
    const selectMoney10000 = useRef()
    const [amount, setAmount] = useState()
    const [payload, setPayload] = useState()
    const [showQR, setShowQR] = useState(false)
    const [ref1, setRef1] = useState()
    const [ref2, setRef2] = useState()
    const handleMoney = (e) => {
        const value = parseFloat(e.target.value);
        setAmount(value)
        setShowQR(false)
    }
    const handleAmont = (e) => {
        const value = parseFloat(e.target.value);
        setAmount(value)
        console.log(selectMoney50.current.checked);
        selectMoney50.current.checked = false
        selectMoney100.current.checked = false
        selectMoney500.current.checked = false
        selectMoney1000.current.checked = false
        selectMoney5000.current.checked = false
        selectMoney10000.current.checked = false
        setShowQR(false)
    }

    const genQR = () => {
        const reference2 = moment().format('YYYYMMDDHmmss')
        const reference1 = auth().id.toUpperCase().substring(0, 18)
        console.log(reference1);
        saveTransection(amount, reference2, reference1)
        setRef1(reference1)
        setRef2(reference2)
        const payload = promptpayJs.generate({
            method: 'QR_STATIC',
            application: 'PROMPTPAY_BILL_PAYMENT',
            billerID: process.env.REACT_APP_BILL_ID,
            currencyCode: '764',
            countryCode: 'TH',
            amount: amount,
            reference1: reference1,
            reference2: reference2
        })
        setPayload(payload)
        setShowQR(true)
    }

    const saveTransection = async(amount, ref2, ref1) => {
        const intAmount = parseFloat(amount)
        console.log('save transection =>', intAmount);
        
        
        const data = {
            amount: amount,
            type: 'deposit',
            status: "pending",
            channel: "QR Code",
            filler: auth().id,
            user_id:  auth().id,
            ref1: ref1,
            ref2: ref2
            
        }
        await axios.post(process.env.REACT_APP_API_URL + '/manageCreditBySelf', data)
        .then(response=>{
            console.log(response);
        }).catch(err=>{
            console.log(err.response);
        })
    }
    console.log(payload);
    return (
        <>
            <div className='card'>
                <div className="card-header">
                    <h3 className="card-title">รายละเอียดการฝากเงิน</h3>
                    <div className="card-options">
                        <a href="#" className="card-options-collapse" data-toggle="card-collapse"><i className="fe fe-chevron-up"></i></a>
                    </div>
                </div>
                <div className='card-body'>
                    <div className="row clearfix">
                        <div className="col-sm-12">
                            <label>เลือกจำนวนเงิน</label>
                            <div className='form-group'>
                                <div className="button-radio-money">
                                    <input type="radio" id="selectMoney" name="selectMoney" value="50.00" onClick={handleMoney} ref={selectMoney50} />
                                    <label className="btn btn-default" for="a25">50</label>
                                </div>
                                <div className="button-radio-money">
                                    <input type="radio" id="selectMoney" name="selectMoney" value="100.00" onClick={handleMoney} ref={selectMoney100} />
                                    <label className="btn btn-default" for="a25">100</label>
                                </div>
                                <div className="button-radio-money">
                                    <input type="radio" id="selectMoney" name="selectMoney" value="500.00" onClick={handleMoney} ref={selectMoney500} />
                                    <label className="btn btn-default" for="a25">500</label>
                                </div>
                                <div className="button-radio-money">
                                    <input type="radio" id="selectMoney" name="selectMoney" value="1000.00" onClick={handleMoney} ref={selectMoney1000} />
                                    <label className="btn btn-default" for="a25">1,000</label>
                                </div>
                                <div className="button-radio-money">
                                    <input type="radio" id="selectMoney" name="selectMoney" value="5000.00" onClick={handleMoney} ref={selectMoney5000} />
                                    <label className="btn btn-default" for="a25">5,000</label>
                                </div>
                                <div className="button-radio-money">
                                    <input type="radio" id="selectMoney" name="selectMoney" value="10000.00" onClick={handleMoney} ref={selectMoney10000} />
                                    <label className="btn btn-default" for="a25">10,000</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="form-group">
                                <label>ระบุจำนวนเงิน</label>
                                <input type="nummber" name='amount' value={amount} onChange={handleAmont} className="form-control" />
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className='form-group'>
                                <button onClick={genQR} className="btn btn-primary btn-block">สร้าง QR Code</button>
                            </div>
                        </div> 
                    </div>
                    
                    { showQR === true && 
                    <div className='row'>
                        <div className='col-12'>
                            <div className='card'>
                                <div className='card-header bg-promtpay'>
                                    <div className='row'>
                                        <div className='col-3'>
                                            <img src={logo} style={{width: 100}} alt="" />
                                        </div>
                                        <div className='col-9'>
                                            <h3 className='text-white'>สแกน QR Code <br/>เพื่อเติมเงิน</h3>
                                        </div>
                                    </div>
                                
                                </div>
                                <div className='card-body'>
                                    <div className='text-center'>
                                        <QRCodeSVG value={payload} size="100%" />
                                    </div>
                                    <p className='mt-2'>
                                        Ref1 : {ref1} <br/>
                                        Ref2 : {ref2} <br/>
                                        ยอดเงิน : {amount.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits:2})} บาท
                                    </p>
                                    <h5 className='mt-2 text-center'>บริษัท สายฝน อินชัวรันส์</h5>
                                </div>
                                <div className='card-footer text-center bg-promtpay'>
                                    <h3 className='text-white'>ขอบคุณที่ใช้บริการ</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                </div>
            </div>
        </>
    )
}

export default FormDepositHead
