import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from 'primereact/dropdown';
import { Tag } from 'primereact/tag';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import moment from 'moment'
import Swal from 'sweetalert2';
        

function ListInsure(props) {
    const [insurance, setInsurance] = useState()
    const getInsuranceVMI = () => {
        axios.get(process.env.REACT_APP_API_URL + '/getInsuranceToday/' + props.userAuth)
        .then(res => res.data)
        .then((data) => {
            // setInsurance(data)
            setInsurance([])
            data.map((d, index) => (
                setInsurance((prevState) => [...prevState, {index: index, data: d}])  
            ))
        })
        .then((err) => console.log(err))
    }
    console.log('insurance => ', insurance);
    useEffect(() => {
        getInsuranceVMI()
    }, [])
    const [customers, setCustomers] = useState(null);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        'country.name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        status: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] }
    });

    const onGlobalFilterChange = (event) => {
        const value = event.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
    };

    const renderHeader = () => {
        const value = filters['global'] ? filters['global'].value : '';

        return (
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" value={value || ''} onChange={(e) => onGlobalFilterChange(e)} placeholder="ค้นหา..." />
            </span>
        );
    };

    const priceBodyTemplate = (rowData) => {
        return parseFloat(rowData.data.data.price).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits:2});
    };

    const priceNetBodyTemplate = (rowData) => {
        return parseFloat(rowData.data.data.price_net).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits:2});
    };

    const stampBodyTemplate = (rowData) => {
        return parseFloat(rowData.data.data.stamp).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits:2});
    };

    const vatBodyTemplate = (rowData) => {
        return parseFloat(rowData.data.data.vat).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits:2});
    };

    const fileBodyTemplate = (rowData) => {
        return <a target='_blank' href={rowData.data.data.file.file_store} className='btn btn-light btn-sm'><i className="fa fa-file-text-o" aria-hidden="true"></i></a>
    };

    const [requestRemark, setRequestRemark] = useState()
    const [requiredRemark, setRequiredRemark] = useState()
    const [visible, setVisible] = useState(false);
    const formSendRequestCancle = useRef([])
    const formCancleRequest = useRef()
    let i = 0
    const cancleBodyTemplate = (rowData) => {
        
        console.log('row data => ', rowData);
        if (rowData.data.data.status === 'success') {
            if (rowData.data.data.date_start === moment().format("YYYY-MM-DD")) {
                return (<>
                    <button type="button" className="btn btn-outline-danger btn-sm" data-toggle="modal" data-target={"#exampleModal"+rowData.data.data._id}>
                        ส่งคำขอ
                    </button>
                    <div className="modal fade" id={"exampleModal"+rowData.data.data._id} tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">ส่งคำขอยกเลิกกรมธรรม์</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <form ref={el => formSendRequestCancle.current[rowData.index] = el} onSubmit={(event) => handleSendRequestCancle(event, rowData.index)}>
                                    <div className="modal-body">
                                        <div className='form-group text-left'>
                                            <label>_id : {rowData.data.data._id}</label><br/>
                                            <label>กรมธรรม์เลขที่  <span className='text-danger'>*</span></label>
                                            <input type="text" className="form-control" value={rowData.data.data.policy_no} disabled/>
                                            <input type='hidden' name='policyNo' value={rowData.data.data.policy_no} />
                                        </div>
                                        <div className='form-group text-left'>
                                            <label>เหตุผลในการยกเลิกกรมธรรม์ <span className='text-danger'>*</span></label>
                                            <input type="text" className="form-control" name='requestRemark' required/>    
                                        </div>
                                        <input type='hidden' name='broker' value={rowData.data.data.broker_short_name} />
                                        <input type='hidden' name='_id' value={rowData.data.data._id} />
                                        <input type='hidden' name='index' value={rowData.index} />
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-dismiss="modal">ปิด</button>
                                        <button type="submit" className="btn btn-primary">ส่งคำขอยกเลิกกรมธรรม์</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </>)     
            }               
        } else {
            if(rowData.data.data.status.status === 'sendRequest') {
                return (
                    <>
                        <button type="submit" onClick={(e) => handleCancleRequest(e, rowData.data.data._id)} className="btn btn-outline-warning btn-sm"> ยกเลิกคำขอกรมธรรม์ </button>
                    </>
                )
            } else if(rowData.data.data.status.status === 'cancle') {
                return 'ถูกยกเลิก'
            }
        }
    };

    const handleSendRequestCancle = (event, index) => {
        event.preventDefault() //ไม่ให้ Browser refres
        const formData = new FormData(formSendRequestCancle.current[index])
        const formEnt = Object.fromEntries(formData.entries())
        console.log(formEnt);
        axios.post(process.env.REACT_APP_API_URL + "/sendRequestCanclePolicy", formEnt)
        .then(response=>{
            Swal.fire("สำเร็จ", response.data.success, 'success').then((result) => {
                if (result.isConfirmed) {
                    window.location.reload()
                    getInsuranceVMI()
                }
            })
        }).catch(err=>{
            Swal.fire('แจ้งเตือน',err.response.data.error,'error')
        })
    }
    const handleCancleRequest = (event, id) => {
        event.preventDefault() //ไม่ให้ Browser refresh
        const formData = new FormData(formCancleRequest.current)
        const formEnt = Object.fromEntries(formData.entries())
        console.log(id);
        axios.post(process.env.REACT_APP_API_URL + "/cancleSendRequestCanclePolicy", {_id: id})
        .then(response=>{
            Swal.fire("สำเร็จ", response.data.success, 'success').then((result) => {
                if (result.isConfirmed) {
                    getInsuranceVMI()
                }
            })
        }).catch(err=>{
            Swal.fire('แจ้งเตือน',err.response.data.error,'error')
        })
    }

    const brokerBodyTemplate = (rowData) => {
        return (
            <div className='text-center'>
                <img className="avatar" src={process.env.REACT_APP_URL_IMG_LOGO + rowData.data.data.logo} alt="" />
            </div>
        )
    }

    const nameBodyTemplate = (rowData) => {
        if (rowData.data.data.lastname === undefined) {
            return rowData.data.data.insured_prefix+rowData.data.data.name
        } else {
            return rowData.data.data.name+' '+rowData.data.data.lastname
        }
    };

    const actuallyPaidBodyTemplate = (rowData) => {
        return parseFloat(rowData.data.data.actually_paid).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits:2});
    };

    const statusBodyTemplate = (rowData) => {
        if (rowData.data.data.status === "success") {
            return <span className="tag tag-green">สำเร็จ</span>
        } else {
            if (rowData.data.data.status.status === 'sendRequest') {
                return <span className="tag tag-warning">ส่งคำขอยกเลิก</span>
            } else {
                return <span className="tag tag-danger">ยกเลิก</span>
            }
            
        }
    }

    const typeInsureBodyTemplate = (rowData) => {
        if (rowData.data.data.type_insured === 'cmi') {
            return <span className="tag tag-azure">พรบ.</span>
        } else {
            return <span className="tag tag-cyan">ประกัน</span>
        }
    }

    const commissionBodyTemplate = (rowData) => {
        const commission =  rowData.data.data.price_net-rowData.data.data.actually_paid
        return (<>
            {parseFloat(commission).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits:2})}
            <small className='ml-1 text-success'><span className=" badge badge-light text-success rounded-pill">{rowData.data.data.commission_percent}%</span></small>
        </>);
    };

    const header = renderHeader();
    
    return (
        <>
            <div className="tab-pane active" id="listInsure">
                <DataTable value={insurance} paginator showGridlines rows={10}  dataKey="data._id" filters={filters}
                    globalFilterFields={['data.logo', 'data.broker_name', 'data.name', 'data.lastname', 'data.registration_no', 'data.car_type', 'data.price', 'data.stamp', 'data.vat', 'data.price_net' ]}
                    header={header} emptyMessage="ไม่พบข้อมูล">
                    
                    <Column className='font-prompt' header="โบรคเกอร์" body={brokerBodyTemplate} sortable></Column>
                    <Column className='font-prompt' field="data.data.broker_name" header="Broker Name" hidden sortable></Column>
                    <Column className='font-prompt' header="ประเภท" body={typeInsureBodyTemplate} sortable></Column>
                    <Column className='font-prompt' field="data.data.policy_no" header="เลขกรมธรรม์" style={{ minWidth: '200px' }} sortable></Column>
                    <Column className='font-prompt' header="ชื่อ" body={nameBodyTemplate} style={{ minWidth: '200px' }} sortable></Column>
                    <Column className='font-prompt' field="data.data.registration_no" header="ทะเบียนรถ" sortable></Column>
                    <Column className='font-prompt' header="เบี้ยสุทธิ" body={priceNetBodyTemplate} sortable></Column>
                    <Column className='font-prompt' header="เบี้ยนำส่ง" body={actuallyPaidBodyTemplate} sortable></Column>
                    <Column className='font-prompt text-center' field="data.data.file.file_store" header="กรมธรรม์" body={fileBodyTemplate} sortable></Column>
                    <Column className='font-prompt' header="สถานะ" body={statusBodyTemplate} sortable></Column>
                    <Column className='font-prompt text-center' header="จัดการกรมธรรม์" body={cancleBodyTemplate} style={{ minWidth: '150px' }} sortable></Column>
                    {/* <Column className='font-prompt' field='data.data._id' header="_id" sortable></Column> */}
                </DataTable>
            </div>
            
        </>
    )
}

export default ListInsure
