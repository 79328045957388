import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import Swal from 'sweetalert2'
import FormEditPassword from './FormEditPassword'

function FormEditSeller(props) {
    const form = useRef()
    const [userId, setUserId] = useState()
    const [firstname, setFirstname] = useState()
    const [lastname, setLastname] = useState()
    const [name, setName] = useState()
    const [email, setEmail] = useState()
    const [statusActive, setStatusActive] = useState()
    const [checked, setChecked] = useState()
    const onSubmit = (event) => {
        event.preventDefault() //ไม่ให้ Browser refresh
        const formData = new FormData(form.current)
        const formEnt = Object.fromEntries(formData.entries())
        axios.post(process.env.REACT_APP_API_URL + '/updateDetail/seller', formEnt)
        .then(response=>{
            // form.current.reset()
            Swal.fire('สำเร็จ',response.data.success,'success')
        }).catch(err=>{
            console.log(err.response);
            Swal.fire('แจ้งเตือน',err.response.data.error,'error')
        })
    }
    const getDetailAgent = () => {
        axios.get(process.env.REACT_APP_API_URL + "/getDetail/seller/" + props.id)
        .then(res => {
            // res.data[0]
            console.log(res.data);
            setUserId(res.data[0]._id)
            setFirstname(res.data[0].seller[0].firstname)
            setLastname(res.data[0].seller[0].lastname)
            setName(res.data[0].name)
            setEmail(res.data[0].email)
            setStatusActive(res.data[0].status_active)
            if (res.data[0].status_active === 1) {
                setChecked(true)
            } else {
                setChecked(false)
            }
        })
        // .then((data) => setData(data))
        .catch(err => console.log(err))
    }
    const handelChecked = (e) => {
        if (e.target.checked === true) {
            setStatusActive(1)
            setChecked(true)
        } else {
            setStatusActive(0)
            setChecked(false)
        }
    }
    useEffect(() => {
        getDetailAgent()
    }, [])
    return (
        <>
            <div className="row clearfix">
                <div className="col-lg-8 col-md-12 col-sm-12">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">ข้อมูลผู้ใช้งาน</h3>
                        </div>
                        <div className="card-body">
                            <form ref={form} onSubmit={onSubmit}>
                                <input type="hidden" name="user_id" value={userId} />
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>ชื่อ</label>
                                            <input type="text" name="firstname" value={firstname} onChange={(e) => {setFirstname(e.target.value)}} className="form-control" required />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>นามสกุล</label>
                                            <input type="text" name="lastname" value={lastname} onChange={(e) => {setLastname(e.target.value)}} className="form-control" required />
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <label>ชื่อผู้ใช้งาน</label>
                                            <input type="text" name="name" value={name} onChange={(e) => {setName(e.target.value)}} className="form-control" required />
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <label>อีเมล</label>
                                            <input name='email' type="email" value={email} onChange={(e) => {setEmail(e.target.value)}} className="form-control" required />
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <label>การใช้งานบัญชี</label><br />
                                            <label className="custom-switch">
                                                <span className="custom-switch-description mr-1">ปิด</span>
                                                <input type="checkbox" value={statusActive} name="status_active" 
                                                        className="custom-switch-input btn-iconcolor" 
                                                        // onChange={(e) => {console.log(e.target.checked)}}
                                                        onChange={handelChecked}
                                                        checked={checked}
                                                />
                                                <span className="custom-switch-indicator"></span>
                                                <span className="custom-switch-description">เปิด</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className='row mt-3'>
                                    <div className="col-sm-12">
                                        <button type="submit" className="btn btn-primary mr-2">บันทึก</button>
                                        <button type="reset" className="btn btn-outline-secondary">ยกเลิก</button>
                                    </div> 
                                </div>    
                            </form>
                        </div>
                    </div>
                </div>
                <FormEditPassword userId={userId} />
            </div>
        </>
    )
}

export default FormEditSeller
