import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Swal from 'sweetalert2'

function GetYear() {
  const params = useParams()
  const id = params.id
  const [data, setData] = useState()
  const getYearMuangthai = () => {
    console.log('get year muangthai');
    axios.get(process.env.REACT_APP_API_URL + "/getYearMuangthai/" + id)
    .then(res => {
        console.log('res year muangthai => ',res.data.year)
        setData(res.data.year)
    })
    .catch(err => console.log(err))
  }
  const onSubmit = (MakeCode, Family, YearGroupID) => {
    const data = {
      MakeCode: MakeCode,
      Family: Family,
      YearGroupID: YearGroupID
    }
    axios.post(process.env.REACT_APP_API_URL + "/getEnginear", data)
    .then(res => {
      Swal.fire('สำเร็จ','บันทึกค่าสำเร็จ','success')
      .then((result) => {
          if (result.isConfirmed) {
              getYearMuangthai()
          }
      })
    }).catch(err => console.log(err))
  }
  useEffect(() => {
    getYearMuangthai()
  }, [])
  return (
    <>
      <div>GetYear</div>
        <table className='table table-bordered'>
          <tr>
              <td>model_code</td>
              <td>Family</td>
              <td>year</td>
              <td>status</td>
          </tr>
          {data && data.map((m) => (
              <tr>
                  <td>{m.MakeCode}</td>
                  <td>{m.Family}</td>
                  <td>{m.YearGroupID}</td>
                  <td>
                      {m.car_size ? <span>Update</span>
                          :
                          <a onClick={e => onSubmit(m.MakeCode, m.Family, m.YearGroupID)} className='btn btn-info'>Update</a>
                      }
                  </td>
              </tr>
          ))}
      </table>
    </>
    
  )
}

export default GetYear