import axios from 'axios'
import React, { Component, useEffect, useState } from 'react'
import { useAuthUser } from 'react-auth-kit';
import moment from 'moment'

function ViewProfileAdmin() {
    const auth = useAuthUser()
    const [profile, setProfile] = useState()
    
    const getProfile = () => {
        axios.get(process.env.REACT_APP_API_URL + "/getProfile/admin/" + auth().id)
        .then(res => res.data)
        .then((profile) => setProfile(profile[0]))
        .catch(err => console.log(err))
    }
    
    const toThaiDateString = (date) => {
        const dateConvert = moment(date).format("YYYY-MM-DD hh:mm")
        console.log('date dateConvert ==> ' ,dateConvert)
        let monthNames = ["",
            "มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน",
            "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม.",
            "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"
        ];
    
        const yearConvertToInt = parseInt(moment(date).format("YYYY"), 10)
        const monthConvertToInt = parseInt(moment(date).format("MM"), 10)
        let year = yearConvertToInt + 543;
        let month = monthNames[monthConvertToInt];
        console.log(month);
        let numOfDay = parseInt(moment(date).format("DD"), 10)
    
        return `${numOfDay} ${month} ${year}`;
    }

    useEffect(() => {
        getProfile()
    }, [])

    console.log(profile);
    return (
        <>
            <div className="row">
                <div className="col-xl-12 col-md-12">
                    <div className="card">
                        <div className="card-body">
                            {/* <ul className="list-group">
                                <li className="list-group-item">
                                    <b>ชื่อผู้ใช้ </b>
                                    <div>{profile.name}</div>
                                </li>
                                <li className="list-group-item">
                                    <b>อีเมล </b>
                                    <div>{profile.email}</div>
                                </li>
                                <li className="list-group-item">
                                    <b>เริ่มเป็นสมาชิก</b>
                                    <div>{toThaiDateString(profile.created_at)}</div>
                                </li>
                            </ul> */}
                        </div>
                    </div>
                </div>
            </div> 
        </>
    )
}

export default ViewProfileAdmin
