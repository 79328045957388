import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Select from 'react-select'
import { Controller, useForm } from 'react-hook-form'
import moment from 'moment'
import { Checkbox } from '@mui/material'

function CarModel({ formProps: { register, formState: {errors}, setValue }, data}) {
    const { broker } = data[0][0]
    const { carType, carBody } = data[1][0]
    console.log(data[1][0]);
    const { makeCode, indexMakeCode, modelCode, defaultModel, year, carSize, seat, weight, color, defaultColor, colorDes } = data[2][0]
    const [optionCarYear, setOptionCarYear] = useState()
    const [defaultCarYear, setDefaultCarYear] = useState(year)
    const [optionCarSeat, setOptionCarSeat] = useState()
    const [defaultCarSeat, setDefaultCarSeat] = useState(seat)
    const [indexMakeCodeState, setIndexMakeCodeState] = useState(indexMakeCode)
    const [defaultModelState, setDefaultModelState] = useState(defaultModel)
    const [indexColorState, setIndexColorState] = useState(defaultColor)
    const [makeCodeRef, setMakeCodeRef] = useState(null)
    const [modelCodeRef, setModelCodeRef] = useState(null)
    const [optionsCarColor, setOptionCarColor] = useState()
    const [optionsCarModel, setOptionCarModel] = useState()
    const [optionsCarMake, setOptionCarMake] = useState([])
    const [requiredCarSize, setRequiredCarSize] = useState()
    const [startCarSize, setStarCarSize] = useState()
    const [requiredWeight, setRequiredWeight] = useState()
    const [starCarWeight, setStarCarWeight] = useState()
    const [requiredColorDes, setRequiredColorDes] = useState()
    const [disabledColorDes, setDisabledColorDes] = useState()
    const [defaultColorDes, setDefaultColorDes] = useState()
    const [defaultCarSize, setDefaultCarSize] = useState(carSize)
    const [defaultWeight, setDefaultWeight] = useState(weight)
    const [requiredCarYear, setRequiredCarYear] = useState()
    const [starCarYear, setStarCarYear] = useState()
    const [loadingMake, setLoadingMake] = useState(false)
    const [loadingModel, setLoadingModel] = useState(false)

    const carYear = () => {
        const month = parseInt(moment().format("MM"), 10)
        console.log('month =>',month);
        if (month === 12) {
            const year = parseInt(moment().format("YYYY"), 10)
            const yearNow = year + 1
            const countLoop = yearNow - 1900
            const element = []
            for (let index = 0; index <= countLoop; index++) {
                const value = yearNow-index
                element[index] = {value: value, label: value };
            }
            setOptionCarYear(element)    
        } else {
            const yearNow = parseInt(moment().format("YYYY"), 10)
            const countLoop = yearNow - 1900
            const element = []
            for (let index = 0; index <= countLoop; index++) {
                const value = yearNow-index
                element[index] = {value: value, label: value };
            }
            setOptionCarYear(element)    
        }
    }

    const carSeat = () => {
        const element = []
        for (let index = 1; index < 100; index++) {
            element[index] = {value: index, label: index };
        }
        setOptionCarSeat(element)
    }

    const getCarMake = () => {
        setLoadingMake(true)
        axios.post(process.env.REACT_APP_API_URL + "/getCarMake",
            {
                broker: broker,
                type: 'cmi'
            }
        )
        .then(res => res.data)
        .then((carMake) => {
            console.log('car make', carMake);
            if (broker === 'muangthai') {
                setOptionCarMake([])
                carMake.map((cm, index) => (
                    setOptionCarMake((prevState) => [...prevState, {index: index, index: index, value: cm.MakeCode, label: cm.Description}])  
                ))
            } else if(broker === 'tokio' | broker === 'deves') {
                setOptionCarMake(carMake)
            } else {
                setOptionCarMake([])
                carMake.map((cm, index) => (
                    setOptionCarMake((prevState) => [...prevState, {index: index, value: cm.make_code, label: cm.make_name}])  
                ))     
            }
            setLoadingMake(false)
        })
        .catch(err => console.log(err))
    }

    const getCarModel = (makeCode) => {
        setLoadingModel(true)
        axios.post(process.env.REACT_APP_API_URL + "/getCarModel",
            {
                broker: broker,
                type: 'cmi',
                makeCode: makeCode,
                carType: carType
            }
        )
        .then(res => res.data)
        .then((carModel) => {
            console.log(carModel);
            setOptionCarModel([])
            if(broker === 'allianz') {
                carModel.map((cm, index) => (
                    setOptionCarModel((prevState) => [...prevState, {index: index, value: cm.model_code, label: cm.model_name, seat: cm.seats, carSize: cm.car_size, weight: cm.weight}])
                ))
            } else if(broker === 'tokio' | broker === 'deves' | broker === 'muangthai') {
                setOptionCarModel(carModel)
            } else {
                carModel.map((cm, index) => (
                    setOptionCarModel((prevState) => [...prevState, {index: index, value: cm.model_code, label: cm.model_name}])
                ))
            }
            setLoadingModel(false)
        })
        .catch(err => console.log(err))
    }

    const getCarColor = () => {
        if (broker === 'tokio') {
            setOptionCarColor([{index: 0, value: 'ไม่ระบุ', label: 'ไม่ระบุ'}, {index: 0, value: 'อื่น ๆ', label: 'อื่น ๆ'}])
        } else {
            axios.get(process.env.REACT_APP_API_URL + "/getCarColor/" + broker)
            .then(res => res.data)
            .then((carColor) => {
                setOptionCarColor([])
                carColor.map((cc, index) => (
                    setOptionCarColor((prevState) => [...prevState, {index: index, value: cc.color_code, label: cc.color_name_th}])
                ))
                setIndexColorState(optionsCarColor)
            })
            .catch(err => console.log(err))
        }
    }

    const handleCarMake = (event) => {
        getCarModel(event.value)
        // modelCodeRef.clearValue();
        setValue('makeCode', event)
        setValue('indexMakeCode', event.index)
        setIndexMakeCodeState(event.index)
        setDefaultModelState('')
        setOptionCarModel([])
        errors.makeCode = ''
    }

    const handleCarModel = (event) => {
        console.log(event);
        if (broker === 'allianz') {
            setValue('modelCode', event)
            const modelCodeDefault = {index: event.index, value: event.value, label: event.label}
            setValue('defaultModel', modelCodeDefault)
            setDefaultModelState(modelCodeDefault)
            setDefaultCarSeat({value: event.seat, label: event.seat})
            setValue('seat', {value: event.seat, label: event.seat})
            setDefaultCarSize(event.carSize)
            setValue('carSize', event.carSize)
            setDefaultWeight(event.weight)
            setValue('weight', event.weight)
            errors.modelCode = ''
        } else {
            setValue('modelCode', event)
            const modelCodeDefault = {index: event.index, value: event.value, label: event.label}
            setValue('defaultModel', modelCodeDefault)
            setDefaultModelState(modelCodeDefault)
            errors.modelCode = ''
        }
    }

    const handleCarYear = (event) => {
        setValue('year', event)
        setDefaultCarYear(event)
        errors.year = ''
    }

    const handleCarSeat = (event) => {
        setValue('seat', event)
        setDefaultCarSeat(event)
        errors.seat = ''
    }

    const handleColor = (event) => {
        setValue('color', event)
        const colorDefault = {index: event.index, value: event.value, label: event.label}
        setValue('defaultColor', colorDefault)
        setIndexColorState(colorDefault)
        if (event.label === 'อื่น ๆ') {
            setDisabledColorDes(false)
            setRequiredColorDes({required: "กรุณาระบุสี"})
            setDefaultColorDes()
        } else {
            setDisabledColorDes(true)
            setRequiredColorDes(false)
            setDefaultColorDes(event.label)
            errors.colorDes = ''
        }
        console.log('handle color', requiredColorDes);
        errors.color = ''
    }

    const setDefault = () => {
        if (carType === '1.10') {
            if (seat === undefined) {
                setDefaultCarSeat({value: 7, label: 7})
                setValue('seat', {value: 7, label: 7})    
            }
            setRequiredCarSize({required: "กรุณากรอกขนาดรถ (ซีซี)"})
            setStarCarSize(true)
            setRequiredWeight({required: false})
            setStarCarWeight(false)
        } else if (carType === '1.20A') {
            if (seat === undefined) {
                setDefaultCarSeat({value: 12, label: 12})
                setValue('seat', {value: 12, label: 12})     
            }
            setRequiredCarSize({required: false})
            setStarCarSize(false)
            setRequiredWeight({required: false})
            setStarCarWeight(false)
        } else if (carType === '1.40A' | carType === '1.40B') {
            if (seat === undefined) {
                setDefaultCarSeat({value: 5, label: 5})
                setValue('seat', {value: 5, label: 5})     
            }
            setRequiredCarSize({required: false})
            setStarCarSize(false)
            setRequiredWeight({required: "กรุณากรอกน้ำหนักรถ"})
            setStarCarWeight(true)
        }
        if (color === undefined) {
            setDisabledColorDes(true)
            setRequiredColorDes(false)
            errors.colorDes = ''
        } else {
            if (color.label === 'อื่น ๆ') {
                setDisabledColorDes(false)
                setRequiredColorDes({required: "กรุณาระบุสี"})
                setDefaultColorDes()
            } else {
                setDisabledColorDes(true)
                setRequiredColorDes(false)
                setDefaultColorDes(color.label)
                setValue('colorDes', color.label)
                errors.colorDes = ''
            }
        }
    }

    useEffect(() => {
        getCarColor()
        getCarMake()
        carYear()
        carSeat()
        setDefault()
        if (broker === 'tokio' | broker === 'deves') {
            setRequiredCarYear({required: "กรุณาเลือกปีรถ"})
            setStarCarYear(true)
        } else {
            setRequiredCarYear({required: false})
            errors.carYear = ''
            setStarCarYear(false)
        }
    }, [])
    return (
        <>
            <div className="card">
                <div className="card-header">
                    <h3 className="card-title">รุ่นรถ</h3>
                </div>
                <div className="card-body">
                    <div className="row clearfix">
                        <div className="col-md-6 col-sm-12">
                            <div className='form-group'>
                                <label>ยี่ห้อ <span className="text-danger">*</span></label>
                                <Select
                                    name='makeCode'
                                    onChange={handleCarMake}
                                    options={optionsCarMake}
                                    value={optionsCarMake[indexMakeCodeState]}
                                    isLoading={loadingMake}
                                    ref={
                                        register('makeCode', {required: "กรุณาเลือกยี่ห้อรถ"})
                                    }
                                />
                                {errors.makeCode && <span className='text-danger'>{errors.makeCode.message}</span> }
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <div className='form-group'>
                                <label>รุ่น <span className="text-danger">*</span></label>
                                <Select name='modelCode'
                                        onChange={handleCarModel}
                                        options={optionsCarModel}
                                        value={defaultModelState}
                                        isLoading={loadingModel}
                                        ref={(ref) => {
                                            setModelCodeRef(ref)
                                            register('modelCode', {required: "กรุณาเลือกรุ่นรถ"})
                                        }}
                                />
                                {errors.modelCode && <span className='text-danger'>{errors.modelCode.message}</span> }
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-12">
                            <div className='form-group'>
                                <label>ปีรถ (ค.ศ.) {starCarYear === true && <span className="text-danger">*</span>}</label>
                                <Select name='year'
                                        onChange={handleCarYear}
                                        options={optionCarYear}
                                        value={defaultCarYear}
                                        ref={
                                            register('year', requiredCarYear)
                                        }
                                /> 
                                {errors.year && 
                                    <span className='text-danger'>{errors.year.message}</span>
                                }  
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-12">
                            <div className="form-group">
                                <label>จำนวนที่นั่ง <span className="text-danger">*</span></label>
                                <Select name='seat'
                                        onChange={handleCarSeat}
                                        options={optionCarSeat}
                                        value={defaultCarSeat}
                                        ref={
                                            register('seat', {required: "กรุณากรอกจำนวนที่นั่ง"})
                                        }
                                />
                                {errors.seat && <span className='text-danger'>{errors.seat.message}</span> }    
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-12">
                            <div className="form-group">
                                <label>ขนาดรถ (ซีซี) {startCarSize === true && <span className="text-danger">*</span>} </label>
                                <input type="number" className="form-control" name='carSize' {...register('carSize', requiredCarSize)} />
                                {errors.carSize && <span className='text-danger'>{errors.carSize.message}</span> }    
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-12">
                            <div className="form-group">
                                <label>น้ำหนักรถ (กิโลกรัม) {starCarWeight === true && <span className="text-danger">*</span>}</label>
                                <input type="number" className="form-control" name='weight' {...register('weight', requiredWeight)} />
                                {errors.weight && <span className='text-danger'>{errors.weight.message}</span> }    
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <div className='form-group'>
                                <label>สี <span className="text-danger">*</span></label>
                                <Select name='color' 
                                    options={optionsCarColor}
                                    value={indexColorState}  
                                    onChange={handleColor} 
                                    ref={
                                        register('color', {required: "กรุณาเลือกสีรถ"})
                                    } 
                                />
                                {errors.color && <span className='text-danger'>{errors.color.message}</span> }
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <div className='form-group'>
                                <label>อื่นๆ ระบุ </label>
                                <input type="text" className="form-control" name='colorDes' value={defaultColorDes} onChange={(e) => setValue('colorDes', e.target.value) } disabled={disabledColorDes} {...register('colorDes', requiredColorDes)} />
                                {errors.colorDes && <span className='text-danger'>{errors.colorDes.message}</span> }
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        </>
    )
}

export default CarModel
