import React from 'react'
import {useAuthUser} from 'react-auth-kit'
import Nav from '../Layout/Nav'
import Header from '../Layout/Header'
import Footer from '../Layout/Footer'
import ListRedeemCommission from './ListRedeemCommission'

function RedeemCommission() {
    const auth = useAuthUser()
    return (
        <>
            <Nav />
            <div className='page'>
                <Header />
                <div className="section-body">
                    <div className="container-fluid">
                        <div className="d-flex justify-content-between align-items-center ">
                            <div className="header-action">
                                <h1 className="page-title">รายการคืนคอมมิชชั่น</h1>
                                <ol className="breadcrumb page-breadcrumb">
                                    <li className="breadcrumb-item"><a href="/dashboard">หน้าแรก</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">รายการคืนคอมมิชชั่น</li>
                                </ol>
                            </div>
                            <ul className="nav nav-tabs page-header-tab">
                                <li className="nav-item"><a className="nav-link active" data-toggle="tab" href="#Staff-all">รายการคืนคอมมิชชั่น</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="section-body mt-4">
                    <div className="container-fluid">
                        <div className="tab-content">
                            <ListRedeemCommission authId={auth().id} />
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default RedeemCommission
