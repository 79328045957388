import React, { useEffect, useRef, useState } from 'react'
import vmi110 from '../../../assets/assets/images/carType/vmi110.png'
import vmi210 from '../../../assets/assets/images/carType/vmi210.png'
import vmi320 from '../../../assets/assets/images/carType/vmi320.png'

function CarType({ formProps: { register, formState: {errors}, setValue }, data}) {
    const [checked, setChecked] = useState()
    const [carBodyShow, setCarBodyShow] = useState()
    const carBodyRef = useRef()
    const { carType, carBody } = data[0]
    const labelCarType = [
        {id: 1, carType: 'sedanorpickup', value: '110', img: vmi110},
        {id: 2, carType: 'van', value: '210', img: vmi210},
        {id: 3, carType: 'truck', value: '320', img: vmi320},
    ]

    const handleCarBody = (e) => {
        if (e.target.value === '110') {
            carBodyRef.current.value = '110 - รถยนต์นั่งใช้ส่วนบุคคล ไม่เกิน 7 ที่นั่ง'
            setValue("carBody", '110 - รถยนต์นั่งส่วนบุคคล')
        } else if (e.target.value === '210') {
            carBodyRef.current.value = '210 - รถยนต์โดยสารใช้ส่วนบุคคล เกิน 7 ที่นั่ง'
            setValue("carBody", '210 - รถยนต์โดยสารใช้ส่วนบุคคล เกิน 7 ที่นั่ง')
        } else if (e.target.value === '320') {
            carBodyRef.current.value = '320 - รถบรรทุก น้ำหนักรวมไม่เกิน 4 ตัน'
            setValue("carBody", '320 - รถบรรทุก น้ำหนักรวมไม่เกิน 4 ตัน')
        }
    }
    return (
        <>
            <div className="card">
                <div className="card-header">
                    <h3 className="card-title">กรุณาเลือกประเภทรถ</h3>
                </div>
                <div className="card-body">
                    <div className="form-group">
                        <div className="row gutters-sm">
                            {labelCarType.map((lct) => (
                                <div key={lct.id} className="col-sm-2">
                                    <label className="imagecheck mb-4">
                                        <input name="carType" type="radio" value={lct.value} className="imagecheck-input" onClick={handleCarBody} {...register('carType', {required: "กรุณาเลือกประเภทรถ"})} />
                                        <figure className="imagecheck-figure">
                                            <img src={lct.img} alt="}" className="imagecheck-image img-thumbnail" />
                                        </figure>
                                    </label>
                                </div>
                            ))}
                            {errors.carType &&
                                <span className='text-danger'>{errors.carType.message}</span>
                            }
                        </div>
                    </div>
                    <div className="col-md-12 col-sm-12">
                        <div className="form-group">
                            <label>แบบตัวถัง <span className="text-danger">*</span></label>
                            <input name="carBody" type="text" value={carBody} className="form-control" {...register('carBody', {required: "กรุณาเลือกประเภทรถ"})} ref={carBodyRef} disabled={true}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CarType
