import React, { useContext, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form'
import { useAuthUser } from 'react-auth-kit'
import Swal from 'sweetalert2';
import axios from 'axios';

import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { createTheme, ThemeProvider } from '@mui/material';

import { ContextVMI } from '../Context/ContextVMI';

import CarType from './Typography/CarType';
import CarModel from './Typography/CarModel';
import CarDetail from './Typography/CarDetail';
import Insured from './Typography/Insured';
import Purchase from './Typography/Purchase';
import Package from './Typography/Package';
import Broker from './Typography/Broker';
import Order from './Typography/Order';

function FormVMI() {
    const auth = useAuthUser()
    const [activeStep, setActiveStep] = useState(0)
    const [open, setOpen] = useState(false);

    const brokerUseForm = useForm()
    const carTypeUseForm = useForm()
    const carModelUseForm = useForm()
    const carDetailUseForm = useForm()
    const insutedUseForm = useForm()
    const purchaseUseForm = useForm()
    const packageUseForm = useForm()
    const oderUseForm = useForm()
    
    const form = useRef()

    const { broker, carType, carModel, carDetail, packageInsure, datePackage, insured } = useContext(ContextVMI)
    
    const theme = createTheme({
        typography: {
          fontFamily: [
            '"Prompt"'
          ].join(','),
        },
    });

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    console.log(packageInsure);

    const TypographyShow = () => {
        switch (activeStep) {
            case 0:
                return <Broker formProps={brokerUseForm} data={broker} />
            case 1:
                return <CarType formProps={carTypeUseForm} data={carType} />
            case 2:
                return <CarModel formProps={carModelUseForm} data={[broker, carModel, carType]} />
            case 3:
                return <CarDetail formProps={carDetailUseForm} data={[broker, carDetail, carModel]} />
            case 4:
                return <Package formProps={packageUseForm} data={[broker, carType, carModel, carDetail, packageInsure]} />
            case 5:
                return <Insured formProps={insutedUseForm} data={[broker, insured, packageInsure]} />
            case 6:
                return <Purchase formProps={purchaseUseForm} data={[broker, packageInsure, datePackage]} />
            case 7:
                return <Order formProps={oderUseForm} data={[broker, carType, carModel, carDetail, insured, packageInsure, datePackage ]} />
            default:
                return 'มีข้อผิดพลาด'
        }
    }

    const handleSubmit = (activeStep) => {
        console.log('handleSubmit ==> ', activeStep);
        // brokerUseForm.handleSubmit(onSubmit)
        if(activeStep === 0){
            return brokerUseForm.handleSubmit(onSubmit)
        } else if(activeStep === 1){
            return carTypeUseForm.handleSubmit(onSubmit)
        } else if(activeStep === 2) {
            return carModelUseForm.handleSubmit(onSubmit)
        } else if(activeStep === 3) {
            return carDetailUseForm.handleSubmit(onSubmit)
        } else if(activeStep === 4) {
            return packageUseForm.handleSubmit(onSubmit)
        } else if(activeStep === 5) {
            return insutedUseForm.handleSubmit(onSubmit)
        } else if(activeStep === 6) {
            return purchaseUseForm.handleSubmit(onSubmit)
        } else if(activeStep === 7) {
            return oderUseForm.handleSubmit(onSubmit)
        }
    }

    const onSubmit = data => {
        if(activeStep === 0){
            broker[1](data)
            handleNext()
        } else if(activeStep === 1){
            carType[1](data)
            handleNext()
        } else if(activeStep === 2) {
            carModel[1](data)
            handleNext()
        } else if(activeStep === 3) {
            carDetail[1](data)
            handleNext()
        } else if(activeStep === 4) {
            packageInsure[1](data)
            handleNext()
        } else if(activeStep === 5) {
            insured[1](data)
            handleNext()
        } else if(activeStep === 6) {
            datePackage[1](data)
            handleNext()
        } else if(activeStep === 7) {
            submit()
        }
    };

    const submit = () => {
        console.log('ส่งคำสั่งซื้อ', packageInsure[0].total);
        getCreditTotal()
        if (creditTotal < packageInsure[0].total) {
            Swal.fire('แจ้งเตือน','ยอดเงินเครดิตของท่านไม่พอ กรุณาเติมเงินค่ะ','error')
        } else {
            sendRequest()
        }
    }

    const [creditTotal, setCreditTotal] = useState()
    const getCreditTotal = () => {
        axios.get(process.env.REACT_APP_API_URL + "/getCreditTotal/" + auth().id)
        .then(res => res.data)
        .then((creditTotal) => setCreditTotal(creditTotal))
        .catch(err => console.log(err))
    }

    const sendRequest = async() => {
        setOpen(true)
        const cdata = [broker[0], carType[0], carModel[0], carDetail[0], insured[0], packageInsure[0], datePackage[0], {userId: auth().id}]
        axios.post(process.env.REACT_APP_API_URL + '/savevmi', cdata)
        .then(response=>{
            setOpen(false)
            console.log('response : ',response.data)
            if (response.data.isSuccess === true | response.data.isSuccess === 'true') {
                const cdata2 = [broker[0], carType[0], carModel[0], carDetail[0], insured[0], packageInsure[0], datePackage[0], {userId: auth().id}, response.data]
                saveCustomer(cdata2)
                console.log(cdata2);
                Swal.fire({
                    title: 'สำเร็จ',
                    icon: 'success',
                    html: 'ซื้อ ประกันภาคสมัครใจ สำเร็จ <br/><a target="_blank" href="'+response.data.file.file_ms+'">ดาวน์โหลดกรมธรรม์</a>',
                    allowOutsideClick: false,
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload()
                    }
                })
            } else {
                console.log('success false');
                Swal.fire({
                    title: 'แจ้งเตือน',
                    text: response.data.messageError,
                    icon: 'error',
                    allowOutsideClick: false,
                })
            }
        }).catch(err=>{
            setOpen(false)
            console.log('error',err);
            Swal.fire('แจ้งเตือน',err,'error')
        })
    }

    const saveCustomer = (data) => {
        axios.post(process.env.REACT_APP_API_URL + '/saveCustomerVMI', data)
        .then(response=>{
            console.log(response);
        }).catch(err=>{
            console.log('error',err)
        })
    }

    useEffect(() => {
        getCreditTotal()
    }, [])
    console.log('active : ', activeStep);
    return (
        <>

            <div className="tab-pane" id="formVMI">
                <div className='row justify-content-md-center'>
                    <div className='col-lg-12 col-md-12 col-sm-12'>
                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={open}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop>
                        <ThemeProvider theme={theme}>
                            <Box sx={{ width: '100%' }}>
                                {/* <form onSubmit={activeStep === 0 ? brokerUseForm.handleSubmit(onSubmit) : carTypeUseForm.handleSubmit(onSubmit)}> */}
                                <form onSubmit={handleSubmit(activeStep)}>
                                    <Stepper activeStep={activeStep} alternativeLabel>
                                        <Step >
                                            <StepLabel>บริษัทประกันภัย</StepLabel>
                                        </Step>
                                        <Step >
                                            <StepLabel>ประเภทรถ</StepLabel>
                                        </Step>
                                        <Step>
                                            <StepLabel>รายละเอียดรถ</StepLabel>
                                        </Step>
                                        <Step >
                                            <StepLabel>การจดทะเบียนรถ</StepLabel>
                                        </Step>
                                        <Step >
                                            <StepLabel>แพ็คเกจประกันภัย</StepLabel>
                                        </Step>
                                        <Step >
                                            <StepLabel>ผู้เอาประกัน</StepLabel>
                                        </Step>
                                        <Step >
                                            <StepLabel>กรมธรรม์</StepLabel>
                                        </Step>
                                        <Step >
                                            <StepLabel>คำสั่งซื้อ</StepLabel>
                                        </Step>
                                    </Stepper>
                                    <Typography sx={{ mt: 2, mb: 1 }}>
                                        {TypographyShow()}
                                    </Typography>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                        <button type='button' className='btn btn-twitter' disabled={activeStep === 0} onClick={handleBack}>กลับ</button>
                                        <Box sx={{ flex: '1 1 auto' }} />
                                        {activeStep === 7
                                            ?<button type='submit' className='btn btn-primary'>สั่งซื้อ</button>
                                            :<button type='submit' className='btn btn-primary'>ถัดไป</button> }
                                    </Box>
                                </form>
                            </Box>
                        </ThemeProvider>
                    </div>
                </div>
            </div>
        </>
    );
}

export default FormVMI;