import React, { useEffect, useState } from 'react'
import { useAuthUser } from 'react-auth-kit'
import { useSignOut } from 'react-auth-kit'
import axios from 'axios'

function Header() {
    const signOut = useSignOut()
    const auth = useAuthUser()
    const [creditTotal, setCreditTotal] = useState()
    const getCreditTotal = () => {
        axios.get(process.env.REACT_APP_API_URL + "/getCreditTotal/" + auth().id)
        .then(res => res.data)
        .then((creditTotal) => setCreditTotal(creditTotal))
        .catch(err => console.log(err))
    }
    const refreshCredit = () => {
        getCreditTotal()
    }
    useEffect(() => {
        getCreditTotal()
    }, [])
    return (
        <>
            <div className="section-body" id="page_top" >
                <div className="container-fluid">
                    <div className="page-header">
                        <div className="left">
                            {auth().role === 3 &&
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item" aria-current="page">
                                            <a href={'/credit?userId=' + auth().id} className='text-muted'>
                                                <img src={`https://cdn-icons-png.flaticon.com/128/2953/2953363.png`} width={20} /> 
                                                ยอดเครดิตคงเหลือ {parseFloat(creditTotal).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits:2})} บาท
                                            </a>
                                        </li>
                                    </ol>
                                </nav>
                            }
                            
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item" aria-current="page">
                                            <a href={'/credit?userId=' + auth().id} className='text-muted'>
                                                <img src={`https://cdn-icons-png.flaticon.com/128/2953/2953363.png`} className='mr-2' width={20} /> 
                                                ยอดเครดิตคงเหลือ {parseFloat(creditTotal).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits:2})} บาท
                                            </a>
                                            <a onClick={refreshCredit}><i className="ml-3 fa fa-refresh text-muted" aria-hidden="true"></i></a>
                                        </li>
                                    </ol>
                                </nav>
                            
                        </div>
                        <div className="right">
                            <div className="notification d-flex">
                                {/* <div className="dropdown d-flex">
                                    <a href='javascript:void(0);' className="nav-link icon d-none d-md-flex btn btn-default btn-icon ml-1" data-toggle="dropdown"><i className="fa fa-bell"></i><span className="badge badge-primary nav-unread"></span></a>
                                    <div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                                        <ul className="list-unstyled feeds_widget">
                                            <li>
                                                <div className="feeds-left">
                                                    <span className="avatar avatar-blue"><i className="fa fa-check"></i></span>
                                                </div>
                                                <div className="feeds-body ml-3">
                                                    <p className="text-muted mb-0">Campaign <strong className="text-blue font-weight-bold">Holiday</strong> is nearly reach budget limit.</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="feeds-left">
                                                    <span className="avatar avatar-green"><i className="fa fa-user"></i></span>
                                                </div>
                                                <div className="feeds-body ml-3">
                                                    <p className="text-muted mb-0">New admission <strong className="text-green font-weight-bold">32</strong> in computer department.</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="feeds-left">
                                                    <span className="avatar avatar-red"><i className="fa fa-info"></i></span>
                                                </div>
                                                <div className="feeds-body ml-3">
                                                    <p className="text-muted mb-0">6th sem result <strong className="text-red font-weight-bold">67%</strong> in computer department.</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="feeds-left">
                                                    <span className="avatar avatar-azure"><i className="fa fa-thumbs-o-up"></i></span>
                                                </div>
                                                <div className="feeds-body ml-3">
                                                    <p className="text-muted mb-0">New Feedback <strong className="text-azure font-weight-bold">53</strong> for university assessment.</p>
                                                </div>
                                            </li>
                                        </ul>
                                        <div className="dropdown-divider"></div>
                                        <a href="javascript:void(0)" className="dropdown-item text-center text-muted-dark readall">Ken Smith all as read</a>
                                    </div>
                                </div> */}
                                <div className="dropdown d-flex">
                                    <a className="chip ml-3" data-toggle="dropdown">
                                        <span className="avatar" style={{backgroundImage: `url('https://cdn-icons-png.flaticon.com/512/3237/3237472.png')`}}></span> 
                                        {auth().name}</a>
                                    <div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                                        <a className="dropdown-item" href="/profile"><i className="dropdown-icon fe fe-user"></i> ข้อมูส่วนตัว</a>
                                        {auth().role === 3 || auth().role === 4 &&
                                            <a className="dropdown-item" href="/creadit"><img src={`https://cdn-icons-png.flaticon.com/128/2953/2953363.png`} width={18} /> จัดการเครดิต</a>
                                        }
                                    </div>
                                </div>
                                <a onClick={() => signOut()} className="nav-link icon settingbar"><i className="fe fe-power"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Header
