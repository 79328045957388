import React, { useEffect, useRef, useState } from 'react'
import moment from 'moment';
import axios from 'axios';

function Purchase({ formProps: { register, formState: {errors}, setValue }, data}) {
    const {broker} = data[0][0]
    const {packageId, packageType, net, scale, stamp, sumInsured, total, vat} = data[1][0]
    const {dateStart, dateStop} = data[2][0]
    const [dateStartState, setDateStartState] = useState(moment().startOf('day').format("YYYY-MM-DD"))
    const [dateStopState, setDateStopState] = useState(moment().startOf('day').add(1, 'years').format("YYYY-MM-DD"))
    const [priceState, setPriceState] = useState(0)
    const [stampState, setStampState] = useState(0)
    const [vatState, setVatState] = useState(0)
    const [priceDefault, setPriceDefault] = useState(0)
    const [priceNetState, setPriceNetState] = useState(0)
    const [priceOverYear, setPriceOverYear] = useState(0)
    const [priceLessYear, setPriceLessYear] = useState(0)
    const [brokerName, setBrokerName] = useState()

    console.log('package => ',data[1][0]);

    const getBroker = () => {
        axios.get(process.env.REACT_APP_API_URL + '/getBrokerName/' + broker)
        .then(res => setBrokerName(res.data.broker_name))
        .catch((err) => console.log(err))
    }

    const handleDateStop = () => {
        setDateStopState(moment(dateStartState).startOf('day').add(1, 'years').format("YYYY-MM-DD"))
        setValue('dateStop', moment(dateStartState).startOf('day').add(1, 'years').format("YYYY-MM-DD"))
        console.log(dateStopState);
    }

    useEffect(() => {
        getBroker()
        handleDateStop()
        setValue('dateStart', dateStartState)
    }, [dateStartState])
    console.log(data);
    return (
        <>
            <div className="card">
                <div className="card-header">
                    <h3 className="card-title">กรมธรรม์</h3>
                </div>
                <div className="card-body">
                    <div className="row clearfix">
                        <div className="col-md-6 col-sm-12">
                            <div className="form-group">
                                <label>วันที่เริ่มกรมธรรม์ <span className="text-danger">*</span></label>
                                <input type="date" className="form-control" name="dateStart" value={dateStartState} onChange={(e) => setDateStartState(e.target.value)}  />
                                {/* <input type="date" className="form-control" name="start" value={dateStartState} onChange={handleDateStart} /> */}
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <div className="form-group">
                                <label>วันที่สิ้นสุดกรมธรรม์ <span className="text-danger">*</span></label>
                                <input type="date" className="form-control" name="dateStop" value={dateStopState} onChange={(e) => setDateStartState(e.target.value)} disabled />
                                {/* <input type="date" className="form-control" name="end" value={dateStopState} onChange={handleDateStop} /> */}
                            </div>
                        </div>
                        <div className="col-md-12 col-sm-12">
                            <label>รายการเบี้ยประกันภัย (บาท)</label>
                            <div className="table-responsive push">
                                <table className="table text-nowrap">
                                    <tbody>
                                        <tr>
                                            <th className="text-center width35"></th>
                                            <th>Product</th>
                                            <th className="text-center" style={{width: "1%"}}>Qnt</th>
                                            <th className="text-right" style={{width: "1%"}}>Unit</th>
                                            <th className="text-right" style={{width: "1%"}}>Amount</th>
                                        </tr>
                                        <tr>
                                            <td className="text-center">1</td>
                                            <td>
                                                <p className="font600 mb-1">ประกันภัยรถยนต์ ประกันภัยชั้น {packageType}</p>
                                                <div className="text-muted">{brokerName}</div>
                                                <div className="text-muted">ทุนประกัน {parseInt(sumInsured).toLocaleString(undefined)} บาท</div>
                                            </td>
                                            <td className="text-center">1</td>
                                            <td className="text-right">{parseFloat(net).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits:2})}</td>
                                            <td className="text-right">{parseFloat(net).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits:2})}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="4" className="font600 text-right">เบี้ยสุทธิ</td>
                                            <td className="text-right">{parseFloat(net).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits:2})}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="4" className="font600 text-right">อากรแสตมป์</td>
                                            <td className="text-right">{parseFloat(stamp).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits:2})}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="4" className="font600 text-right">ภาษีมูลค่าเพิ่ม</td>
                                            <td className="text-right">{parseFloat(vat).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits:2})}</td>
                                        </tr>
                                        <tr className="bg-primary text-light">
                                            <td colspan="4" className="font700 text-right">รวมเงิน</td>
                                            <td className="font700 text-right">{parseFloat(total).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits:2})}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Purchase
