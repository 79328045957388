import React, { useState } from 'react'
import Nav from '../Layout/Nav'
import Header from '../Layout/Header'
import Footer from '../Layout/Footer'
import ListUserGold from './listUser/ListUserGold'
import FormUserSilver from './form/FormUserSilver'

function UserSilver() {
    const search = window.location.search
    let params = new URLSearchParams(search)
    const userId = params.get('userId')
    const [toggle, setToggle] = useState(true)
    const handleToggle = () => {
        console.log(toggle);
        if (toggle === true) {
            setToggle(false)
        } else {
            setToggle(true)
        }
    }
    return (
        <>
            <Nav />
            <div className='page'>
                <Header />
                <div className="section-body">
                    <div className="container-fluid">
                        <div className="d-flex justify-content-between align-items-center ">
                            <div className="header-action">
                                <h1 className="page-title">ผู้ใช้งาน</h1>
                                <ol className="breadcrumb page-breadcrumb">
                                    <li className="breadcrumb-item"><a href="/dashboard">หน้าแรก</a></li>
                                    <li className="breadcrumb-item active" aria-current="page"><a href='/user'>ผู้ใช้งาน</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">จัดการพนักงานขาย</li>
                                    <li className="breadcrumb-item active" aria-current="page">พนักงานขายย่อย</li>
                                </ol>
                            </div>
                            <ul className="nav nav-tabs page-header-tab">
                                <li className="nav-item"><a className="nav-link active" onClick={handleToggle} data-toggle="tab" href="#ListUserGold">รายชื่อผู้ใช้งาน</a></li>
                                <li className="nav-item"><a className="nav-link" data-toggle="tab" href="#formUserSubSeller">เพิ่มข้อมูล</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="section-body mt-4">
                    <div className="container-fluid">
                        <div className="tab-content">
                            <div className="tab-pane active" id="ListUserGold">
                                <ListUserGold toggle={toggle} authid={userId} />
                            </div>
                            <div className="tab-pane" id="formUserSubSeller">
                                <FormUserSilver authid={userId} />
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />     
            </div>
        </>
    )
}

export default UserSilver
