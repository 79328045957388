import React, { useState } from 'react'
import Nav from '../Layout/Nav'
import Header from '../Layout/Header'
import Footer from '../Layout/Footer'
import ListUser from './ListUser'
import FormUser from './FormUser'
import FormUserAdmin from './form/FormUserAdmin'
import ListUserAdmin from './listUser/ListUserAdmin'

function UserAdministrator() {
    const [toggle, setToggle] = useState(true)
    const handleToggle = () => {
        console.log(toggle);
        if (toggle === true) {
            setToggle(false)
        } else {
            setToggle(true)
        }
    }
    return (
        <>
            <Nav />
            <div className='page'>
                <Header />
                <div className="section-body">
                    <div className="container-fluid">
                        <div className="d-flex justify-content-between align-items-center ">
                            <div className="header-action">
                                <h1 className="page-title">ผู้ใช้งาน</h1>
                                <ol className="breadcrumb page-breadcrumb">
                                    <li className="breadcrumb-item"><a href="/dashboard">หน้าแรก</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">ข้อมูลผู้ดูแลระบบ</li>
                                </ol>
                            </div>
                            <ul className="nav nav-tabs page-header-tab">
                                <li className="nav-item"><a className="nav-link active" onClick={handleToggle} data-toggle="tab" href="#Staff-all">รายชื่อผู้ใช้งาน</a></li>
                                <li className="nav-item"><a className="nav-link" data-toggle="tab" href="#formuseradmin">เพิ่มข้อมูล</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="section-body mt-4">
                    <div className="container-fluid">
                        <div className="tab-content">
                            <ListUserAdmin toggle={toggle} />
                            <FormUserAdmin />
                        </div>
                    </div>
                </div>
                <Footer />     
            </div>
        </>
    )
}

export default UserAdministrator
