import React, { useEffect, useState } from 'react'
import axios from 'axios'

function Broker({ formProps: { register, formState: {errors}, setValue }, data}) {

    const { broker } = data[0]
    const [brokers, setBrokers] = useState()
    const getBrokerCMI = () => {
        axios.get(process.env.REACT_APP_API_URL + "/getBrokerCMI")
        .then(res => res.data)
        .then((brokers) => setBrokers(brokers))
        .catch(err => console.log(err))
    }
    useEffect(() => {
        getBrokerCMI()
    },[])
    return (
        <>
            <div className="card">
                <div className="card-header">
                    <h3 className="card-title">บริษัทประกันภัย</h3>
                </div>
                <div className="card-body">
                    <div className="form-group">
                        <label className="form-label">กรุณาเลือกบริษัทประกันภัย</label>
                        <div className="row gutters-sm">
                            {brokers && brokers.map((broker) => (
                                <div key={broker._id} className="col-sm-2">
                                    <label className="imagecheck mb-4">
                                        <input name="broker" type="radio" value={broker.broker_short_name} className="imagecheck-input" {...register('broker', {required:"กรุณาเลือกบริษัทประกัน"})}   />
                                        <figure className="imagecheck-figure">
                                            <img src={process.env.REACT_APP_URL_IMG_LOGO + broker.logo} alt="}" className="imagecheck-image img-thumbnail" />
                                        </figure>
                                    </label>
                                </div>
                            ))}
                            {errors.broker && 
                                <div className='col-sm-12'>
                                    <span className='text-danger'>{errors.broker.message}</span>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Broker
