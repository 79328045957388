import React from 'react'
import Nav from '../Layout/Nav'
import Header from '../Layout/Header'
import Footer from '../Layout/Footer'
import ViewProfile from './ViewProfile'
import FormProfile from './FormProfile'
import FormProfileAdmin from './form/FormProfileAdmin'
import { useAuthUser } from 'react-auth-kit'
import ViewProfileAdmin from './view/ViewProfileAdmin'

function Profile() {
    return (
        <>
            <Nav />
            <div className='page'>
                <Header />
                <div className="section-body">
                    <div className="container-fluid">
                        <div className="d-flex justify-content-between align-items-center ">
                            <div className="header-action">
                                <h1 className="page-title">ข้อมูลส่วนตัว</h1>
                                <ol className="breadcrumb page-breadcrumb">
                                    <li className="breadcrumb-item"><a href="/dashboard">หน้าแรก</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">ข้อมูลส่วนตัว</li>
                                </ol>
                            </div>
                            <ul className="nav nav-tabs page-header-tab">
                                <li className="nav-item"><a className="nav-link active" data-toggle="tab" href="#viewProfile">แสดงข้อมูล</a></li>
                                <li className="nav-item"><a className="nav-link" data-toggle="tab" href="#editProfile">แก้ไขข้อมูล</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="section-body mt-4">
                    <div className="container-fluid">
                        <div className="tab-content">
                            <ViewProfile />
                            <FormProfile />
                        </div>
                    </div>
                </div>
                <Footer />     
            </div>
        </>
    )
}

export default Profile
