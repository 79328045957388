import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import moment from 'moment'
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from 'primereact/dropdown';
import { Tag } from 'primereact/tag';
import SaleDashboard from './SaleDashboard';
        
function ListCMI(props) {
    const [insurance, setInsurance] = useState()
    const [brokers, setBrokers] = useState()
    const [dateStartState, setDateStartState] = useState()
    const [dateStopState, setDateStopState] = useState()
    const formFilter = useRef()
    const toThaiDateString = (date) => {
        console.log('date',date);
        let monthNames = ["",
            "ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.",
            "พ.ค.", "มิ.ย.", "ก.ค.", "ส.ค..",
            "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."
        ];
    
        const yearConvertToInt = parseInt(moment(date).format("YYYY"), 10)
        const monthConvertToInt = parseInt(moment(date).format("MM"), 10)
        let year = yearConvertToInt + 543;
        const subYear = year.toString().substring(2,4);
        let month = monthNames[monthConvertToInt];
        let numOfDay = parseInt(moment(date).format("DD"), 10)
    
        return <p>{numOfDay} {month} {subYear}</p>;
    }

    const getBrokerCMI = () => {
        axios.get(process.env.REACT_APP_API_URL + "/getBrokerCMI")
        .then(res => res.data)
        .then((brokers) => setBrokers(brokers))
        .catch(err => console.log(err))
    }
    const getInsuranceCMI = () => {
        axios.get(process.env.REACT_APP_API_URL + '/getInsuranceCMI/' + props.userAuth)
        .then(res => res.data)
        .then((data) => setInsurance(data))
        .then((err) => console.log(err))
    }
    const getInsuranceCMIFilter = (event) => {
        event.preventDefault()
        const formData = new FormData(formFilter.current)
        const formEnt = Object.fromEntries(formData.entries())
        console.log(formEnt);
        axios.post(process.env.REACT_APP_API_URL + '/getInsuranceCMIFilter/', formEnt)
        .then(res => res.data)
        .then((data) => setInsurance(data))
        .then((err) => console.log(err))
    }
    useEffect(() => {
        getInsuranceCMI()
        getBrokerCMI()
    }, [])
    const [customers, setCustomers] = useState(null);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        'country.name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        status: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] }
    });

    const onGlobalFilterChange = (event) => {
        const value = event.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
    };
    
    
    const renderHeader = () => {
        const value = filters['global'] ? filters['global'].value : '';

        return (
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" value={value || ''} onChange={(e) => onGlobalFilterChange(e)} placeholder="ค้นหา..." />
            </span>
        );
    };
    const header = renderHeader();
    const priceNetBodyTemplate = (rowData) => {
        return parseFloat(rowData.data.price_net).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits:2});
    };

    const actuallyPaidBodyTemplate = (rowData) => {
        return parseFloat(rowData.data.actually_paid).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits:2});
    };

    const fileBodyTemplate = (rowData) => {
        if (rowData.data.file.file_store) {
            return <a target='_blank' href={rowData.data.file.file_store} className='btn btn-light'><i className="fa fa-file-text-o" aria-hidden="true"></i></a>
        } else {
            return <a target='_blank' href={rowData.data.file} className='btn btn-light'><i className="fa fa-file-text-o" aria-hidden="true"></i></a>
        }
    };

    const nameBodyTemplate = (rowData) => {
        if (rowData.data.lastname) {
            if (rowData.data.lastname === undefined) {
                return rowData.data.insured_prefix+rowData.data.name
            } else {
                return rowData.data.name+' '+rowData.data.lastname
            }
        } else {
            if (rowData.data.insured_prefix === 'ว่าง') {
                return rowData.data.name
            } else {
                return rowData.data.insured_prefix+rowData.data.name
            }
        }
    };

    const brokerBodyTemplate = (rowData) => {
        return (
            <div className='text-center'>
                <img className="avatar" src={process.env.REACT_APP_URL_IMG_LOGO + rowData.data.logo} alt="" />
            </div>
        )
    }

    const statusBodyTemplate = (rowData) => {
        if (rowData.data.status === "success") {
            return <span className="tag tag-green">สำเร็จ</span>
        } else {
            if (rowData.data.status.status === 'sendRequest') {
                return <span className="tag tag-warning">ส่งคำขอยกเลิก</span>
            } else {
                return <span className="tag tag-danger">ยกเลิก</span>
            }
        }
    }

    const commissionBodyTemplate = (rowData) => {
        const commission =  rowData.data.price_net-rowData.data.actually_paid
        return (<>
            {parseFloat(commission).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits:2})}
            <small className='ml-1 text-success'><span className=" badge badge-light text-success rounded-pill">{rowData.data.commission_percent}%</span></small>
        </>);
    };
    
    const dateBodyTemplate = (rowData) => {
        return toThaiDateString(rowData.data.created_at)
    }
    return (
        <>
            <div className="tab-pane active" id="listCMI">
                {/* <SaleDashboard userAuth={props.userAuth} /> */}
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <form ref={formFilter} onSubmit={getInsuranceCMIFilter}>
                                    <div className="row">
                                        <div className='col-3'>
                                            <div className="form-group">
                                                <label>วันที่</label>
                                                <input type="date" className="form-control" name="dateStart" value={dateStartState}
                                                    onChange={(e) => {
                                                        setDateStartState(e.target.value)
                                                    }} />
                                            </div>
                                        </div>
                                        <div className='col-3'>
                                            <div className="form-group ml-3">
                                                <label>ถึง วันที่</label>
                                                <input type="date" className="form-control" name="dateStop" value={dateStopState}
                                                    onChange={(e) => {
                                                        setDateStopState(e.target.value)
                                                    }} />
                                            </div>
                                        </div>
                                        <div className='col-3'>
                                            <div className="form-group ml-3">
                                                <label>บริษัท</label>
                                                <select className="form-control show-tick" name='brokerName'>
                                                    <option value="all">-- ทั้งหมด --</option>
                                                    {brokers && brokers.map((broker) => (
                                                        <option value={broker.broker_short_name}>{broker.broker_name}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className='col-3'>
                                            <div className='form-group ml-3 mt-2'>
                                                <br/>
                                                <input type='hidden' name='userId' value={props.userAuth}/>
                                                <button type="submit" className="btn btn-primary btn-block">ค้นหากรมธรรม์</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <DataTable value={insurance} paginator showGridlines rows={10}  dataKey="id" filters={filters}
                    globalFilterFields={['data.broker','data.policy_no', 'data.broker_name', 'data.name', 'data.lastname', 'data.registration_no', 'data.car_type', 'data.price', 'data.stamp', 'data.vat', 'data.price_net' ]}
                    header={header} emptyMessage="ไม่พบข้อมูล">
                    <Column className='font-prompt' field="data.logo" header="โบรคเกอร์" body={brokerBodyTemplate} sortable></Column>
                    <Column className='font-prompt' field="data.broker_name" header="Broker Name" hidden sortable></Column>
                    <Column className='font-prompt' field="data.created_at" header="วันที่แจ้งงาน" body={dateBodyTemplate} sortable></Column>
                    <Column className='font-prompt' field="data.policy_no" header="เลขกรมธรรม์" style={{ minWidth: '150px' }} sortable></Column>
                    {/* <Column className='font-prompt' field="data.contract_number" header="เลขอ้างอิง" style={{ minWidth: '150px' }} sortable></Column> */}
                    <Column className='font-prompt' field="data.name, data.lastname" header="ชื่อ" body={nameBodyTemplate} style={{ minWidth: '200px' }} sortable></Column>
                    <Column className='font-prompt' field="data.registration_no" header="ทะเบียนรถ" sortable></Column>
                    {/* <Column className='font-prompt' field="data.car_type" header="ประเภทรถ" sortable></Column> */}
                    <Column className='font-prompt' field="data.price_net" header="เบี้ยสุทธิ" body={priceNetBodyTemplate} sortable></Column>
                    <Column className='font-prompt' field="data.actually_paid" header="เบี้ยนำส่ง" body={actuallyPaidBodyTemplate} sortable></Column>
                    <Column className='font-prompt' field="data.actually_paid, data.price_net, data.commission_percent" header="คอมมิชชั่น" body={commissionBodyTemplate} sortable></Column>
                    <Column className='font-prompt' field="data.file, data.policy_no" header="กรมธรรม์" body={fileBodyTemplate} sortable></Column>
                    <Column className='font-prompt' header="สถานะ" body={statusBodyTemplate} sortable></Column>
                </DataTable>
            </div>
        </>
    )
}

export default ListCMI
