import axios from 'axios'
import React, { useRef } from 'react'
import Swal from 'sweetalert2'
import '../../../assets/assets/css/styleByDev.css'

function FormBroker() {
    const form = useRef()
    const file = useRef()
    const onSubmit = (event) => {
        event.preventDefault() //ไม่ให้ Browser refresh
        const maxSize = 300
        if (file.current.files[0].size > maxSize * 1024) {
            Swal.fire('แจ้งเตือน',`ขนาดของไฟล์ต้องไม่เกิน ${maxSize} KB`,'error')
        }
        
        const formData = new FormData(form.current)
        // const formEnt = Object.fromEntries(formData.entries())
        axios.post(process.env.REACT_APP_API_URL + '/saveBroker', formData)
        .then(response=>{
            form.current.reset()
            Swal.fire('สำเร็จ','เพิ่มบริษัทประกันภัยสำเร็จ','success')
        }).catch(err=>{
            Swal.fire('แจ้งเตือน',err.response.data.error,'error')
        })
    }
    return (
        <>
            <div className="tab-pane" id="form">
                <div className="row clearfix">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">ข้อมูลบริษัทประกันภัย</h3>
                            </div>
                            <div className="card-body">
                                <form ref={form} onSubmit={onSubmit}>
                                    <div className="row clearfix">
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label>ชื่อบริษัทประกันภัย</label>
                                                <input type="text" name="broker_name" className="form-control" required />
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label>ชื่อย่อ</label>
                                                <input type="text" name="broker_short_name" className="form-control" required />
                                            </div>
                                        </div>
                                        <div className="col-sm-12">
                                            <div className="form-group">
                                                <h3 className="card-title">ผลิตภัณฑ์</h3>
                                            </div>
                                        </div>
                                        <div className="col-sm-12">
                                            <div className="form-group">
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" type="checkbox" name='status_cmi' id="inlineCheckbox1" value="1" />
                                                    <label className="form-check-label" for="inlineCheckbox1">ประกันภาคบังคับ (พรบ.)</label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" type="checkbox" name='status_vmi' id="inlineCheckbox2" value="1" />
                                                    <label className="form-check-label" for="inlineCheckbox2">ประกันภาคสมัครใจ (ประเภท)</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-sm-6'>
                                            <div className='row'>
                                                <div className="col-sm-12">
                                                    <div className="form-group">
                                                        <h3 className="card-title">ค่า Commission ประกันภาคบังคับ (พรบ.)</h3>
                                                    </div>
                                                </div>
                                                <div className="col-10">
                                                    <div className="form-group">
                                                        <label>รถเก๋ง</label>
                                                        <input type="number" name="sedan" className="form-control" placeholder='0.00%' required />
                                                    </div>
                                                </div>
                                                <div className="col-10">
                                                    <div className="form-group">
                                                        <label>รถกระบะ</label>
                                                        <input type="number" name="pickup" className="form-control" placeholder='0.00%' required />
                                                    </div>
                                                </div>
                                                <div className="col-10">
                                                    <div className="form-group">
                                                        <label>รถตู้</label>
                                                        <input type="number" name="van" className="form-control" placeholder='0.00%' required />
                                                    </div>
                                                </div>        
                                            </div>
                                        </div>
                                        <div className='col-sm-6'>
                                            <div className='row'>
                                                <div className="col-sm-12">
                                                    <div className="form-group">
                                                        <h3 className="card-title">ค่า Commission ประกันภาคสมัครใจ (ประเภท)</h3>
                                                    </div>
                                                </div>
                                                <div className="col-10">
                                                    <div className="form-group">
                                                        <label>ประกันชั้น 1</label>
                                                        <input type="number" name="class1" className="form-control" placeholder='0.00%' required />
                                                    </div>
                                                </div>
                                                <div className="col-5">
                                                    <div className="form-group">
                                                        <label>ประกันชั้น 2+</label>
                                                        <input type="number" name="class2plus" className="form-control" placeholder='0.00%' required />
                                                    </div>
                                                </div>
                                                <div className="col-5">
                                                    <div className="form-group">
                                                        <label>ประกันชั้น 2</label>
                                                        <input type="texnumbert" name="class2" className="form-control" placeholder='0.00%' required />
                                                    </div>
                                                </div>
                                                <div className="col-5">
                                                    <div className="form-group">
                                                        <label>ประกันชั้น 3+</label>
                                                        <input type="number" name="class3plus" className="form-control" placeholder='0.00%' required />
                                                    </div>
                                                </div>
                                                <div className="col-5">
                                                    <div className="form-group">
                                                        <label>ประกันชั้น 3</label>
                                                        <input type="number" name="class3" className="form-control" placeholder='0.00%' required />
                                                    </div>
                                                </div>        
                                            </div>
                                        </div>
                                        <div className="col-sm-12">
                                            <div className='form-group'>
                                                <label>Logo</label><br/>                        
                                                <input type="file" name='logo' ref={file}/>    
                                            </div>
                                        </div>
                                        <div className="col-sm-12">
                                            <button type="submit" className="btn btn-primary mr-2">บันทึก</button>
                                            <button type="reset" className="btn btn-outline-secondary">ยกเลิก</button>
                                        </div> 
                                    </div>    
                                </form>
                            </div>
                        </div>
                    </div>
                </div>    
            </div>
        </>
    )
}

export default FormBroker

