import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Swal from 'sweetalert2'

function ListUserLevel(props) {
    const [users, setUsers] = useState()
    const no = 1
    const getUser = () => {
        axios.get(process.env.REACT_APP_API_URL + "/getUser/" + props.manageRole + "/" + props.userIdLead)
        .then(res => res.data)
        .then((users) => setUsers(users))
        .catch(err => console.log(err))
    }

    console.log(props.manageRole);
    
    const showRole = (role) => {
        console.log('show role',role);
        switch (role) {
            case 'platinum':
                return (<span className="tag tag-success">Platinum</span>)
                break;
            case 'gold':
                return (<span className="tag tag-success">Gold</span>)
                break;
            case 'silver':
                return (<span className="tag tag-success">Silver</span>)
                break;
            default:
                return 'เกิดข้อผิดพลาด'
                break;
        }
    }

    const showStatus = (status_active) => {
        if (status_active === 'active') {
            return (<span className="tag tag-success">เปิดใช้งาน</span>)
        } else if(status_active === 'inactive') {
            return (<span className="tag tag-danger">ถูกระงับการใช้งาน</span>)
        }
    }

    const hrefManageUser = (manageRole, userIdLead) => {
        if (manageRole === 'platinum') {
            return (<a className="dropdown-item" href={"/manageUser?manageRole=gold&userId=" + userIdLead}><i className="fa fa-user-o"></i> จัดการสมาชิก</a>)
        } else if(manageRole === 'gold') {
            return (<a className="dropdown-item" href={"/manageUser?manageRole=silver&userId=" + userIdLead}><i className="fa fa-user-o"></i> จัดการสมาชิก</a>)
        }
    }

    const delUser = (id) => {
        Swal.fire({
            title: 'คุณต้องการลบข้อมูลใช่หรือไม่',
            text: 'หากคุณลบผู้ใช้งานรายนี้ ระบบจะทำการลบสมาชิกที่เกี่ยวข้องกับผู้ใช้งานรายนี้ด้วย',
            confirmButtonColor: '#dc3545',
            showCancelButton: true,
            confirmButtonText: 'ใช่',
            cancelButtonText: 'ไม่ใช่'
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                axios.get(process.env.REACT_APP_API_URL + '/delUser/' + id)
                .then(res => {
                    console.log(res);
                    Swal.fire('สำเร็จ',res.data.success,'success')
                }).catch(err=>{
                    console.log(err)
                    Swal.fire('Error','เกิดข้อผิดพลาดบางอย่าง','error')
                })
            } else if (result.isDenied) {
                Swal.fire('Changes are not saved', '', 'info')
            }
        })
    }

    useEffect(() => {
        getUser()
    }, [props.toggle])
    console.log(users);
    return (
        <>
            <div className="card">
                <div className="table-responsive">
                    <table className="table table-hover table-vcenter text-nowrap table-striped mb-0">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>ชื่อ</th>
                                <th>อีเมล</th>
                                <th>ระดับสิทธิ์</th>
                                <th>สถานะการใช้งาน</th>
                                <th>การตั้งค่า</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users && users.map((user,no) => (
                                <tr key={user._id}>
                                    <td className="w60">{no+1}</td>
                                    <td><div className="font-15">{user.user_detail.firstname} {user.user_detail.lastname}</div></td>
                                    <td>{user.email}</td>
                                    <td><span className="text-muted">{showRole(user.role)}</span></td>
                                    <td>{showStatus(user.status_active)}</td>
                                    <td>
                                        <a className="nav-link dropdown-toggle text-dark" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false"><i className="fa fa-bars" aria-hidden="true"></i></a>
                                        <div className="dropdown-menu">
                                            {/* <a className="dropdown-item" href={"/manageUser?managerole=" + props.manageRole + "&userId=" + user._id}><i className="fa fa-user-o"></i> จัดการ SubSeller</a> */}
                                            {/* <a className="dropdown-item" href={hrefManageUser(props.manageRole, user._id)}><i className="fa fa-user-o"></i> จัดการ SubSeller</a> */}
                                            {hrefManageUser(props.manageRole, user._id)}
                                            <a className="dropdown-item" href={"/commission/" + user._id}><img src={`https://cdn-icons-png.flaticon.com/128/2652/2652181.png`} width={18} /> Commission</a>
                                            <a className="dropdown-item" href={"/credit?userId=" + user._id}><img src={`https://cdn-icons-png.flaticon.com/128/2953/2953363.png`} width={18} /> จัดการเครดิต</a>
                                            <a className="dropdown-item" href={"edit/" + user.role + "/" + user._id}><i className="fa fa-edit"></i> แก้ไขข้อมูล</a>
                                            <a className="dropdown-item" onClick={() => delUser(user._id)}><i className="fa fa-trash-o"></i> ลบข้อมูล</a>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default ListUserLevel
