import React, { Component } from 'react'
import Footer from '../Layout/Footer'
import Header from '../Layout/Header'
import Nav from '../Layout/Nav'
import FormVMI from './FormVMI'
import ListVMI from './ListVMI'
import { ContextVMIProvider } from '../Context/ContextVMI';
import {useAuthUser} from 'react-auth-kit'

function InsureVMI() {
    const auth = useAuthUser()
    return (
        <>
            <Nav />
            <div className='page'>
                <Header />
                <div className="section-body">
                    <div className="container-fluid">
                        <div className="d-flex justify-content-between align-items-center ">
                            <div className="header-action">
                                <h1 className="page-title">ซื้อประกัน</h1>
                                <ol className="breadcrumb page-breadcrumb">
                                    <li className="breadcrumb-item"><a href="/dashboard">หน้าแรก</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">ซื้อประกัน</li>
                                </ol>
                            </div>
                            <ul className="nav nav-tabs page-header-tab">
                                <li className="nav-item"><a className="nav-link active" data-toggle="tab" href="#listVMI">แสดงรายการประกัน</a></li>
                                <li className="nav-item"><a className="nav-link" data-toggle="tab" href="#formVMI">ซื้อประกันภัย</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="section-body mt-4">
                    <div className="container-fluid">
                        <div className="tab-content">
                            <ListVMI userAuth={auth().id} />
                            <ContextVMIProvider>
                                <FormVMI />
                            </ContextVMIProvider>
                        </div>
                    </div>
                </div>
                <Footer />     
            </div>
        </>
    )
}

export default InsureVMI
