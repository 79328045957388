import React, { Component } from 'react'
import Footer from '../../Layout/Footer'
import Header from '../../Layout/Header'
import Nav from '../../Layout/Nav'
import FormEditBroker from './FormEditBroker'

export default class EditBroker extends Component {
    render() {
        return (
            <>
                <Nav />
                <div className='page'>
                    <Header />
                    <div className="section-body">
                        <div className="container-fluid">
                            <div className="d-flex justify-content-between align-items-center ">
                                <div className="header-action">
                                    <h1 className="page-title">แก้ไขข้อมูลบริษัทประกันภัย</h1>
                                    <ol className="breadcrumb page-breadcrumb">
                                        <li className="breadcrumb-item"><a href="/dashboard">หน้าแรก</a></li>
                                        <li className="breadcrumb-item"><a href="/broker">บริษัทประกันภัย</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">แก้ไขข้อมูลบริษัทประกันภัย</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="section-body mt-4">
                        <div className="container-fluid">
                            <FormEditBroker />
                        </div>
                    </div>
                    <Footer />   
                </div>
            </>
        )
    }
}
