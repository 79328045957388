import React from 'react'
import Footer from '../Layout/Footer'
import Header from '../Layout/Header'
import Nav from '../Layout/Nav'
import {useAuthUser} from 'react-auth-kit'
import ListCancleInsurance from './ListCancleInsurance'

function ManageInsurance() {
    const auth = useAuthUser()
    return (
        <>
            <Nav />
            <div className='page'>
                <Header />
                <div className="section-body">
                    <div className="container-fluid">
                        <div className="d-flex justify-content-between align-items-center ">
                            <div className="header-action">
                                <h1 className="page-title">จัดการกรมธรรม์</h1>
                                <ol className="breadcrumb page-breadcrumb">
                                    <li className="breadcrumb-item"><a href="/dashboard">หน้าแรก</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">รายการคำขอยกเลิกกรมธรรม์</li>
                                </ol>
                            </div>
                            {/* <ul className="nav nav-tabs page-header-tab">
                                <li className="nav-item"><a className="nav-link active" data-toggle="tab" href="#listVMI">รายการคำขอยกเลิกกรมธรรม์</a></li>
                                <li className="nav-item"><a className="nav-link" data-toggle="tab" href="#formVMI">ซื้อประกันภัย</a></li>
                            </ul> */}
                        </div>
                    </div>
                </div>
                <div className="section-body mt-4">
                    <div className="container-fluid">
                        {/* <div className="tab-content">
                            <ListCancleInsurance userAuth={auth().id} />
                        </div> */}
                        <ListCancleInsurance />
                    </div>
                </div>
                <Footer />     
            </div>
        </>
    )
}

export default ManageInsurance
