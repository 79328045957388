import React from 'react'
import { useAuthUser } from 'react-auth-kit';
import ViewProfileAdmin from './view/ViewProfileAdmin';

function ViewProfile() {
    const auth = useAuthUser()
    const viewProfile = () => {
        switch (auth().role) {
            case 1:
                return <ViewProfileAdmin />
            default:
                break;
        }
    }
    return (
        <>
            <div className="tab-pane active" id="viewProfile">
                {viewProfile()}
            </div>
        </>
    )
}

export default ViewProfile
