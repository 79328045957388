import axios from 'axios'
import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import moment from 'moment'

function CarDetail({ formProps: { register, formState: {errors}, setValue }, data}) {
    console.log(data);
    const {broker} = data[0][0]
    const {registrationType, registrationNo, registrationProvince, registrationProvinceDisable, registrationYear, carCountry, carCountryDisable, chassisNumber, engineNumber} = data[1][0]
    const [optionCarRegisYear, setOptionCarRegisYear] = useState()
    const [defaultCarRegisYear, setDefaultCarRegisYear] = useState(registrationYear)
    const [defaultRegistrationProvince, setDefaultRegistrationProvince] = useState(registrationProvince)
    const [defaultCarCountry, setDefaultCarCountry] = useState(carCountry)
    const [optionRegisSpecial, setOptionRegisSpecial] = useState()
    const [optionRegisPrv, setOptionRegisPrv] = useState()
    const [optionCarCountry, setOptionCarCountry] = useState()
    const [isDisabled, setIsDisabled] = useState(registrationProvinceDisable)
    const [isDisabledCarCountry, setIsDisabledCarCountry] = useState(carCountryDisable)
    const [requiredRegistrationProvince, setRequiredRegistrationProvince] = useState()
    const [requiredCarCountry, setRequiredCarCountry] = useState()
    const carRegisYear = () => {
        const yearNow = parseInt(moment().format("YYYY"), 10)
        const countLoop = yearNow - 1900
        const element = []
        for (let index = 0; index <= countLoop; index++) {
            const value = yearNow-index
            element[index] = {value: value, label: value };
        }
        setOptionCarRegisYear(element)
    }
    const getRegisPrv = () => {
        axios.get(process.env.REACT_APP_API_URL + '/getRegisPrv/' + broker + '/vmi')
        .then(res => res.data)
        .then((regisPrvData) => {
            setOptionRegisPrv([])
            regisPrvData.map((rp, index) => (
                setOptionRegisPrv((prevState) => [...prevState, {index: index, value: rp.regisPrv_code, label: rp.regisPrv_name}])  
            ))
        })
        .catch((err) => console.log(err))
    }
    const getRegisSpc = () => {
        axios.get(process.env.REACT_APP_API_URL + '/getRegisSpc/' + broker)
        .then(res => res.data)
        .then((regisSpcData) => {
            setOptionRegisSpecial([])
            regisSpcData.map((rp, index) => (
                setOptionRegisSpecial((prevState) => [...prevState, {index: index, value: rp.regisSpecial_code, label: rp.regisSpecial_name}])  
            ))
        })
        .catch((err) => console.log(err))
    }
    const getCarCountry = () => {
        axios.get(process.env.REACT_APP_API_URL + '/getCarCountry/' + broker)
        .then(res => res.data)
        .then((carCountry) => {
            console.log('car country = ', carCountry);
            setOptionCarCountry([])
            carCountry.map((cc, index) => (
                setOptionCarCountry((prevState) => [...prevState, {index: index, value: cc.country_code, label: cc.country_name_th}])  
            ))
        })
        .catch((err) => console.log(err))
    }

    useEffect(() => {
        getRegisPrv()
        getRegisSpc()
        carRegisYear()
        if (isDisabledCarCountry === false) {
            getCarCountry() //เรียกใช้งาน fn getCarCountry()
        }
    }, [])

    const handleRegisProvince = (e) => {
        console.log('province : ', e);
        setValue("registrationProvince", e)
        setDefaultRegistrationProvince(e)
    }

    const handleCarContry = (e) => {
        setValue("carCountry", e)
        setDefaultCarCountry(e)
        setRequiredCarCountry({required: false})
        console.log(requiredCarCountry)
    }

    const handleRegisSpc = (e) => {
        console.log('registration type : ', e.target);
        if (e.target.value === '2') {
            //เลือกประเภททะเบียน ป้ายแดง
            console.log('e.target.value is 1')
            setIsDisabled(true) //ฟิลด์ทะเบียนจังหวัดเปิด
            setValue('registrationProvinceDisable', true) //ค่า registrationProvinceDisable มีค่าเป็น false
            setValue('registrationProvince', {index: 78, value: '79', label: 'ป้ายแดง'})
            setDefaultRegistrationProvince({index: 78, value: '79', label: 'ป้ายแดง'})

            setValue('carCountryDisable', true) //disable ฟิลด์ carCountry
            setIsDisabledCarCountry(true) //disable ฟิลด์ carCountry
            setValue('carCountry', {index: 242, value: 'THA', label: 'ไทย'}) //กำหนดให้ carCountry เป็น ไทย
            setDefaultCarCountry({index: 242, value: 'THA', label: 'ไทย'}) //กำหนดค่าเริ่มต้นของ carCountry ให้เป็น ไทย
        } else if(e.target.value === '4') {
            //เลือกประเภททะเบียน ต่างประเทศ
            console.log('e.target.value is 3')
            setIsDisabled(true) //disable ฟิลด์ registrationProvince
            setValue('registrationProvinceDisable', true) //disable ฟิลด์ registrationProvince

            setValue('carCountryDisable', false) //เปิดฟิลด์ carCountry
            setIsDisabledCarCountry(false) //เปิดฟิลด์ carCountry
            setRequiredCarCountry({required: "กรุณาเลือกประเทศรถ"}) //required ฟิลด์ carCountry
            setRequiredRegistrationProvince({required: false}) //ยกเลิกการ required ฟิลด์ registrationProvince
            errors.registrationProvince = '' //ไม่แสดงข้อความ required ฟิลด์ registrationProvince
            getCarCountry() //เรียกใช้งาน fn getCarCountry()
        } else {
            console.log('e.target.value is not 1')
            setIsDisabled(false) //disable ฟิลด์ registrationProvince
            setValue('registrationProvinceDisable', false) //disable ฟิลด์ registrationProvince
            setRequiredRegistrationProvince({required: "กรุณาเลือกทะเบียนจังหวัด"}) //ยกเลิก required ฟิลด์ registrationProvince

            setValue('carCountryDisable', true) //disable ฟิลด์ carCountry
            setIsDisabledCarCountry(true) //disable ฟิลด์ carCountry
            setValue('carCountry', {index: 242, value: 'THA', label: 'ไทย'}) //กำหนดให้ carCountry เป็น ไทย
            setDefaultCarCountry({index: 242, value: 'THA', label: 'ไทย'}) //กำหนดค่าเริ่มต้นของ carCountry ให้เป็น ไทย
        }
    }

    const handleCarRegisYear = (event) => {
        setValue('registrationYear', event)
        setDefaultCarRegisYear(event)
        errors.registrationYear = ''
    }
    return (
        <>
            <div className="card">
                <div className="card-header">
                    <h3 className="card-title">การจดทะเบียนรถ</h3>
                </div>
                <div className="card-body">
                    <div className="row clearfix">
                        <div className="col-md-12 col-sm-12">
                            <div className="form-group">
                                <label>ประเภททะเบียน <span className="text-danger">*</span></label>
                                <div className="custom-controls-stacked">
                                    {optionRegisSpecial && optionRegisSpecial.map((ops) => (
                                        <label className="custom-control custom-radio custom-control-inline">
                                            <input type="radio" className="custom-control-input" name="registrationType" value={ops.value} onClick={handleRegisSpc} {...register('registrationType', {required: "กรุณาเลือกประเภททะเบียนรถ"})} />
                                            <span className="custom-control-label">{ops.label}</span>
                                        </label>
                                    ))}
                                </div>
                                {errors.registrationType &&
                                    <span className='text-danger'>{errors.registrationType.message}</span>
                                }
                            </div>
                        </div>
                        <div className="col-md-12 col-sm-12">
                            <div className='form-group'>
                                <label>รถประเทศ <span className="text-danger">*</span></label>
                                <Select
                                    name='carCountry'
                                    onChange={handleCarContry}
                                    options={optionCarCountry}
                                    isDisabled={isDisabledCarCountry}
                                    value={defaultCarCountry}
                                    ref={
                                        register('carCountry', requiredCarCountry)
                                    }
                                />
                                {errors.carCountry &&
                                    <span className='text-danger'>{errors.carCountry.message}</span>
                                }
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <div className="form-group">
                                <label>ทะเบียนรถ <span className="text-danger">*</span></label><small> ไม่ต้องใส่ขีด ไม่ต้องเว้นวรรค</small>
                                <input type="text" className="form-control" name='registrationNo' placeholder='กก1234, 1กก1234' {...register('registrationNo', {required: "กรุณาระบุหมายเลขทะเบียน"})} />
                                {errors.registrationNo &&
                                    <span className='text-danger'>{errors.registrationNo.message}</span>
                                }
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <div className='form-group'>
                                <label>ทะเบียนจังหวัด <span className="text-danger">*</span></label>
                                <Select
                                    name='registrationProvince'
                                    onChange={handleRegisProvince}
                                    options={optionRegisPrv}
                                    isDisabled={isDisabled}
                                    value={defaultRegistrationProvince}
                                    ref={
                                        register('registrationProvince', requiredRegistrationProvince)
                                    }
                                    // {...register('makeCode', {required: "กรุณากรอกรุ่นรถ"})}
                                />
                                {errors.registrationProvince &&
                                    <span className='text-danger'>{errors.registrationProvince.message}</span>
                                }
                            </div>
                        </div>
                        <div className="col-md-12 col-sm-12">
                            <div className="form-group">
                                <label>หมายเลขตัวถัง <span className="text-danger">*</span></label>
                                <input type="text" className="form-control bg-light-azure text-uppercase" name='chassisNumber' {...register('chassisNumber', {required: "กรุณาระบุหมายเลขตัวถัง"})} />
                                {errors.chassisNumber &&
                                    <span className='text-danger'>{errors.chassisNumber.message}</span>
                                }
                            </div>
                        </div>
                        <div className="col-md-12 col-sm-12">
                            <div className="form-group">
                                <label>หมายเลขเครื่องยนต์</label>
                                <input type="text" className="form-control" name='engineNumber' {...register('engineNumber')}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CarDetail
