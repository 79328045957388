import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Select from 'react-select'
import moment from 'moment'

function CarModel({ formProps: { register, formState: {errors}, setValue }, data}) {
    const { broker } = data[0][0]
    const { makeCode, indexMakeCode, modelCode, defaultModel, subModelCode, defaultSubModel, 
            year, carSize, seat, weight, color, defaultColor, colorDes } = data[1][0]
    const { carType, carBody } = data[2][0]
    const [optionCarYear, setOptionCarYear] = useState()
    const [defaultCarYear, setDefaultCarYear] = useState(year)
    const [optionCarSeat, setOptionCarSeat] = useState()
    const [defaultCarSeat, setDefaultCarSeat] = useState(seat)
    const [indexMakeCodeState, setIndexMakeCodeState] = useState(indexMakeCode)
    const [defaultModelState, setDefaultModelState] = useState(defaultModel)
    const [indexColorState, setIndexColorState] = useState(defaultColor)
    const [makeCodeState, setMakeCodeState] = useState()
    const [modelCodeState, setModelCodeState] = useState()
    const [subModelCodeState, setSubModelCodeState] = useState()
    const [defaultSubModelState, setDefaultSubModelState] = useState()
    const [optionCarSize, setOptionCarSize] = useState()
    const [optionsCarColor, setOptionCarColor] = useState()
    const [optionsCarModel, setOptionCarModel] = useState()
    const [optionsCarMake, setOptionCarMake] = useState([])
    const [optionSubModel, setOptionSubModel] = useState()
    const [requiredCarSize, setRequiredCarSize] = useState()
    const [startCarSize, setStarCarSize] = useState()
    const [requiredWeight, setRequiredWeight] = useState()
    const [starCarWeight, setStarCarWeight] = useState()
    const [requiredColorDes, setRequiredColorDes] = useState()
    const [disabledColorDes, setDisabledColorDes] = useState()
    const [defaultColorDes, setDefaultColorDes] = useState()
    const [defaultCarSize, setDefaultCarSize] = useState(carSize)
    const [defaultWeight, setDefaultWeight] = useState(weight)
    const [loadingModel, setLoadingModel] = useState(false)
    const [loadingSubModel, setLoadingSubModel] = useState(false)
    const [loadingCarYear, setLoadingCarYear] = useState(false)

    const carYear = () => {
        if (broker === 'thaisri' | broker === 'allianz') {
            axios.post(process.env.REACT_APP_API_URL + "/getCarYear", {
                "make_code": makeCodeState,
                "model_code": modelCodeState,
                "broker": broker
            })
            .then(res => res.data)
            .then((carYear) => {
                setOptionCarYear([])
                carYear.map((cy, index) => (
                    setOptionCarYear((prevState) => [...prevState, {index: index, value: cy.car_year, label: cy.car_year}])  
                ))
            })
            .catch(err => console.log(err))
        } else if(broker === 'muangthai') {
            axios.post(process.env.REACT_APP_API_URL + "/getCarYear", {
                "make_code": makeCodeState,
                "model_code": modelCodeState,
                "broker": broker
            })
            .then(res => res.data)
            .then((carYear) => {
                setOptionCarYear([])
                carYear.map((cy, index) => (
                    setOptionCarYear((prevState) => [...prevState, {index: index, value: cy.YearGroupID, label: cy.YearGroupID}])  
                ))
            })
            .catch(err => console.log(err))
        } else {
            const yearNow = parseInt(moment().format("YYYY"), 10)
            const countLoop = yearNow - 1900
            const element = []
            for (let index = 0; index <= countLoop; index++) {
                const value = yearNow-index
                element[index] = {value: value, label: value };
            }
            setOptionCarYear(element)    
        }
    }

    const carSeat = () => {
        const element = []
        for (let index = 1; index < 100; index++) {
            element[index] = {value: index, label: index };
        }
        setOptionCarSeat(element)
    }


    const getCarMake = () => {
        axios.post(process.env.REACT_APP_API_URL + "/getCarMake",
            {
                broker: broker,
                type: 'vmi'
            }
        )
        .then(res => res.data)
        .then((carMake) => {
            if (broker === 'muangthai') {
                setOptionCarMake([])
                carMake.map((cm, index) => (
                    setOptionCarMake((prevState) => [...prevState, {index: index, value: cm.MakeCode, label: cm.Description}])  
                ))
            } else if(broker === 'tokio' | broker === 'deves') {
                setOptionCarMake(carMake)
            } else {
                setOptionCarMake([])
                carMake.map((cm, index) => (
                    setOptionCarMake((prevState) => [...prevState, {index: index, value: cm.make_code, label: cm.make_name}])  
                ))
            }
        })
        .catch(err => console.log(err))
    }

    const getCarModel = (makeCode) => {
        setLoadingModel(true)
        axios.post(process.env.REACT_APP_API_URL + "/getCarModel",
            {
                broker: broker,
                type: 'vmi',
                makeCode: makeCode,
                carType: carType
            }
        )
        .then(res => res.data)
        .then((carModel) => {
            console.log('car model =>',carModel);
            if(broker === 'thaisri') {
                setOptionCarModel([])
                carModel.map((cm, index) => (
                    setOptionCarModel((prevState) => [...prevState, {index: index, value: cm.submodel_code, label: cm.submodel_name}])
                ))
            } else if(broker === 'allianz') {
                setOptionCarModel([])
                carModel.map((cm, index) => (
                    setOptionCarModel((prevState) => [...prevState, {index: index, value: cm.model_code, label: cm.model_name, seat: cm.seats, carSize: cm.car_size, weight: cm.weight}])
                ))
            } else if(broker === 'tokio' | broker === 'deves' | broker === 'muangthai') {
                setOptionCarModel(carModel)
            } else {
                setOptionCarModel([])
                carModel.map((cm, index) => (
                    setOptionCarModel((prevState) => [...prevState, {index: index, value: cm.model_code, label: cm.model_name}])
                ))
            }
            setLoadingModel(false)
        })
        .catch(err => console.log(err))
    }

    const getSubModel = (makeCode, modelCode) => {
        setLoadingSubModel(true)
        axios.get(process.env.REACT_APP_API_URL + "/getSubModel/" + broker + '/' + makeCode + '/' + modelCode)
        .then(res => res.data)
        .then((subModel) => {
            setOptionSubModel(subModel)
            setLoadingSubModel(false)
        })
    }

    const getCarColor = () => {
        if (broker === 'tokio') {
            setOptionCarColor([{index: 0, value: 'ไม่ระบุ', label: 'ไม่ระบุ'}, {index: 0, value: 'อื่น ๆ', label: 'อื่น ๆ'}])
        } else {
            axios.get(process.env.REACT_APP_API_URL + "/getCarColor/" + broker)
            .then(res => res.data)
            .then((carColor) => {
                setOptionCarColor([])
                carColor.map((cc, index) => (
                    setOptionCarColor((prevState) => [...prevState, {index: index, value: cc.color_code, label: cc.color_name_th}])
                ))
                setIndexColorState(optionsCarColor)
            })
            .catch(err => console.log(err))
        }
    }

    const handleCarMake = (event) => {
        getCarModel(event.value)
        // modelCodeRef.clearValue();
        setValue('makeCode', event)
        setValue('indexMakeCode', event.index)
        setIndexMakeCodeState(event.index)
        setDefaultModelState()
        setMakeCodeState(event.value)
        errors.makeCode = ''
    }

    const handleCarModel = (event) => {
        if (broker === 'allianz') {
            setValue('modelCode', event)
            const modelCodeDefault = {index: event.index, value: event.value, label: event.label}
            setValue('defaultModel', modelCodeDefault)
            setDefaultModelState(modelCodeDefault)
            setDefaultCarSeat({value: event.seat, label: event.seat})
            setValue('seat', {value: event.seat, label: event.seat})
            setDefaultCarSize(event.carSize)
            setValue('carSize', event.carSize)
            setDefaultWeight(event.weight)
            setValue('weight', event.weight)
            errors.modelCode = ''
            setModelCodeState(event.value)
        } else if (broker === 'tokio') {
            setValue("modelCode", event)
            setValue("defaultModel", event)
            setDefaultModelState(event)
            errors.modelCode = ''
            setModelCodeState(event.value)
            getSubModel(event.brandId, event.value)
        } else {
            setValue('modelCode', event)
            setValue('defaultModel', event)
            setDefaultModelState(event)
            errors.modelCode = ''
            setModelCodeState(event.value)
        }
    }

    const handleCarSubModel = (event) => {
        console.log(event);
        setValue('subModelCode', event)
        const subModelCodeDefault = {index: event.index, value: event.value, label: event.label}
        setValue('defaultSubModel', subModelCodeDefault)
        setDefaultSubModelState(subModelCodeDefault)
        setSubModelCodeState(event.value)
        carYearTokio(event)
    }

    const carYearTokio = (data) => {
        console.log('caryeartokio', data);
        axios.post(process.env.REACT_APP_API_URL + "/getCarYear", {
            "make_code": data.makeCode,
            "model_code": data.modelCode,
            "broker": broker,
            "submodel_name": data.label
        })
        .then(res => res.data)
        .then((carYear) => {
            setOptionCarYear([])
            carYear.map((cy, index) => (
                setOptionCarYear((prevState) => [...prevState, {index: index, value: cy.SubModel_Year, label: cy.SubModel_Year, carSize: cy.SubModel_Displacement, weight: cy.SubModel_Weight}])  
            ))
        })
    }

    const handleCarYear = (event) => {
        setValue('year', event)
        setDefaultCarYear(event)
        errors.year = ''
        if (broker === 'tokio') {
            setDefaultCarSize(event.carSize)
            setValue('carSize', event.carSize)
            setDefaultWeight(event.weight)
            setValue('weight', event.weight)
        } else if(broker === 'muangthai') {
            setLoadingCarYear(true)
            axios.post(process.env.REACT_APP_API_URL + "/getCarSize", {
                "make_code": makeCodeState,
                "model_code": modelCodeState,
                "broker": broker,
                "year": event.value
            })
            .then(res => res.data)
            .then((carSize) => {
                setOptionCarSize([])
                carSize.map((cs, index) => (
                    setOptionCarSize((prevState) => [...prevState, {index: index, value: cs.EngineSize, label: cs.EngineSize}])  
                ))
                setLoadingCarYear(false)
            })
        }
    }

    const handleCarSize = (event) => {
        console.log(event);
        setValue('carSize', event.value)
        setDefaultCarSize(event)
        errors.carSize = ''
    }

    const handleCarSeat = (event) => {
        setValue('seat', event)
        setDefaultCarSeat(event)
        errors.seat = ''
    }

    const handleColor = (event) => {
        setValue('color', event)
        const colorDefault = {index: event.index, value: event.value, label: event.label}
        setValue('defaultColor', colorDefault)
        setIndexColorState(colorDefault)
        if (event.label === 'อื่น ๆ') {
            setDisabledColorDes(false)
            setRequiredColorDes({required: "กรุณาระบุสี"})
            setDefaultColorDes()
        } else {
            setDisabledColorDes(true)
            setRequiredColorDes(false)
            setDefaultColorDes(event.label)
            errors.colorDes = ''
        }
        console.log('handle color', requiredColorDes);
        errors.color = ''
    }

    const setDefault = () => {
        if (carType === '110') {
            if (seat === undefined) {
                setDefaultCarSeat({value: 7, label: 7})
                setValue('seat', {value: 7, label: 7})    
            }
            setRequiredCarSize({required: "กรุณากรอกขนาดรถ (ซีซี)"})
            setStarCarSize(true)
            setRequiredWeight({required: false})
            setStarCarWeight(false)
        } else if (carType === '210') {
            if (seat === undefined) {
                setDefaultCarSeat({value: 12, label: 12})
                setValue('seat', {value: 12, label: 12})     
            }
            setRequiredCarSize({required: "กรุณากรอกขนาดรถ (ซีซี)"})
            setStarCarSize(true)
            setRequiredWeight({required: false})
            setStarCarWeight(false)
        } else if (carType === '320') {
            if (seat === undefined) {
                setDefaultCarSeat({value: 3, label: 3})
                setValue('seat', {value: 3, label: 3})     
            }
            setRequiredCarSize({required: false})
            setStarCarSize(false)
            setRequiredWeight({required: "กรุณากรอกน้ำหนักรถ"})
            setStarCarWeight(true)
        }
        if (color === undefined) {
            setDisabledColorDes(true)
            setRequiredColorDes(false)
            errors.colorDes = ''
        } else {
            if (color.label === 'อื่น ๆ') {
                setDisabledColorDes(false)
                setRequiredColorDes({required: "กรุณาระบุสี"})
                setDefaultColorDes()
            } else {
                setDisabledColorDes(true)
                setRequiredColorDes(false)
                setDefaultColorDes(color.label)
                setValue('colorDes', color.label)
                errors.colorDes = ''
            }
        }
    }

    useEffect(() => {
        if (modelCodeState === undefined) {
            getCarColor()
            getCarMake()
            carYear()
            carSeat()
            setDefault()
        } else {
            carYear()    
        }
    }, [modelCodeState])
    console.log('modelCodeState', modelCodeState);
    console.log(subModelCodeState);
    return (
        <>
            <div className="card">
                <div className="card-header">
                    <h3 className="card-title">รุ่นรถ</h3>
                </div>
                <div className="card-body">
                    <div className="row clearfix">
                        <div className="col-md-6 col-sm-12">
                            <div className='form-group'>
                                <label>ยี่ห้อ <span className="text-danger">*</span></label>
                                <Select
                                    name='makeCode'
                                    onChange={handleCarMake}
                                    options={optionsCarMake}
                                    value={optionsCarMake[indexMakeCodeState]}
                                    ref={
                                        register('makeCode', {required: "กรุณาเลือกยี่ห้อรถ"})
                                    }
                                />
                                {errors.makeCode &&
                                    <span className='text-danger'>{errors.makeCode.message}</span>
                                }
                            </div>
                        </div>
                        {broker === 'tokio' ?
                            <>
                                <div className="col-md-6 col-sm-12">
                                    <div className='form-group'>
                                        <label>รุ่น <span className="text-danger">*</span></label>
                                        <Select name='modelCode'
                                                onChange={handleCarModel}
                                                options={optionsCarModel}
                                                value={defaultModelState}
                                                isLoading={loadingModel}
                                                ref={(ref) => {
                                                    register('modelCode', {required: "กรุณาเลือกรุ่นรถ"})
                                                }}
                                        />
                                        {errors.modelCode && 
                                            <span className='text-danger'>{errors.modelCode.message}</span>
                                        }
                                    </div>
                                </div>
                                <div className="col-md-12 col-sm-12">
                                    <div className='form-group'>
                                        <label>รุ่นย่อย <span className="text-danger">*</span></label>
                                        <Select name='subModelCode'
                                                onChange={handleCarSubModel}
                                                options={optionSubModel}
                                                value={defaultSubModel}
                                                isLoading={loadingSubModel}
                                                ref={(ref) => {
                                                    register('subModelCode', {required: "กรุณาเลือกรุ่นรถ"})
                                                }}
                                        />
                                        {errors.subModelCode && 
                                            <span className='text-danger'>{errors.subModelCode.message}</span>
                                        }
                                    </div>
                                </div>
                            </>
                        :
                            <div className="col-md-6 col-sm-12">
                                <div className='form-group'>
                                    <label>รุ่น <span className="text-danger">*</span></label>
                                    <Select name='modelCode'
                                            onChange={handleCarModel}
                                            options={optionsCarModel}
                                            value={defaultModelState}
                                            isLoading={loadingModel}
                                            ref={(ref) => {
                                                register('modelCode', {required: "กรุณาเลือกรุ่นรถ"})
                                            }}
                                    />
                                    {errors.modelCode && 
                                        <span className='text-danger'>{errors.modelCode.message}</span>
                                    }
                                </div>
                            </div>
                        }
                        
                        <div className="col-md-3 col-sm-12">
                            <div className='form-group'>
                                <label>ปีรถ (ค.ศ.) <span className="text-danger">*</span></label>
                                <Select name='year'
                                        onChange={handleCarYear}
                                        options={optionCarYear}
                                        value={defaultCarYear}
                                        ref={
                                            register('year', {required: "กรุณาเลือกปีรถ"})
                                        }
                                />
                                {errors.year && 
                                    <span className='text-danger'>{errors.year.message}</span>
                                }    
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-12">
                            <div className="form-group">
                                <label>จำนวนที่นั่ง <span className="text-danger">*</span></label>
                                {/* <input type="number" className="form-control" name='seat' value={seat} {...register('seat', {required: "กรุณากรอกจำนวนที่นั่ง"})} /> */}
                                <Select name='seat'
                                        onChange={handleCarSeat}
                                        options={optionCarSeat}
                                        value={defaultCarSeat}
                                        ref={
                                            register('seat', {required: "กรุณากรอกจำนวนที่นั่ง"})
                                        }
                                />
                                {errors.seat && 
                                    <span className='text-danger'>{errors.seat.message}</span>
                                }    
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-12">
                            <div className="form-group">
                                <label>ขนาดเครื่อยนต์ (ซีซี) {startCarSize === true && <span className="text-danger">*</span>}</label>
                                {broker === 'muangthai' ?
                                <Select name='carSize'
                                        onChange={handleCarSize}
                                        options={optionCarSize}
                                        value={defaultCarSize}
                                        ref={
                                            register('carSize', {required: "กรุณาเลือกขนาดเครื่อยนต์"})
                                        }
                                />
                                :
                                <input type="number" className="form-control" name='carSize' {...register('carSize', requiredCarSize)} />
                                }
                                {errors.carSize && 
                                    <span className='text-danger'>{errors.carSize.message}</span>
                                }   
                            </div>
                            
                        </div>
                        <div className="col-md-3 col-sm-12">
                            <div className="form-group">
                                <label>น้ำหนักรถ (กิโลกรัม) {starCarWeight === true && <span className="text-danger">*</span>}</label>
                                <input type="number" className="form-control" name='weight' {...register('weight', requiredWeight)} />
                                {errors.weight && 
                                    <span className='text-danger'>{errors.weight.message}</span>
                                }
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <div className='form-group'>
                                <label>สี <span className="text-danger">*</span></label>
                                <Select name='color' 
                                    options={optionsCarColor}
                                    value={indexColorState}  
                                    onChange={handleColor} 
                                    ref={register('color', {required: "กรุณาเลือกสีรถ"})} 
                                />
                                {errors.color && 
                                    <span className='text-danger'>{errors.color.message}</span>
                                }
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <div className='form-group'>
                                <label>อื่นๆ ระบุ </label>
                                <input type="text" className="form-control" name='colorDes' value={defaultColorDes} onChange={(e) => setValue('colorDes', e.target.value) } disabled={disabledColorDes} {...register('colorDes', requiredColorDes)} />
                                {errors.colorDes && <span className='text-danger'>{errors.colorDes.message}</span> }
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        </>
    )
}

export default CarModel
