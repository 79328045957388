import React, { useEffect, useState } from 'react'
import axios from 'axios'
import moment from 'moment'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

function ListWithdraw(props) {
    const [credit, setCredit] = useState()
    const [creditTotal, setCreditTotal] = useState()
    const getCreditWithdraw = () => {
        axios.get(process.env.REACT_APP_API_URL + "/getCreditWithdraw/" + props.userId)
        .then(res => res.data)
        .then((credit) => setCredit(credit))
        .catch(err => console.log(err))
    }

    const getCreditTotal = () => {
        axios.get(process.env.REACT_APP_API_URL + "/getCreditTotal/" + props.userId)
        .then(res => res.data)
        .then((creditTotal) => setCreditTotal(creditTotal))
        .catch(err => console.log(err))
    }
    
    const toThaiDateString = (date) => {
        const dateConvert = moment(date).format("YYYY-MM-DD hh:mm")
        let monthNames = ["",
            "ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.",
            "พ.ค.", "มิ.ย.", "ก.ค.", "ส.ค..",
            "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."
        ];
    
        const yearConvertToInt = parseInt(moment(date).format("YYYY"), 10)
        const monthConvertToInt = parseInt(moment(date).format("MM"), 10)
        let year = yearConvertToInt + 543;
        let month = monthNames[monthConvertToInt];
        let numOfDay = parseInt(moment(date).format("DD"), 10)
        let time = moment(date).format("HH:mm")
    
        return <p>{numOfDay} {month} {year} <br/> เวลา {time} น.</p>;
    }
    const typeList = (channel) => {
        if (channel === 'cash') {
            return <span className="tag tag-red">ถอนเงิน</span>
        } else if(channel === 'buyInsured') {
            return <span className="tag tag-blue">ซื้อประกัน</span>
        } else {
            return <span className="tag tag-warning">{channel}</span>
        }
    }
    const createdBody = (data) => {
        return toThaiDateString(data.created_at)
    }

    const typeBody = (data) => {
        if (data.channel === 'cash') {
            return <span className="tag tag-red">ถอนเงิน</span>
        } else if(data.channel === 'buyInsured') {
            return <span className="tag tag-blue">ซื้อประกัน</span>
        } else {
            return <span className="tag tag-warning">{data.channel}</span>
        }
    }

    const amountBody = (data) => {
        return '฿' + data.amount.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits:2})
    }

    const userBody = (data) => {
        return data.userJoin[0].name
    }
    const statusBody = (data) => {
        if (data.status === 'success') {
            return <span className="tag tag-green">สำเร็จ</span>
        } else if(data.status === 'fail') {
            return <span className="tag tag-red">ไม่สำเร็จ</span>
        } else if(data.status === 'wait') {
            return <span className="tag">รอการบันทึก</span>
        }
    }
    useEffect(() => {
        getCreditWithdraw()
        getCreditTotal()
    },[])
    return (
        <>
            <div className="tab-pane active" id="viewCreadit">
                <div className="row">
                    <div className="col-md-12">
                        <div className='card'>
                            <div className='card-header'>
                                <span className="card-title">เครดิตคงเหลือ</span>
                            </div>
                            <div className='card-body text-right'>
                                <h1>{parseFloat(creditTotal).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits:2})}</h1>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <DataTable value={credit} paginator rows={10}  dataKey="id" scrollable>
                                    <Column className='font-prompt' field="created_at" header="วันที่/เวลาทำรายการ" body={createdBody}></Column>
                                    <Column className='font-prompt' field="channel" header="รายการ" body={typeBody} style={{ minWidth: '200px' }}></Column>
                                    <Column className='font-prompt' field="amount" header="จำนวนเงิน" body={amountBody}></Column>
                                    <Column className='font-prompt' field="user_id" header="ผู้ทำรายการ" body={userBody}></Column>
                                    <Column className='font-prompt' field="status" header="สถานะ" body={statusBody}></Column>
                                </DataTable>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        </>
    )
}

export default ListWithdraw
