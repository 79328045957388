import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import Swal from 'sweetalert2'

function GetCar() {
    const form = useRef()
    const form2 = useRef()
    const [broker, setBroker] = useState()
    const [makeCode, setMakeCode] = useState()
    const [modelCode, setModelCode] = useState()
    const [year, setYear] = useState()
    const getBroker = () => {
        axios.get(process.env.REACT_APP_API_URL + "/getBrokerVMI")
        .then(res => setBroker(res.data))
        .catch(err => console.log(err))
    }
    const getMakeCode = () => {
        console.log('getmakecode');
        axios.get(process.env.REACT_APP_API_URL + "/getAdminMakeCode")
        .then(res => {
            console.log('res make code => ',makeCode);
            setMakeCode(res.data)
        })
        .catch(err => console.log(err))
    }
    
    const getModelCode = () => {
        console.log('getmodelcode');
        axios.get(process.env.REACT_APP_API_URL + "/getAdminModelCode")
        .then(res => {
            console.log('res model code => ',modelCode);
            setModelCode(res.data)
        })
        .catch(err => console.log(err))
    }

    const getYearMuangthai = (id) => {
        console.log('getYearMuangthai')
        console.log(id)
        axios.get(process.env.REACT_APP_API_URL + "/getYearMuangthai/" + id)
        .then(res => {
            console.log('res year muangthai => ',res.data);
            // setYear(res.data)
        })
        .catch(err => console.log(err))
    }

    const getEnginear = (id) => {
        console.log('getEnginear');
        axios.get(process.env.REACT_APP_API_URL + "/getEnginear" + id)
        .then(res => {
            console.log('res year muangthai => ',res.data);
            setYear(res.data)
        })
        .catch(err => console.log(err))
    }

    const onSubmit = (event) => {
        event.preventDefault()
        const fd = new FormData(form.current)
        const fe = Object.fromEntries(fd.entries())
        console.log('from data => ', form);
        axios.post(process.env.REACT_APP_API_URL + '/adminGetcar', fe)
        .then(res=>{
            // form.current.reset()
            Swal.fire('สำเร็จ','บันทึกค่าสำเร็จ','success')
        }).catch(err=>{
            console.log(err)
            Swal.fire('แจ้งเตือน',err,'error')
        })
    }

    const onSubmit2 = (makeCode, broker) => {
        console.log('on submit 2');
        const data = {
            makeCode: makeCode,
            broker: broker
        }
        axios.post(process.env.REACT_APP_API_URL + '/adminGetmodel', data)
        .then(res=>{
            // form.current.reset()
            Swal.fire('สำเร็จ','บันทึกค่าสำเร็จ','success')
            .then((result) => {
                if (result.isConfirmed) {
                    getMakeCode()
                    window.location.reload()
                }
            })

        }).catch(err=>{
            console.log(err)
            Swal.fire('แจ้งเตือน',err,'error')
        })
    }

    const onSubmit3 = (makeCode, modelCode, broker) => {
        console.log('on submit 2');
        const data = {
            makeCode: makeCode,
            modelCode: modelCode,
            broker: broker
        }
        axios.post(process.env.REACT_APP_API_URL + '/adminGetYear', data)
        .then(res=>{
            // form.current.reset()
            Swal.fire('สำเร็จ','บันทึกค่าสำเร็จ','success')
            .then((result) => {
                if (result.isConfirmed) {
                    // getMakeCode()
                    getModelCode()
                }
            })

        }).catch(err=>{
            console.log(err)
            Swal.fire('แจ้งเตือน',err,'error')
        })
    }

    const onSubmit4 = (MakeCode, Family, YearGroupID) => {
        const data = {
          MakeCode: MakeCode,
          Family: Family,
          YearGroupID: YearGroupID
        }
        axios.post(process.env.REACT_APP_API_URL + "/getEnginear", data)
        .then(res => {
          Swal.fire('สำเร็จ','บันทึกค่าสำเร็จ','success')
          .then((result) => {
              if (result.isConfirmed) {
                  getYearMuangthai()
              }
          })
        }).catch(err => console.log(err))
    }

    const onSubmit5 = (makeCode, modelCode) => {
        const data = {
            makeCode: makeCode,
            modelCode: modelCode,
            broker: "tokio"
        }
        console.log(data);
        axios.post(process.env.REACT_APP_API_URL + "/getSubModel", data)
        .then(res => {
          Swal.fire('สำเร็จ','บันทึกค่าสำเร็จ','success')
          .then((result) => {
              if (result.isConfirmed) {
                getModelCode()
              }
          })
        }).catch(err => console.log(err))
    }

    useEffect(() => {
        getBroker(),
        getMakeCode(),
        getModelCode()
        // getYearMuangthai()
    },[])
    console.log(makeCode);
    return (
        <>
            {/* <div>GetCar</div>
            <form ref={form} onSubmit={onSubmit}>
                <select class="form-select" name='broker'>
                    <option>เลือก</option>
                    {broker && broker.map((b) => (
                        <option value={b.broker_short_name}>{b.broker_name}</option>
                    ))}
                </select>
                <button className='btn btn-primary'>ค้นหา</button>
            </form>
            <table className='table table-bordered'>
                <tr>
                    <td>make_code</td>
                    <td>make_name</td>
                    <td>broker</td>
                    <td>status</td>
                </tr>
                {makeCode && makeCode.map((m) => (
                    <tr>
                        <td>{m.make_code}</td>
                        <td>{m.make_name}</td>
                        <td>{m.broker}</td>
                        <td>
                            {m.status}
                            {m.status === 1 &&
                                <a onClick={e => onSubmit2(m.make_code, m.broker)} className='btn btn-info'>Get Model</a>
                            }
                        </td>
                    </tr>
                ))}
            </table> */}
            <div>GetModel</div>
            <form ref={form2} onSubmit={onSubmit2}>
                <select class="form-select" name='broker'>
                    <option>เลือก</option>
                    {broker && broker.map((b) => (
                        <option value={b.broker_short_name}>{b.broker_name}</option>
                    ))}
                </select>
                <button className='btn btn-primary'>ค้นหา</button>
            </form>
            <table className='table table-bordered'>
                <tr>
                    <td>make_code</td>
                    <td>model_code</td>
                    <td>model_name</td>
                    <td>year</td>
                    <td>car_size</td>
                    <td>broker</td>
                    <td>status</td>
                </tr>
                {modelCode && modelCode.map((m) => (
                    <tr>
                        <td>{m.make_code}</td>
                        <td>{m.model_code}</td>
                        <td>{m.model_name}</td>
                        <td>{m.year && <span>ดึงค่าปีสำเร็จ</span>}</td>
                        <td>{m.car_size}</td>
                        <td>{m.broker}</td>
                        <td>
                            <a onClick={e => onSubmit5(m.make_code, m.model_code, m.broker)} className='btn btn-info'>Update</a>
                        </td>
                    </tr>
                ))}
            </table>
            {/* <div>GetYear</div>
            <form ref={form2} onSubmit={onSubmit2}>
                <select class="form-select" name='broker'>
                    <option>เลือก</option>
                    {broker && broker.map((b) => (
                        <option value={b.broker_short_name}>{b.broker_name}</option>
                    ))}
                </select>
                <button className='btn btn-primary'>ค้นหา</button>
            </form>
            <table className='table table-bordered'>
                <tr>
                    <td>make_code</td>
                    <td>model_code</td>
                    <td>model_name</td>
                    <td>year</td>
                    <td>car_size</td>
                    <td>broker</td>
                    <td>status</td>
                </tr>
                {year && year.map((m) => (
                    <tr>
                        <td>
                            <a onClick={() => getEnginear(m._id)}>{m._id}</a>
                        </td>
                        <td></td>
                        <td>{m.model_name}</td>
                        <td>
                            {m.year.length}
                        </td>
                        <td>{m.car_size}</td>
                        <td>{m.broker}</td>
                        <td>
                            {m.car_size ? <span>Update</span>
                                :
                                <a onClick={e => onSubmit3(m.make_code, m.model_code, m.broker)} className='btn btn-info'>Update</a>
                            }
                        </td>
                    </tr>
                ))}
            </table> */}
        </>
    )
}

export default GetCar