import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import './assets/assets/plugins/bootstrap/css/bootstrap.min.css'
import './assets/assets/plugins/bootstrap-datepicker/css/bootstrap-datepicker3.min.css'
import './assets/assets/plugins/datatable/dataTables.bootstrap4.min.css'
import './assets/assets/plugins/summernote/dist/summernote.css'
import './assets/assets/css/style.css'
import './assets/assets/css/styleByDev.css'
import "primereact/resources/primereact.min.css"; 
import "primereact/resources/themes/lara-light-indigo/theme.css";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
