import React, { useEffect } from 'react'
import { useAuthUser } from 'react-auth-kit'
import DashboardAdmin from './DashboardAdmin';
import DashboardAgent from './DashboardAgent';
import DashboardSeller from './DashboardSeller';
import DashboardSubSeller from './DashboardSubSeller';
import {useIsAuthenticated} from 'react-auth-kit';
import { redirect } from 'react-router-dom';

function Dashboard() {
    
    const isAuthenticated = useIsAuthenticated()
    const auth = useAuthUser()
    const renderSwitch = (role) => {
        switch(role) {
          case "administrator":
            return <DashboardAdmin />;
          case "agent":
            return <DashboardAgent />;
          case "platinum":
            return <DashboardSeller />;
          case "gold":
            return <DashboardSubSeller />;
          case "silver":
            return <DashboardSubSeller />;
          default:
            return 'ไม่มีข้อมูล';
        }
    }
    return (
        <>
            {renderSwitch(auth().role)}
        </>
    )
    
}

export default Dashboard
