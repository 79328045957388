import React, { useRef } from 'react'
import axios from 'axios'
import Swal from 'sweetalert2'
import { useAuthUser } from 'react-auth-kit'

function FormUserGold(props) {
    const form = useRef()
    const onSubmit = (event) => {
        event.preventDefault() //ไม่ให้ Browser refresh
        const formData = new FormData(form.current)
        const formEnt = Object.fromEntries(formData.entries())
        axios.post(process.env.REACT_APP_API_URL + '/saveUser', formEnt)
        .then(response=>{
            form.current.reset()
            Swal.fire('สำเร็จ','เพิ่มผู้ใช้งานสำเร็จ','success')
        }).catch(err=>{
            console.log(err.response.data);
            Swal.fire('แจ้งเตือน',err.response.data.error,'error')
        })
    }
    
    return (
        <>
            <div className="row clearfix">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">ข้อมูลผู้ใช้งาน</h3>
                        </div>
                        <div className="card-body">
                            <form ref={form} onSubmit={onSubmit}>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>ชื่อ</label>
                                            <input type="text" name="firstname" className="form-control" required />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>นามสกุล</label>
                                            <input type="text" name="lastname" className="form-control" required />
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <label>อีเมล</label>
                                            <input name='email' type="email" className="form-control" required />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>รหัสผ่าน</label>
                                            <input name='password' type="password" className="form-control" required />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>ยืนยันรหัสผ่าน</label>
                                            <input name='confirmPassword' type="password" className="form-control" required />
                                        </div>
                                    </div>
                                </div>
                                <input type="hidden" name="user_id" value={props.authid}/>
                                <input type="hidden" name="role" value="gold"/>
                                <div className='row mt-3'>
                                    <div className="col-sm-12">
                                        <button type="submit" className="btn btn-primary mr-2">บันทึก</button>
                                        <button type="reset" className="btn btn-outline-secondary">ยกเลิก</button>
                                    </div> 
                                </div>    
                            </form>
                            
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FormUserGold
