import React from 'react'
import { useAuthUser } from 'react-auth-kit'
import { useSignOut } from 'react-auth-kit'
import logo from "../../assets/assets/images/logo-saifon-cricle.png"
import iconVMI from '../../assets/assets/images/logo-icon-vmi.png'
import iconCMI from '../../assets/assets/images/logo-icon-cmi.png'
import iconCommissionReport from '../../assets/assets/images/icon-commission-report.png'
import iconRedeemCommission from '../../assets/assets/images/icon-redeem-commission.png'
import iconCancleContract from '../../assets/assets/images/icon-contract.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTachometerAltAverage, faCarCrash } from '@fortawesome/free-solid-svg-icons'

function Nav() {
    const signOut = useSignOut()
    const auth = useAuthUser()
    const roleName = (role) => {
        if (role === 'administrator') {
            return 'ผู้ดูแลระบบ'
        } else if(role === 'agent') {
            return 'ตัวแทน (Agent)'
        } else if(role === 'platinum' | role === 'gold' | role === 'silver') {
            return (<><h6>พนักงานขาย<span className="tag ml-1">{role}</span></h6></> );
        } else {
            return 'ไม่มีข้อมูล'
        }
    }
    const menu = (role) => {
        if (role === 'administrator') {
            return (
                <>
                    <ul className="metismenu">
                        <li><a href="/dashboard"><i className="fa fa-dashboard"></i><span>Dashboard</span></a></li>
                        <li className="g_heading">รายงาน</li>
                        <li><a href="/reportcommission"><img src={iconCommissionReport} className='mr-2' width={16} /><span>สรุปคอมมิชชั่น</span></a></li>
                        <li><a href="/redeemcommission"><img src={iconRedeemCommission} className='mr-2' width={16} /><span>คืนคอมมิชชั่น</span></a></li>
                        {/* <li className="g_heading">จัดการกรมธรรม์</li>
                        <li><a href="/manageInsurance"><img src={iconCancleContract} className='mr-2' width={18} /><span>คำขอยกเลิกกรมธรรม์</span></a></li> */}
                        <li className="g_heading">เอกสาร</li>
                        <li><a href="library.html"><i className="fa fa-file-text"></i><span>เอกสารโอนโค้ด</span></a></li>
                        <li><a href="library.html"><i className="fa fa-file-text"></i><span>เอกสารโบรชัวร์</span></a></li>
                        <li><a href="library.html"><i className="fa fa-file-text"></i><span>คู่มือการใช้งาน</span></a></li>
                        <li className="g_heading">จัดการ</li>
                        <li><a href="/user"><i className="fa fa-users" aria-hidden="true"></i><span>ข้อมูลผู้ใช้งาน</span></a></li>
                        <li><a href="/administrator"><i className="fa fa-user-circle" aria-hidden="true"></i><span>ข้อมูลผู้ดูแลระบบ</span></a></li>
                        <li className="g_heading">ตั้งค่ารายละเอียด</li>
                        <li><a href="/broker"><i className="fa fa-user"></i><span>ข้อมูลบริษัทประกันภัย</span></a></li>
                    </ul>
                </>
            );            
        } else if(role === 'agent') {
            return (
                <>
                    <ul className="metismenu">
                        <li><a href="/dashboard"><i className="fa fa-dashboard"></i><span>Dashboard</span></a></li>
                        <li><a href="staff.html"><i className="fa fa-folder"></i><span>รายงานการชำระเงิน</span></a></li>
                        <li className="g_heading">จัดการกรมธรรม์</li>
                        <li><a href="/manageInsurance"><img src={iconCancleContract} className='mr-2' width={18} /><span>คำขอยกเลิกกรมธรรม์</span></a></li>
                        <li className="g_heading">รายงาน</li>
                        <li><a href="/reportcommission"><img src={iconCommissionReport} className='mr-2' width={16} /><span>สรุปคอมมิชชั่น</span></a></li>
                        <li className="g_heading">เอกสาร</li>
                        <li><a href="library.html"><i className="fa fa-file-text"></i><span>เอกสารโอนโค้ด</span></a></li>
                        <li><a href="library.html"><i className="fa fa-file-text"></i><span>เอกสารโบรชัวร์</span></a></li>
                        <li><a href="library.html"><i className="fa fa-file-text"></i><span>คู่มือการใช้งาน</span></a></li>
                        <li className="g_heading">จัดการ</li>
                        <li><a href={"/manageUser?manageRole=platinum&userId=" + auth().id}><i className="fa fa-user"></i><span>ข้อมูลผู้ใช้งาน</span></a></li>
                    </ul>
                </>
            );
        } else if(role === 'silver') {
            return (
                <>
                    <ul className="metismenu">
                        <li><a href="/dashboard"><i className="fa fa-dashboard"></i><span>Dashboard</span></a></li>
                        <li className="g_heading">ซื้อ-ขาย</li>
                        <li><a href="/offerPremium"><i className="fa fa-book"></i><span>เสนอเบี้ยประกัน</span></a></li>
                        <li><a href="/insurecmi"><img src={iconCMI} className='mr-2' width={18} /><span>สั่งซื้อ พรบ.</span></a></li>
                        <li><a href="/insurevmi"><img src={iconVMI} className='mr-2' width={18} /><span>สั่งซื้อประกัน</span></a></li>                        
                        <li><a href="/cancleinsurance"><i className="fa fa-ban"></i><span>ยกเลิกกรมธรรม์</span></a></li>
                        <li className="g_heading">รายงาน</li>
                        <li><a href="/reportcommission"><img src={iconCommissionReport} className='mr-2' width={16} /><span>สรุปคอมมิชชั่น</span></a></li>
                        <li className="g_heading">เอกสาร</li>
                        <li><a href="#"><i className="fa fa-file-text"></i><span>เอกสารโอนโค้ด</span></a></li>
                        <li><a href="#"><i className="fa fa-file-text"></i><span>เอกสารโบรชัวร์</span></a></li>
                        <li><a href="#"><i className="fa fa-file-text"></i><span>คู่มือการใช้งาน</span></a></li>
                    </ul>
                </>
            );
        } else {
            return (
                <>
                    <ul className="metismenu">
                        <li><a href="/dashboard"><i className="fa fa-dashboard"></i><span>Dashboard</span></a></li>
                        
                        <li className="g_heading">ซื้อ-ขาย</li>
                        <li><a href="/offerPremium"><i className="fa fa-book"></i><span>เสนอเบี้ยประกัน</span></a></li>
                        <li><a href="/insurecmi"><img src={iconCMI} className='mr-2' width={18} /><span>สั่งซื้อ พรบ.</span></a></li>
                        <li><a href="/insurevmi"><img src={iconVMI} className='mr-2' width={18} /><span>สั่งซื้อประกัน</span></a></li>                        
                        <li><a href="/cancleinsurance"><i className="fa fa-ban"></i><span>ยกเลิกกรมธรรม์</span></a></li>
                        <li className="g_heading">รายงาน</li>
                        <li><a href="/reportcommission"><img src={iconCommissionReport} className='mr-2' width={16} /><span>สรุปคอมมิชชั่น</span></a></li>
                        <li className="g_heading">เอกสาร</li>
                        <li><a href="#"><i className="fa fa-file-text"></i><span>เอกสารโอนโค้ด</span></a></li>
                        <li><a href="#"><i className="fa fa-file-text"></i><span>เอกสารโบรชัวร์</span></a></li>
                        <li><a href="#"><i className="fa fa-file-text"></i><span>คู่มือการใช้งาน</span></a></li>
                        {/* <li className="g_heading">จัดการ</li>
                        <li><a href="/user"><i className="fa fa-user"></i><span>ข้อมูลผู้ใช้งาน</span></a></li> */}
                    </ul>
                </>
            );
        }
    }
    return (
        <>
            <div id="header_top" className="header_top">
                <div className="container">
                    <div className="hleft">
                        {/* <a href='javascript:void(0);' className="header-brand" href="index.html"><i className="fa fa-graduation-cap brand-logo"></i></a> */}
                        <a className="header-brand" href="/dashboard"><img src={logo} style={{width: 50}} /></a>
                        <div className="dropdown">
                            <a href='javascript:void(0);' className="nav-link icon menu_toggle"><i className="fe fe-align-center"></i></a>
                            {/* <a href="page-search.html" className="nav-link icon"><i className="fe fe-search" data-toggle="tooltip" data-placement="right" title="Search..."></i></a>
                            <a href="app-email.html"  className="nav-link icon app_inbox"><i className="fe fe-inbox" data-toggle="tooltip" data-placement="right" title="Inbox"></i></a>
                            <a href="app-filemanager.html"  className="nav-link icon app_file xs-hide"><i className="fe fe-folder" data-toggle="tooltip" data-placement="right" title="File Manager"></i></a>
                            <a href="app-social.html"  className="nav-link icon xs-hide"><i className="fe fe-share-2" data-toggle="tooltip" data-placement="right" title="Social Media"></i></a>
                            <a href="javascript:void(0)" className="nav-link icon theme_btn"><i className="fe fe-feather"></i></a>
                            <a href="javascript:void(0)" className="nav-link icon settingbar"><i className="fe fe-settings"></i></a> */}
                        </div>
                    </div>
                    <div className="hright">
                        {/* <a href="" className="nav-link icon right_tab"><i className="fe fe-align-right"></i></a> */}
                        <a onClick={() => signOut()} className="nav-link icon settingbar"><i className="fe fe-power"></i></a>                
                    </div>
                </div>
            </div>
            <div id="left-sidebar" className="sidebar">
                <h5 className="brand-name">Saifon Insurance</h5>
                <ul className="nav nav-tabs">
                    <li className="nav-item"><a className="nav-link active" data-toggle="tab" href="#menu-uni">{roleName(auth().role)}</a></li>
                    {/* <li className="nav-item"><a href='javascript:void(0);' className="nav-link active" data-toggle="tab" href="#menu-admin">Admin</a></li> */}
                </ul>
                <div className="tab-content mt-3">
                    <div className="tab-pane fade show active" id="menu-uni" role="tabpanel">
                        <nav className="sidebar-nav">
                            {menu(auth().role)}
                        </nav>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Nav
