import React, { useEffect, useRef, useState } from 'react'
import Sedan from '../../../assets/assets/images/carType/sedan.png'
import Pickup from '../../../assets/assets/images/carType/pickup.png'
import Van from '../../../assets/assets/images/carType/van.png'
import Select from 'react-select'

function CarType({ formProps: { register, formState: {errors}, setValue }, data}) {
    const { broker } = data[0][0]
    const [checked, setChecked] = useState()
    const [carBodyShow, setCarBodyShow] = useState()
    const carBodyRef = useRef()
    const { carType, carBody } = data[0][1]
    const [optionCarBody, setOptionCarBody] = useState()
    let labelCarType
    labelCarType = [
        {id: 1, carType: 'sedan', value: '1.10', img: Sedan},
        {id: 2, carType: 'pickup', value: '1.40', img: Pickup},
        {id: 3, carType: 'van', value: '1.20', img: Van},
    ]

    const handleCarType = (e) => {
        if (broker === 'deves') {
            if (e.target.value === '1.10') {
                setOptionCarBody([
                    { index: 1, value: '1.10', label: '1.10 - รถยนต์นั่งไม่เกิน 7 คน' }
                ])
            } else if (e.target.value === '1.20') {
                setOptionCarBody([
                    { index: 1, value: '1.20A', label: '1.20A - รถยนต์นั่งโดยสารไม่เกิน 15 ที่นั่ง' }
                ])
            } else if (e.target.value === '1.40') {
                setOptionCarBody([
                    { index: 1, value: '1.40A', label: '1.40A - รถบรรทุก น้ำหนักรวมไม่เกิน 3 ตัน' },
                    { index: 2, value: '1.40B', label: '1.40B - รถบรรทุก น้ำหนักรวม 3 ตัน ถึง 6 ตัน' }
                ])
            }    
        } else {
            if (e.target.value === '1.10') {
                setOptionCarBody([
                    { index: 1, value: '1.10', label: '1.10 - รถยนต์นั่งไม่เกิน 7 คน' }
                ])
            } else if (e.target.value === '1.20') {
                setOptionCarBody([
                    { index: 1, value: '1.20A', label: '1.20A - รถยนต์นั่งโดยสารไม่เกิน 15 ที่นั่ง' }
                ])
            } else if (e.target.value === '1.40') {
                setOptionCarBody([
                    { index: 1, value: '1.40A', label: '1.40A - รถบรรทุก น้ำหนักรวมไม่เกิน 3 ตัน' }
                ])
            }
        }
    }

    const handleCarBody = (e) => {
        console.log(e);
        setValue("carType", e.value)
        setValue("carBody", e.label)
    }
    
    return (
        <>
            <div className="card">
                <div className="card-header">
                    <h3 className="card-title">กรุณาเลือกประเภทรถ</h3>
                </div>
                <div className="card-body">
                    <div className="form-group">
                        <div className="row gutters-sm">
                            {labelCarType.map((lct) => (
                                <div key={lct.id} className="col-sm-2">
                                    <label className="imagecheck mb-4">
                                    <input name="carType" type="radio" value={lct.value} className="imagecheck-input" onClick={handleCarType} {...register('carTypeSelect', {required: "กรุณาเลือกประเภทรถ"})} />
                                    <figure className="imagecheck-figure">
                                        <img src={lct.img} alt="}" className="imagecheck-image img-thumbnail" />
                                    </figure>
                                    </label>
                                </div>    
                            ))}
                            {errors.carTypeSelect &&
                                <>
                                    <br/>
                                    <span className='text-danger'>{errors.carType.message}</span>
                                </>
                            }        
                        </div>
                    </div>
                    <div className="col-md-12 col-sm-12">
                        <div className="form-group">
                            <label>แบบตัวถัง <span className="text-danger">*</span></label>
                            {/* <input name="carBody" type="text" value={carBody} className="form-control" {...register('carBody', {required: "กรุณาเลือกประเภทรถ"})} ref={carBodyRef} disabled={true}/> */}
                            <Select
                                name='carBody'
                                options={optionCarBody}
                                value={carBody}
                                onChange={handleCarBody}
                                ref={
                                    register('carBody', {required: "กรุณาเลือกแบบตัวถัง"})
                                }
                                // {...register('makeCode', {required: "กรุณากรอกรุ่นรถ"})}
                            />
                            {errors.carBody &&
                                <span className='text-danger'>{errors.carBody.message}</span>
                            }
                        </div>
                        
                    </div>
                </div>
            </div>
        </>
    )
}

export default CarType
