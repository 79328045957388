import axios from 'axios';
import React from 'react'
import { useParams } from 'react-router-dom';
import Footer from '../Layout/Footer';
import Header from '../Layout/Header';
import Nav from '../Layout/Nav';
import FormEditAgent from './formEdit/FormEditAgent';
import FormEditSeller from './formEdit/FormEditSeller';
import FormEditSubSeller from './formEdit/FormEditSubSeller';
import FormEditUserLevel from './formEdit/FormEditUserLevel';
import FormEditAdmin from './formEdit/FormEditAdmin';

function FormEditUser(props) {
    const params = useParams()
    const showFormEdit = () => {
        const role = params.role
        if (role === 'admin') {
            return <FormEditAdmin id={params.id} />
        } else if (role === 'agent') {
            return <FormEditAgent id={params.id} />
        } else {
            return <FormEditUserLevel id={params.id} />
        }
    }
    return (
        <>
            <Nav />
            <div className='page'>
                <Header />
                <div className="section-body">
                    <div className="container-fluid">
                        <div className="d-flex justify-content-between align-items-center ">
                            <div className="header-action">
                                <h1 className="page-title">ผู้ใช้งาน</h1>
                                <ol className="breadcrumb page-breadcrumb">
                                    <li className="breadcrumb-item"><a href="/dashboard">หน้าแรก</a></li>
                                    <li className="breadcrumb-item"><a href="/user">ผู้ใช้งาน</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">แก้ไขข้อมูลผู้ใช้งาน</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section-body mt-4">
                    <div className="container-fluid">
                        {showFormEdit()}
                    </div>
                </div>
                <Footer />     
            </div> 
        </>
    )
}

export default FormEditUser
