import React, { useEffect, useRef, useState } from 'react'
import moment from 'moment';
import axios from 'axios';

function Purchase({ formProps: { register, formState: {errors}, setValue }, data}) {
    const {broker} = data[0][0]
    const {carType} = data[1][0]
    const {makeCode, indexMakeCode, modelCode, defaultModel, year, carSize, seat, weight, color, defaultColor, colorDes} = data[2][0]
    const {registrationType, registrationNo, registrationProvince, registrationProvinceDisable, registrationYear, carCountry, carCountryDisable, chassisNumber, engineNumber} = data[3][0]
    const {dateStart, dateStop, price, stamp, vat, priceNet} = data[4][0]
    const [dateStartState, setDateStartState] = useState(dateStart)
    const [dateStopState, setDateStopState] = useState(dateStop)
    const [priceState, setPriceState] = useState(0)
    const [stampState, setStampState] = useState(0)
    const [vatState, setVatState] = useState(0)
    const [priceDefault, setPriceDefault] = useState(0)
    const [priceNetState, setPriceNetState] = useState(0)
    const [priceOverYear, setPriceOverYear] = useState(0)
    const [priceLessYear, setPriceLessYear] = useState(0)
    const [toggle, setToggle] = useState(false)
    const [brokerName, setBrokerName] = useState()

    console.log(data);
    const getBroker = () => {
        axios.get(process.env.REACT_APP_API_URL + '/getBrokerName/' + broker)
        .then(res => setBrokerName(res.data.broker_short_name))
        .catch((err) => console.log(err))
    }

    const getQuickQuotes = () => {
        console.log('quick quotes');
        axios.post(process.env.REACT_APP_API_URL + '/getQuickQuotes', [data, dateStartState, dateStopState])
        .then(res => {
            setPriceDefault(res.data.netPremium)
            setPriceNetState(res.data.grossPremium)
            setVatState(res.data.additionalCosts[0].amount)
            setStampState(res.data.additionalCosts[1].amount)
            setValue("dateStart", dateStartState)
            setValue("dateStop", dateStopState)
            setValue("price", res.data.netPremium)
            setValue("stamp", res.data.additionalCosts[1].amount)
            setValue("vat", res.data.additionalCosts[0].amount)
            setValue("priceNet", res.data.grossPremium)
        }).catch((err) => console.log(err))
        setToggle(true)
    }

    const defaultValue = () => {
        if (carType === '1.10') {
            // setPriceState({priceState: 600})
            setPriceState(600)
            setStampState(3)
            setVatState(42.21)
            setPriceDefault(600)
            setPriceNetState(645.21)
            setValue("dateStart", dateStartState)
            setValue("dateStop", dateStopState)
            setValue("price", 600)
            setValue("stamp", 3)
            setValue("vat", 42.21)
            setValue("priceNet", 645.21)
        } else if (carType === '1.20A') {
            setPriceState(1100)
            setStampState(5)
            setVatState(77.35)
            setPriceDefault(1100)
            setPriceNetState(1182.35) 
            setValue("dateStart", dateStartState)
            setValue("dateStop", dateStopState)
            setValue("price", 1100)
            setValue("stamp", 5)
            setValue("vat", 77.35)
            setValue("priceNet", 1182.35) 
        } else if (carType === '1.40A') {
            setPriceState(900)
            setStampState(4)
            setVatState(63.28)
            setPriceDefault(900)
            setPriceNetState(967.28)
            setValue("dateStart", dateStartState)
            setValue("dateStop", dateStopState)
            setValue("price", 900)
            setValue("stamp", 4)
            setValue("vat", 63.28)
            setValue("priceNet", 967.28)
        } else if (carType === '1.40B') {
            setPriceState(1220)
            setStampState(5)
            setVatState(85.75)
            setPriceDefault(1220)
            setPriceNetState(1310.75)
            setValue("dateStart", dateStartState)
            setValue("dateStop", dateStopState)
            setValue("price", 1220)
            setValue("stamp", 5)
            setValue("vat", 85.75)
            setValue("priceNet", 1310.75)
        }
        setToggle(true)
    }

    const calculate = () => {
        const currentYearStart = moment(dateStartState).startOf('years').format("YYYY")
        const currentYearStop = moment(dateStopState).startOf('years').format("YYYY")
        const isFeb29DayStart = currentYearStart % 4
        const isFeb29DayStop = currentYearStop % 4
        const x = moment(dateStartState).format("YYYYMMDD");
        const y = moment(dateStopState).format("YYYYMMDD");
        let rangDay = moment(y).diff(moment(x).startOf('day'), 'days')
        let rangDayDivide = 365
        if (isFeb29DayStart !== 0 && isFeb29DayStop !== 0 ) {
            const porata = (rangDay/rangDayDivide) * 100
            const calPrice = Math.ceil((priceDefault*porata)/100)
            setPriceState(calPrice)
            const calVat = (calPrice+stampState) * 0.07
            setVatState(calVat)
            const calPriceNet = calPrice + stampState + calVat
            setPriceNetState(calPriceNet)
            setValue("priceNet", calPriceNet)
            setValue("vat", calVat)
        } else {
            if (rangDay === 366) {
                rangDay = 365
                rangDayDivide = 365
                const porata = parseInt((rangDay/rangDayDivide) * 100)
                const calPrice = Math.ceil((priceDefault*porata)/100)
                setPriceState(calPrice)
                const calVat = (calPrice+stampState) * 0.07
                setVatState(calVat)
                const calPriceNet = calPrice + stampState + calVat
                setPriceNetState(calPriceNet) 
                setValue("priceNet", calPriceNet)
                setValue("vat", calVat)
            } else {
                rangDayDivide = 366
                const porata = (rangDay/rangDayDivide) * 100
                const calPrice = Math.ceil((priceDefault*porata)/100)
                setPriceState(calPrice)
                const calVat = (calPrice+stampState) * 0.07
                setVatState(calVat)
                const calPriceNet = calPrice + stampState + calVat
                setPriceNetState(calPriceNet)
                setValue("priceNet", calPriceNet)
                setValue("vat", calVat)
            }
        }
        setValue("dateStart", dateStartState)
        setValue("dateStop", dateStopState)
        setValue("price", priceState)
        setValue("stamp", stampState)
    }


    const handleDateStart = (e) => {
        // setDateStartState({dateStartState: e.target.value})
        setDateStartState(e.target.value)
        calculate()
        getQuickQuotes()
    }

    const handleDateStop = (e) => {
        setDateStopState(e.target.value)
        console.log(dateStopState);
        // setDateStopState(e.target.value)
        calculate()
        getQuickQuotes()
    }

    useEffect(() => {
        if (broker === 'allianz') {
            getQuickQuotes()
        } else {
            if (toggle === false) {
                console.log('toggle false');
                defaultValue()
            } else {
                console.log('toggle true');
                calculate()
            }    
        }
        // if (toggle === false) {
        //     console.log('toggle false');
        //     if (broker === 'allianz') {
        //         getQuickQuotes()
        //     } else {
        //         defaultValue()  
        //     }
        // } else {
        //     console.log('toggle true');
        //     if (broker === 'allianz') {
        //         getQuickQuotes()
        //     } else {
        //         calculate()
        //     }
        // }
        getBroker()
    }, [dateStartState, dateStopState])
    return (
        <>
            <div className="card">
                <div className="card-header">
                    <h3 className="card-title">กรมธรรม์</h3>
                </div>
                <div className="card-body">
                    <div className="row clearfix">
                        <div className="col-md-6 col-sm-12">
                            <div className="form-group">
                                <label>วันที่เริ่มกรมธรรม์ <span className="text-danger">*</span></label>
                                <input type="date" className="form-control" name="dateStart" value={dateStartState} 
                                                    onChange={(e) => {
                                                        setDateStartState(e.target.value)
                                                        setDateStopState(moment(e.target.value).startOf('day').add(1, 'years').format("YYYY-MM-DD"))
                                                    }}  />
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <div className="form-group">
                                <label>วันที่สิ้นสุดกรมธรรม์ <span className="text-danger">*</span></label>
                                <input type="date" className="form-control" name="dateStop" value={dateStopState}
                                                    onChange={(e) => {
                                                        setDateStopState(e.target.value)
                                                    }} />
                            </div>
                        </div>
                        <div className="col-md-12 col-sm-12">
                            <label>รายการเบี้ยประกันภัย (บาท)</label>
                            <div className="table-responsive push">
                                <table className="table text-nowrap">
                                    <tbody>
                                        <tr>
                                            <th className="text-center width35"></th>
                                            <th>Product</th>
                                            <th className="text-center" style={{width: "1%"}}>Qnt</th>
                                            <th className="text-right" style={{width: "1%"}}>Unit</th>
                                            <th className="text-right" style={{width: "1%"}}>Amount</th>
                                        </tr>
                                        <tr>
                                            <td className="text-center">1</td>
                                            <td>
                                                <p className="font600 mb-1">ประกันภัยคุ้มครองผู้ประสบภัยจากรถ (พรบ.)</p>
                                                <div className="text-muted">{brokerName}</div>
                                            </td>
                                            <td className="text-center">1</td>
                                            <td className="text-right">{parseFloat(priceDefault).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits:2})}</td>
                                            <td className="text-right">{parseFloat(priceDefault).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits:2})}</td>
                                        </tr>

                                        {/* {priceOverYear === 0 && priceLessYear === 0 &&
                                            <tr>
                                                <td className="text-center">2</td>
                                                <td>
                                                    <p className="font600 mb-1">เบี้ยประกันเพิ่มเติม</p>
                                                </td>
                                                <td className="text-center"></td>
                                                <td className="text-right"></td>
                                                <td className="text-right">0.00</td>
                                            </tr>
                                        } */}
                                        {/* {priceOverYear !== 0 &&
                                            <tr>
                                                <td className="text-center">2</td>
                                                <td>
                                                    <p className="font600 mb-1">เบี้ยประกันเกิน 1 ปี</p>
                                                </td>
                                                <td className="text-center"></td>
                                                <td className="text-right"></td>
                                                <td className="text-right">+  {parseFloat(priceOverYear).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits:2})}</td>
                                            </tr>
                                        }
                                        {priceLessYear !== 0 &&
                                            <tr>
                                                <td className="text-center">2</td>
                                                <td>
                                                    <p className="font600 mb-1">เบี้ยประกันไม่เต็ม 1 ปี</p>
                                                </td>
                                                <td className="text-center"></td>
                                                <td className="text-right"></td>
                                                <td className="text-right text-danger">(-  {parseFloat(priceLessYear).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits:2})})</td>
                                            </tr>
                                        } */}
                                        <tr>
                                            <td colspan="4" className="font600 text-right">เบี้ยสุทธิ</td>
                                            <td className="text-right">{parseFloat(priceState).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits:2})}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="4" className="font600 text-right">อากรแสตมป์</td>
                                            <td className="text-right">{parseFloat(stampState).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits:2})}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="4" className="font600 text-right">ภาษีมูลค่าเพิ่ม</td>
                                            <td className="text-right">{parseFloat(vatState).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits:2})}</td>
                                        </tr>
                                        <tr className="bg-primary text-light">
                                            <td colspan="4" className="font700 text-right">รวมเงิน</td>
                                            <td className="font700 text-right">{parseFloat(priceNetState).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits:2})}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Purchase
