import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import Footer from '../../Layout/Footer'
import Header from '../../Layout/Header'
import Nav from '../../Layout/Nav'

function FormEditCommission() {
    const form = useRef()
    const params = useParams()
    const [brokerName, setBrokerName] = useState()
    const [sedan, setSedan] = useState()
    const [pickup, setPickup] = useState()
    const [van, setVan] = useState()
    const [class1, setClass1] = useState()
    const [class2Plus, setClass2Plus] = useState()
    const [class2, setClass2] = useState()
    const [class3Plus, setClass3Plus] = useState()
    const [class3, setClass3] = useState()

    const [sedanOld, setSedanOld] = useState()
    const [pickupOld, setPickupOld] = useState()
    const [vanOld, setVanOld] = useState()
    const [class1Old, setClass1Old] = useState()
    const [class2PlusOld, setClass2PlusOld] = useState()
    const [class2Old, setClass2Old] = useState()
    const [class3PlusOld, setClass3PlusOld] = useState()
    const [class3Old, setClass3Old] = useState()

    const [sedanHead, setSedanHead] = useState()
    const [pickupHead, setPickupHead] = useState()
    const [vanHead, setVanHead] = useState()
    const [class1Head, setClass1Head] = useState()
    const [class2PlusHead, setClass2PlusHead] = useState()
    const [class2Head, setClass2Head] = useState()
    const [class3PlusHead, setClass3PlusHead] = useState()
    const [class3Head, setClass3Head] = useState()

    const getCommissionEdit = () => {
        axios.get(process.env.REACT_APP_API_URL + '/getCommissionEdit/' + params.userId + '/' + params.brokerId)
        .then(res => {
            setBrokerName(res.data.brokerJoin[0].broker_name)
            setSedan(res.data.cmi_sedan)
            setPickup(res.data.cmi_pickup)
            setVan(res.data.cmi_van)
            setClass1(res.data.vmi_class1)
            setClass2Plus(res.data.vmi_class2plus)
            setClass2(res.data.vmi_class2)
            setClass3Plus(res.data.vmi_class3plus)
            setClass3(res.data.vmi_class3)
            
            setSedanOld(res.data.cmi_sedan)
            setPickupOld(res.data.cmi_pickup)
            setVanOld(res.data.cmi_van)
            setClass1Old(res.data.vmi_class1)
            setClass2PlusOld(res.data.vmi_class2plus)
            setClass2Old(res.data.vmi_class2)
            setClass3PlusOld(res.data.vmi_class3plus)
            setClass3Old(res.data.vmi_class3)
        })
        .catch(err => {
            console.log(err);
        })
    }
    const getCommissionHead = () => {
        axios.get(process.env.REACT_APP_API_URL + "/getCommissionHead/" + params.userId + "/" + params.brokerId)
        .then(res => {
            setSedanHead(res.data.cmi_sedan)
            setPickupHead(res.data.cmi_pickup)
            setVanHead(res.data.cmi_van)
            setClass1Head(res.data.vmi_class1)
            setClass2PlusHead(res.data.vmi_class2plus)
            setClass2Head(res.data.vmi_class2)
            setClass3PlusHead(res.data.vmi_class3plus)
            setClass3Head(res.data.vmi_class3)
        })
        .catch(err => console.log(err))
    }
    const navigator = useNavigate()
    const onSubmit = (event) => {
        event.preventDefault() //ไม่ให้ Browser refresh
        const formData = new FormData(form.current)
        const formEnt = Object.fromEntries(formData.entries())
        
        axios.post(process.env.REACT_APP_API_URL + '/updateCommissionOfUser', formEnt)
        .then(response=>{
            form.current.reset()
            navigator('/commission/' + params.userId)
            Swal.fire('สำเร็จ',response.data.success,'success')
        }).catch(err=>{
            Swal.fire('แจ้งเตือน',err.response.data.error,'error')
        })
    }
    const handelCommission = (event) => {
        const fieldName = event.target.name
        if (fieldName === 'sedan') {
            if (event.target.value > (sedanHead - 1)) {
                Swal.fire('ข้อควรระวัง','ค่าคอมมิชชั่นรถเก๋งต้องไม่เกิน หรือไม่เท่ากับ ' + sedanHead + '%','warning')
                event.target.value = sedanOld
            }
            setSedan(event.target.value)
        } else if(fieldName === 'pickup') {
            if (event.target.value > (pickupHead - 1)) {
                Swal.fire('ข้อควรระวัง','ค่าคอมมิชชั่นรถกระบะต้องไม่เกิน หรือไม่เท่ากับ ' + pickupHead + '%','warning')
                event.target.value = pickupOld
            }
            setPickup(event.target.value)
        } else if(fieldName === 'van') {
            if (event.target.value > (vanHead - 1)) {
                Swal.fire('ข้อควรระวัง','ค่าคอมมิชชั่นรถตู้ต้องไม่เกิน หรือไม่เท่ากับ ' + vanHead + '%','warning')
                event.target.value = vanOld
            }
            setVan(event.target.value)
        } else if(fieldName === 'class1') {
            console.log(fieldName);
            if (event.target.value > (class1Head - 1)) {
                Swal.fire('ข้อควรระวัง','ค่าคอมมิชชั่นประกันชั้น 1 ต้องไม่เกิน หรือไม่เท่ากับ ' + class1Head + '%','warning')
                event.target.value = class1Old
            }
            setClass1(event.target.value)
        } else if(fieldName === 'class2plus') {
            if (event.target.value > (class2PlusHead - 1)) {
                Swal.fire('ข้อควรระวัง','ค่าคอมมิชชั่นประกันชั้น 2+ ต้องไม่เกิน หรือไม่เท่ากับ ' + class2PlusHead + '%','warning')
                event.target.value = class2PlusOld
            }
            setClass2Plus(event.target.value)
        } else if(fieldName === 'class2') {
            if (event.target.value > (class2Head - 1)) {
                Swal.fire('ข้อควรระวัง','ค่าคอมมิชชั่นประกันชั้น 2 ต้องไม่เกิน หรือไม่เท่ากับ ' + class2Head + '%','warning')
                event.target.value = class2Old
            }
            setClass2(event.target.value)
        } else if(fieldName === 'class3plus') {
            if (event.target.value > (class3PlusHead - 1)) {
                Swal.fire('ข้อควรระวัง','ค่าคอมมิชชั่นประกันชั้น 3+ ต้องไม่เกิน หรือไม่เท่ากับ ' + class3PlusHead + '%','warning')
                event.target.value = class3PlusOld
            }
            setClass3Plus(event.target.value)
        } else if(fieldName === 'class3') {
            if (event.target.value > (class3Head - 1)) {
                Swal.fire('ข้อควรระวัง','ค่าคอมมิชชั่นประกันชั้น 3ต้องไม่เกิน หรือไม่เท่ากับ ' + class3Head,'warning')
                event.target.value = class3Old
            }
            setClass3(event.target.value)
        } else {
            console.log('ไม่พบ Field');
        }
    }
    useEffect(() => {
        getCommissionEdit()
        getCommissionHead()
    }, [])
    return (
        <>
            <Nav />
            <div className='page'>
                <Header />
                <div className="section-body">
                    <div className="container-fluid">
                        <div className="d-flex justify-content-between align-items-center ">
                            <div className="header-action">
                                <h1 className="page-title">แก้ไขค่าคอมมิชชั่นของ {}</h1>
                                <ol className="breadcrumb page-breadcrumb">
                                    <li className="breadcrumb-item"><a href="/dashboard">หน้าแรก</a></li>
                                    <li className="breadcrumb-item"><a href="/user">ผู้ใช้งาน</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">จัดการ Commission</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section-body mt-4">
                    <div className="container-fluid">
                        <div className="row clearfix">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title">แก้ไข Commission</h3>
                                    </div>
                                    <form ref={form} onSubmit={onSubmit}>
                                        <div className="card-body">
                                            <input type="hidden" name='user_id' value={params.userId}/>
                                            <input type="hidden" name='broker_id' value={params.brokerId}/>
                                            <div className='row'>
                                                <div className='col-sm-12'>
                                                    <div className="form-group">
                                                        <label className="col-form-label">บริษัทประกันภัย</label>
                                                        <p>{brokerName}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className="col-sm-12">
                                                    <div className="form-group">
                                                        <h3 className="card-title">ค่า Commission ประกันภาคบังคับ (พรบ.)</h3>
                                                    </div>
                                                </div>
                                                <div className="col-2">
                                                    <div className="form-group">
                                                        <label>รถเก๋ง</label> 
                                                        <input type="number" name="sedan" className="form-control" placehHeader='0.00%' value={sedan} onChange={handelCommission} required />
                                                    </div>
                                                </div>
                                                <div className="col-2">
                                                    <div className="form-group">
                                                        <label>รถกระบะ</label>
                                                        <input type="number" name="pickup" className="form-control" placehHeader='0.00%' value={pickup} onChange={handelCommission} required />
                                                    </div>
                                                </div>
                                                <div className="col-2">
                                                    <div className="form-group">
                                                        <label>รถตู้</label>
                                                        <input type="number" name="van" className="form-control" placehHeader='0.00%' value={van} onChange={handelCommission} required />
                                                    </div>
                                                </div>        
                                            </div>
                                            <div className='row'>
                                                <div className="col-sm-12">
                                                    <div className="form-group">
                                                        <h3 className="card-title">ค่า Commission ประกันภาคสมัครใจ (ประเภท)</h3>
                                                    </div>
                                                </div>
                                                <div className="col-2">
                                                    <div className="form-group">
                                                        <label>ประกันชั้น 1</label>
                                                        <input type="number" name="class1" className="form-control" placehHeader='0.00%' value={class1} onChange={handelCommission} required />
                                                    </div>
                                                </div>
                                                <div className="col-2">
                                                    <div className="form-group">
                                                        <label>ประกันชั้น 2+</label>
                                                        <input type="number" name="class2plus" className="form-control" placehHeader='0.00%'  value={class2Plus} onChange={handelCommission} required />
                                                    </div>
                                                </div>
                                                <div className="col-2">
                                                    <div className="form-group">
                                                        <label>ประกันชั้น 2</label>
                                                        <input type="texnumbert" name="class2" className="form-control" placehHeader='0.00%' value={class2} onChange={handelCommission} required />
                                                    </div>
                                                </div>
                                                <div className="col-2">
                                                    <div className="form-group">
                                                        <label>ประกันชั้น 3+</label>
                                                        <input type="number" name="class3plus" className="form-control" placehHeader='0.00%' value={class3Plus} onChange={handelCommission} required />
                                                    </div>
                                                </div>
                                                <div className="col-2">
                                                    <div className="form-group">
                                                        <label>ประกันชั้น 3</label>
                                                        <input type="number" name="class3" className="form-control" placehHeader='0.00%' value={class3} onChange={handelCommission} required />
                                                    </div>
                                                </div>        
                                            </div>
                                            <div className='row'>
                                                <div className="col-sm-12">
                                                    <button type="submit" className="btn btn-primary mr-2">บันทึก</button>
                                                    <button type="reset" className="btn btn-outline-secondary">ยกเลิก</button>
                                                </div> 
                                            </div>
                                        </div>    
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />     
            </div> 
        </>
    )
}

export default FormEditCommission
