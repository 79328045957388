import React from 'react'
import { useAuthUser } from 'react-auth-kit'
import FormDepositHead from './FormDepositHead'
import FormDepositSelf from './FormDepositSelf'
import ListDeposit from './ListDeposit'

function Deposit(props) {
    const auth = useAuthUser()
    return (
        <>
            <div className="tab-pane" id="deposit">
                <div className="row">
                    <div className="col-xl-4 col-md-12">
                        {auth().id === props.userId
                        ? <FormDepositSelf userId={props.userId} />
                        : <FormDepositHead userId={props.userId} />}
                    </div>
                    <div className="col-xl-8 col-md-12">
                        <ListDeposit userId={props.userId} creditTotal={props.creditTotal} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Deposit
