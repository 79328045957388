import React from 'react'
import Nav from '../Layout/Nav'
import Header from '../Layout/Header'
import Footer from '../Layout/Footer'
import FromDetailCar from './FromDetailCar'

function OfferPremium() {
  return (
    <>
        <Nav />
        <div className='page'>
            <Header />
            <div className="section-body">
                <div className="container-fluid">
                    <div className="d-flex justify-content-between align-items-center ">
                        <div className="header-action">
                            <h1 className="page-title">เสนอเบี้ย</h1>
                            <ol className="breadcrumb page-breadcrumb">
                                <li className="breadcrumb-item"><a href="/dashboard">หน้าแรก</a></li>
                                <li className="breadcrumb-item active" aria-current="page">เสนอเบี้ย</li>
                            </ol>
                        </div>
                        {/* <ul className="nav nav-tabs page-header-tab">
                            <li className="nav-item"><a className="nav-link active" data-toggle="tab" href="#listInsure">รายการกรมธรรม์</a></li>
                            <li className="nav-item"><a className="nav-link" data-toggle="tab" href="#listCancleInsure">กรมธรรม์ยกเลิก</a></li>
                        </ul> */}
                    </div>
                </div>
            </div>
            <div className="section-body mt-4">
                <div className="container-fluid">
                    <div className="tab-content">
                        <FromDetailCar />
                    </div>
                </div>
            </div>
            <Footer />     
        </div>
    </>
  )
}

export default OfferPremium