import React from 'react'
import Nav from '../Layout/Nav'
import Header from '../Layout/Header'
import Footer from '../Layout/Footer'
import ListCMI from './ListCMI'
import FormCMI from './FormCMI'
import { ContextCMIProvider } from '../Context/ContextCMI';
import {useAuthUser} from 'react-auth-kit'

function InsureCMI() {
    const auth = useAuthUser()
    return (
        <>
            <Nav />
            <div className='page'>
                <Header />
                <div className="section-body">
                    <div className="container-fluid">
                        <div className="d-flex justify-content-between align-items-center ">
                            <div className="header-action">
                                <h1 className="page-title">ซื้อ พรบ.</h1>
                                <ol className="breadcrumb page-breadcrumb">
                                    <li className="breadcrumb-item"><a href="/dashboard">หน้าแรก</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">ซื้อ พรบ.</li>
                                </ol>
                            </div>
                            <ul className="nav nav-tabs page-header-tab">
                                <li className="nav-item"><a className="nav-link active" data-toggle="tab" href="#listCMI">แสดงรายการ พรบ.</a></li>
                                <li className="nav-item"><a className="nav-link" data-toggle="tab" href="#formCMI">ซื้อ พรบ.</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="section-body mt-4">
                    <div className="container-fluid">
                        <div className="tab-content">
                            <ListCMI userAuth={auth().id} />
                            <ContextCMIProvider>
                                <FormCMI />
                            </ContextCMIProvider>
                        </div>
                    </div>
                </div>
                <Footer />     
            </div>
        </>
    )
}

export default InsureCMI
