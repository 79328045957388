import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import Select from 'react-select'
import moment from 'moment'

function Insured({ formProps: { register, formState: {errors}, setValue }, data}) {
    const {broker} = data[0][0]
    const { insuredType, insuredTypeName,
            insuredPrefix, indexInsuredPrefix,
            insuredName, 
            insuredLastname,
            insuredUniqueID,
            insuredIdType, indexInsuredIdType,
            nationality, indexNationality,
            occupation, indexOccupation,
            address,
            moo,
            soi,
            road,
            subdistrict, indexSubdistrict,
            district, indexDistrict,
            province, indexProvince,
            zipcode, indexZipcode,
            mobilePhoneNumber,
            phoneNumber,
            officePhoneNumber,
            gender, indexGender,
            emailAddr,
            birthDay,
            driverPrefix,
            driverName,
            driverLastname,
            branchNo
        } = data[1][0]
    const [starLastname, setStarLastname] = useState()
    const [starInsuredIdType, setStarInsuredIdType] = useState()
    const [startNationality, setStarNationality] = useState()
    const [defaultEmail, setDefaultEmail] = useState(emailAddr)
    const [defaultBirthDay, setDefaultBirthDay] = useState(birthDay)
    const [optionGender, setOptionGender] = useState()
    const [requiredDriverPrefix, setRequiredDriverPrefix] = useState()
    const [requiredDriverName, setRequiredDriverName] = useState()
    const [requiredDriverLastname, setRequiredDriverLastname] = useState()
    const [disabledDriverPrefix, setDisabledDriverPrefix] = useState(true)
    const [disabledDriverName, setDisabledDriverName] = useState(true)
    const [disabledDriverLastname, setDisabledDriverLastname] = useState(true)
    const [starDriver, setStarDriver] = useState()
    const [loadingDistrict, setLoadingDistrict] = useState(false)
    const [loadingSubDistrict, setLoadingSubDistrict] = useState(false)
    const [loadingZipcode, setLoadingZipcode] = useState(false)
    const getGender = () => {
        if (broker === 'allianz') {
            setOptionGender([
                {value: "FEMALE", label: "หญิง"},
                {value: "MALE", label: "ชาย"}
            ])        
        } else {
            setOptionGender([
                {value: "F", label: "หญิง"},
                {value: "M", label: "ชาย"},
                {value: "U", label: "ไม่ระบุ"}
            ])        
        }
    }
    //คำนำหน้าชื่อ
    const [optionPrefixName, setOptionPrefixName] = useState()
    const getPrefixName = () => {
        axios.get(process.env.REACT_APP_API_URL + '/getPrefixName/' + broker)
        .then(res => res.data)
        .then((prefixName) => {
            setOptionPrefixName([])
            prefixName.map((pn, index) => (
                setOptionPrefixName((prevState) => [...prevState, {index: index, value: pn.prefix_code, label: pn.prefix}])
            ))
        })
        .catch((err) => console.log(err))
    }

    const getPrefixComp = () => {
        axios.get(process.env.REACT_APP_API_URL + '/getPrefixComp/' + broker)
        .then(res => res.data)
        .then((prefixComp) => {
            setOptionPrefixName([])
            prefixComp.map((pn, index) => (
                setOptionPrefixName((prevState) => [...prevState, {index: index, value: pn.prefixComp_code, label: pn.prefixComp}])  
            ))
        })
        .catch((err) => console.log(err))
    }

    //นามสกุล
    const [isDisableLastname, setIsDisableLastname] = useState()
    const [requiredLastname, setRequiredLastname] = useState()
    //ประเภทผู้เอาประกัน
    
    const [radioInsuredType, setRedioInsuredType] = useState()
    const getInsuredType = () => {
        if (broker === 'allianz') {
            setRedioInsuredType([
                {index: 1, value: 'PERSON', label: 'บุคคลธรรมดา'},
                {index: 2, value: 'COMPANY', label: 'นิติบุคคล'},
            ])        
        } else {
            setRedioInsuredType([
                {index: 1, value: 1, label: 'บุคคลธรรมดา'},
                {index: 2, value: 2, label: 'นิติบุคคล'},
            ])        
        }
    }
    const [optionInsuredIdType, setOptionInsuredIdType] = useState(indexInsuredIdType)
    const [requiredInsuredIdType, setRequiredInsuredIdType] = useState()
    const [isDisableInsuredIdType, setIsDisableInsruedIdType] = useState()
    //สัญชาติ
    const [optionNationality, setOptionNationality] = useState()
    const [requiredNationality, setRequiredNationality] = useState()
    const [isDisableNationality, setIsDisableNationality] = useState()
    const getNationality = () => {
        axios.get(process.env.REACT_APP_API_URL + '/getNationality/' + broker)
        .then(res => res.data)
        .then((nationality) => {
            console.log('nationality',nationality)
            setOptionNationality([])
            nationality.map((n, index) => (
                setOptionNationality((prevState) => [...prevState, {index: index, value: n.code, label: n.des}])  
            ))
            if (broker === 'deves') {
                setDefaultNationality({index: 202, value: '764', label: 'Thai'})
                setValue('nationality', {index: 202, value: '764', label: 'Thai'})
            }
        })
        .catch((err) => console.log(err))
    }
    
    //จังหวัด
    const [optionProvince, setOptionProvince] = useState()
    const getProvince = () => {
        axios.get(process.env.REACT_APP_API_URL + '/getProvince/' + broker)
        .then(res => res.data)
        .then((province) => {
            setOptionProvince([])
            if (broker === 'deves') {
                setOptionProvince(province)
            } else {
                province.map((prov, index) => (
                    setOptionProvince((prevState) => [...prevState, {index: index, value: prov.province_code, label: prov.province_name}])  
                ))    
            }
        })
        .catch((err) => console.log(err))
    }

    //อำเภอ
    const [optionDistrict, setOptionDistrict] = useState()
    const getDistrict = (code) => {
        setLoadingDistrict(true)
        axios.get(process.env.REACT_APP_API_URL + '/getDistrict/' + broker + '/' + code)
        .then(res => res.data)
        .then((district) => {
            setOptionDistrict([])
            if (broker === 'deves') {
                setOptionDistrict(district)
            } else {
                district.map((dis, index) => (
                    setOptionDistrict((prevState) => [...prevState, {index: index, value: dis.district_code, label: dis.district_name}])  
                ))    
            }
            setLoadingDistrict(false)
        })
        .catch((err) => console.log(err))
    }

    //ตำบล
    const [optionSubDistrict, setOptionSubDistrict] = useState()
    const getSubDistrict = (code) => {
        setLoadingSubDistrict(true)
        axios.get(process.env.REACT_APP_API_URL + '/getSubDistrict/' + broker + '/' + code)
        .then(res => res.data)
        .then((subdistrict) => {
            setOptionSubDistrict([])
            if (broker === 'deves') {
                setOptionSubDistrict(subdistrict)
            } else {
                subdistrict.map((dis, index) => (
                    setOptionSubDistrict((prevState) => [...prevState, {index: index, value: dis.subdistrict_code, label: dis.subdistrict_name}])  
                ))    
            }
            setLoadingSubDistrict(false)
        })
        .catch((err) => console.log(err))
    }

    //รหัสไปรษณีย์
    const [optionZipcode, setOptionZipcode] = useState()
    const getZipcode = (code) => {
        setLoadingZipcode(true)
        axios.get(process.env.REACT_APP_API_URL + '/getZipcode/' + broker + '/' + code)
        .then(res => res.data)
        .then((zipcode) => {
            setOptionZipcode([])
            zipcode.map((zip, index) => (
                setOptionZipcode((prevState) => [...prevState, {index: index, value: zip.zipcode, label: zip.zipcode}])  
            ))
            if (zipcode.length === 1) {
                zipcode.map((zip, index) => {
                    handleZipcode({index: index, value: zip.zipcode, label: zip.zipcode})
                })    
            }
            setLoadingZipcode(false)
        })
        .catch((err) => console.log(err))
    }

    //อาชีพ
    const [optionOccupation, setOptionOccupation] = useState()
    const getOccupation = (insuredType) => {
        axios.get(process.env.REACT_APP_API_URL + '/getOccupation/' + broker + '/' + insuredType)
        .then(res => res.data)
        .then((occupation) => {
            setOptionOccupation([])
            occupation.map((occ, index) => {
                if (occ.occupation_code === "230") {
                    setOptionOccupation((prevState) => [...prevState, {index: index, value: occ.occupation_code, label: occ.occupation_desct}])
                    handleOccupation({index: index, value: occ.occupation_code, label: occ.occupation_desct})
                } else {
                    setOptionOccupation((prevState) => [...prevState, {index: index, value: occ.occupation_code, label: occ.occupation_desct}])
                }
                
            })
        })
        .catch((err) => console.log(err))
    }

    const [defaultLastname, setDefaultLastname] = useState(insuredLastname)
    const handleInsuredType = (e) => {
        setValue('insuredTypeName', e.target.value)
        if (e.target.value === '1' | e.target.value === 'PERSON') {
            getPrefixName()
            setIsDisableLastname(false)
            setRequiredLastname({required: "กรุณากรอกนามสกุลผู้เอาประกัน"})
            setStarLastname(true)
            setRequiredInsuredIdType({required: "กรุณาเลือกประเภทเลขประจำตัวผู้เอาประกัน"})
            setStarInsuredIdType(true)
            setIsDisableInsruedIdType(false)
            if (broker === 'deves') {
                setRequiredNationality({required: "กรุณาเลือกสัญชาติผู้เอาประกัน"})
                setIsDisableNationality(false)
                setStarNationality(true)
                handleInsuredIdType({index: 0, value: 1, label: 'บัตรประจำตัวประชาชน (ID Card)'})
            } else if(broker === 'allianz') {
                setRequiredNationality({required: "กรุณาเลือกสัญชาติผู้เอาประกัน"})
                setIsDisableNationality(false)
                setStarNationality(true)
                setDefaultNationality({index: 117, value: 'TH', label: 'Thai'})
                setValue('nationality', {index: 117, value: 'TH', label: 'Thai'})
                handleInsuredIdType({index: 0, value: 'NRIC', label: 'บัตรประจำตัวประชาชน (ID Card)'})
            } else {
                handleInsuredIdType({index: 0, value: 1, label: 'บัตรประจำตัวประชาชน (ID Card)'})
            }
            setDefaultPrefix('')
            setValue("insuredPrefix", undefined)

            setRequiredDriverPrefix(false)
            setRequiredDriverName(false)
            setRequiredDriverLastname(false)
            setStarDriver()
            setDisabledDriverPrefix(true)
            setDisabledDriverName(true)
            setDisabledDriverLastname(true)
            errors.driverPrefix = ''
            errors.driverName = ''
            errors.driverLastname = ''
        } else {
            getPrefixComp()

            setDefaultLastname()
            setValue('insuredLastname', undefined)
            setStarLastname(false)
            setIsDisableLastname(true)
            setRequiredLastname({required: false})

            setDefaultInsuredIdType()
            setValue('insuredIdType', undefined)
            setValue('indexInsuredIdType', undefined)

            setIsDisableInsruedIdType(true)
            setRequiredInsuredIdType({required: false})
            setStarInsuredIdType(false)

            setRequiredNationality({required: false})
            setIsDisableNationality(true)

            setDefaultOccupation('')
            setValue("occupation", undefined)

            setDefaultPrefix('')
            setValue("insuredPrefix", undefined)

            setDefaultGender({value: "U", label: "ไม่ระบุ"})
            setValue("gender", {value: "U", label: "ไม่ระบุ"})
            if (broker === 'allianz') {
                setDefaultOccupation({index: 100, value: "99", label: "อื่น ๆ"})
                // setRequiredDriverPrefix({required: "กรุณาระบุผู้ขับขี่"})
                // setRequiredDriverName({required: "กรุณาระบุผู้ขับขี่"})
                // setRequiredDriverLastname({required: "กรุณาระบุผู้ขับขี่"})
                // setStarDriver(true)
                // setDisabledDriverPrefix(false)      
                // setDisabledDriverName(false)      
                // setDisabledDriverLastname(false)
                setRequiredDriverPrefix(false)
                setRequiredDriverName(false)
                setRequiredDriverLastname(false)
                setStarDriver()
                setDisabledDriverPrefix(true)
                setDisabledDriverName(true)
                setDisabledDriverLastname(true) 
            } else {
                setRequiredDriverPrefix(false)
                setRequiredDriverName(false)
                setRequiredDriverLastname(false)
                setStarDriver()
                setDisabledDriverPrefix(false)
                setDisabledDriverName(false)
                setDisabledDriverLastname(false)
            }
            
            errors.insuredLastname = ''
            
            
        }
        getOccupation(e.target.value)
    }

    const [defaultInsuredIdType, setDefaultInsuredIdType] = useState(indexInsuredIdType)
    const handleInsuredIdType = (e) => {
        setValue('insuredIdType', e)
        setValue('indexInsuredIdType', e)
        setDefaultInsuredIdType(e)
    }

    const getInsuredIdType = () => {
        if (broker === 'thaisri' | broker === 'tokio') {
            setOptionInsuredIdType([
                {index: 0, value: 1, label: 'บัตรประจำตัวประชาชน (ID Card)'},
                {index: 1, value: 2, label: 'หนังสือเดินทาง (Passport)'},
                {index: 2, value: 3, label: 'บัตรประจำตัวคนซึ่งไม่มีสัญชาติไทย (Non Thai ID Card)'},
                {index: 3, value: 4, label: 'บัตรข้าราชการ (Government ID)'},
                {index: 4, value: 5, label: 'บัตรที่รัฐออกให้/ทะเบียนนิติบุคคล (State Government ID)'},
            ])
            setRequiredNationality({required: false})
            setIsDisableNationality(true)
            setValue('nationality', {index: 0, value: 764, label: 'ไทย'})
        } else if(broker === 'allianz') {
            setOptionInsuredIdType([
                {index: 0, value: 'NRIC', label: 'บัตรประจำตัวประชาชน (ID Card)'},
                {index: 1, value: 'PASS', label: 'หนังสือเดินทาง (Passport)'},
            ])
            getNationality()
        } else if(broker === 'deves') {
            setOptionInsuredIdType([
                {index: 0, value: 1, label: 'บัตรประจำตัวประชาชน (ID Card)'},
                {index: 1, value: 3, label: 'หนังสือเดินทาง (Passport)'},
            ])
            getNationality()
        } else {
            setOptionInsuredIdType()
            // setRequiredNationality({required: false})
            // setIsDisableNationality(true)
            setValue('nationality', {index: 0, value: 764, label: 'ไทย'})
        }
        errors.insuredIdType = ''
    }

    const [defaultPrefix, setDefaultPrefix] = useState(indexInsuredPrefix)
    const handleInsuredPrefix = (e) => {
        setValue('insuredPrefix', e)
        setValue('indexInsuredPrefix', e)
        setDefaultPrefix(e)
        errors.insuredPrefix = ''
    }

    const [defaultOccupation, setDefaultOccupation] = useState(indexOccupation)
    const handleOccupation = (e) => {
        setValue('occupation', e)
        setValue('indexOccupation', e)
        setDefaultOccupation(e)
        errors.occupation = ''
    }

    const [defaultNationality, setDefaultNationality] = useState(indexNationality)
    const handleNationality = (e) => {
        setValue('nationality', e)
        setValue('indexNationality', e)
        setDefaultNationality(e)
        errors.nationality = ''
    }

    const [defaultGender, setDefaultGender] = useState(indexGender)
    const handleGender = (e) => {
        setValue('gender', e)
        setValue('indexGender', e)
        setDefaultGender(e)
        errors.gender = ''
    }
    
    const [defaultProvince, setDefaultProvince] = useState(indexProvince)
    const handleProvince = (e) => {
        console.log(e);
        getDistrict(e.value)
        setValue('province', e)
        setValue('indexProvince', e)
        setDefaultProvince(e)
        errors.province = ''
    }

    const [defaultDistrict, setDefaultDistrict] = useState(indexDistrict)
    const handleDistrict = (e) => {
        getSubDistrict(e.value)
        setValue('district', e)
        setValue('indexDistrict', e)
        setDefaultDistrict(e)
        errors.district = ''
    }

    const [defaultSubdistrict, setDefaultSubdistrict] = useState(indexSubdistrict)
    const handleSubDistrict = (e) => {
        getZipcode(e.value)
        setValue('subdistrict', e)
        setValue('indexSubdistrict', e)
        setDefaultSubdistrict(e)
        errors.subdistrict = ''
    }

    const [defaultZipcode, setDefaultZipcode] = useState(indexZipcode)
    const handleZipcode = (e) => {
        setValue('zipcode', e)
        setValue('indexZipcode', e)
        setDefaultZipcode(e)
        errors.zipcode = ''
    }

    const handleEmail = (e) => {
        setDefaultEmail(e.target.value)
        setValue("emailAddr", e.target.value)
    }

    const handleBirthDay = (e) => {
        setDefaultBirthDay(e.target.value)
        setValue("birthDay", e.target.value)
    }

    useEffect(() => {
        getInsuredIdType()
        getProvince()
        // getOccupation()
        getGender()
        getInsuredType()
        setValue("emailAddr", 'auausana@hotmail.com')
        setValue("birthDay", '1999-01-01')
    }, [])
    return (
        <>
            <div className="card">
                <div className="card-header">
                    <h3 className="card-title">ข้อมูลผู้เอาประกัน</h3>
                </div>
                <div className="card-body">
                    <div className="row clearfix">
                        <div className="col-md-12 col-sm-12">
                            <div className="form-group">
                                <div className="custom-controls-stacked">
                                    {radioInsuredType && radioInsuredType.map((t) => (
                                        <label className="custom-control custom-radio custom-control-inline">
                                            <input type="radio" className="custom-control-input" name="insuredType" value={t.value} onClick={handleInsuredType} {...register('insuredType', {required: "กรุณาเลือกประเภทผู้เอาประกัน"})} />
                                            <span className="custom-control-label">{t.label}</span>
                                        </label>
                                    ))}
                                </div>
                                {errors.insuredType && 
                                    <span className='text-danger'>{errors.insuredType.message}</span>
                                }
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <div className="form-group">
                                <label>เลขประจำตัวประชาชน/เลขประจำตัวผู้เสียภาษี <span className="text-danger">*</span></label>
                                <input type="text" className="form-control" name='insuredUniqueID' {...register('insuredUniqueID', {required: "กรุณากรอกเลขประจำตัวประชาชน/เลขประจำตัวผู้เสียภาษี"})} />
                                {errors.insuredUniqueID && 
                                    <span className='text-danger'>{errors.insuredUniqueID.message}</span>
                                }
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <div className="form-group">
                                <label>ประเภทเลขประจำตัวผู้เอาประกัน {starInsuredIdType === true && <span className="text-danger">*</span>} </label>
                                <Select name='insuredIdType'
                                        onChange={handleInsuredIdType}
                                        options={optionInsuredIdType}
                                        value={defaultInsuredIdType}
                                        isDisabled={isDisableInsuredIdType}
                                        ref={
                                            register('insuredIdType', requiredInsuredIdType)
                                        }
                                />
                                {errors.insuredIdType && 
                                    <span className='text-danger'>{errors.insuredIdType.message}</span>
                                }
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className='form-group'>
                                <label>คำนำหน้า <span className="text-danger">*</span></label>
                                <Select name='insuredPrefix'
                                        onChange={handleInsuredPrefix}
                                        options={optionPrefixName}
                                        value={defaultPrefix}
                                        ref={
                                            register('insuredPrefix', {required: "กรุณาเลือกคำนำหน้า"})
                                        }
                                />
                                {errors.insuredPrefix &&
                                    <span className='text-danger'>{errors.insuredPrefix.message}</span>
                                }
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                                <label>ชื่อ <span className="text-danger">*</span></label>
                                <input type="text" className="form-control" name='insuredName' {...register('insuredName', {required: 'กรุณากรอกชื่อผู้เอาประกัน'})} />
                                {errors.insuredName && 
                                    <span className='text-danger'>{errors.insuredName.message}</span>
                                } 
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                                <label>นามสกุล {starLastname === true && <span className="text-danger">*</span>} </label>
                                <input type="text" className="form-control" name='insuredLastname' disabled={isDisableLastname} {...register('insuredLastname', requiredLastname)} />
                                {errors.insuredLastname && 
                                    <span className='text-danger'>{errors.insuredLastname.message}</span>
                                }
                            </div>
                        </div>
                        {broker === 'deves' && isDisableLastname === true &&
                            <div className="col-md-12 col-sm-12">
                                <div className="form-group">
                                    <label>สาขา</label>
                                    <input type="text" className="form-control" name='branchNo' {...register('branchNo')} />
                                </div>
                            </div>
                        }
                        <div className="col-md-4 col-sm-12">
                            <div className='form-group'>
                                <label>อาชีพ <span className="text-danger">*</span></label>
                                <Select name='occupation'
                                        onChange={handleOccupation}
                                        options={optionOccupation}
                                        value={defaultOccupation}
                                        ref={
                                            register('occupation', {required: "กรุณาเลือกอาชีพ"})
                                        }
                                />
                                {errors.occupation && 
                                    <span className='text-danger'>{errors.occupation.message}</span>
                                }
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className='form-group'>
                                <label>เพศ <span className="text-danger">*</span></label>
                                <Select name='gender'
                                        onChange={handleGender}
                                        options={optionGender}
                                        value={defaultGender}
                                        ref={
                                            register('gender', {required: "กรุณาเลือกเพศ"})
                                        }
                                />
                                {errors.gender && 
                                    <span className='text-danger'>{errors.gender.message}</span>
                                }
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className='form-group'>
                                <label>สัญชาติ {startNationality === true && <span className="text-danger">*</span>}</label>
                                <Select name='nationality'
                                        onChange={handleNationality}
                                        options={optionNationality}
                                        isDisabled={isDisableNationality}
                                        value={defaultNationality}
                                        ref={
                                            register('nationality', requiredNationality)
                                        }
                                />
                                {errors.nationality && 
                                    <span className='text-danger'>{errors.nationality.message}</span>
                                }
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-12">
                            <div className="form-group">
                                <label>ที่อยู่ บ้านเลขที่ <span className="text-danger">*</span></label>
                                <input type="text" className="form-control" name='address' {...register('address', {required: 'กรุณากรอกบ้านเลขที่'})} />
                                {errors.address && 
                                    <span className='text-danger'>{errors.address.message}</span>
                                }
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-12">
                            <div className="form-group">
                                <label>หมู่ </label> <small className='text-danger'>หากมีข้อมูลโปรดระบุ</small>
                                <input type="text" className="form-control" name='moo' {...register('moo')} />
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-12">
                            <div className="form-group">
                                <label>ซอย</label>
                                <input type="text" className="form-control" name='soi' {...register('soi')} />
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-12">
                            <div className="form-group">
                                <label>ถนน</label>
                                <input type="text" className="form-control" name='road' {...register('road')} />
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-12">
                            <div className="form-group">
                                <label>จังหวัด <span className="text-danger">*</span></label>
                                <Select name='province'
                                        onChange={handleProvince}
                                        options={optionProvince}
                                        value={defaultProvince}
                                        ref={
                                            register('province', {required: "กรุณาเลือกจังหวัด"})
                                        }
                                />
                                {errors.province && 
                                    <span className='text-danger'>{errors.province.message}</span>
                                }
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-12">
                            <div className="form-group">
                                <label>เขต/อำเภอ <span className="text-danger">*</span></label>
                                <Select name='district'
                                        onChange={handleDistrict}
                                        options={optionDistrict}
                                        value={defaultDistrict}
                                        isLoading={loadingDistrict}
                                        ref={
                                            register('district', {required: "กรุณาเลือกอำเภอ"})
                                        }
                                />
                                {errors.district && 
                                    <span className='text-danger'>{errors.district.message}</span>
                                }
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-12">
                            <div className="form-group">
                                <label>แขวง/ตำบล <span className="text-danger">*</span></label>
                                <Select name='subdistrict'
                                        onChange={handleSubDistrict}
                                        options={optionSubDistrict}
                                        value={defaultSubdistrict}
                                        isLoading={loadingSubDistrict}
                                        ref={
                                            register('subdistrict', {required: "กรุณาเลือกตำบล"})
                                        }
                                />
                                {errors.subdistrict && 
                                    <span className='text-danger'>{errors.subdistrict.message}</span>
                                }
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-12">
                            <div className="form-group">
                                <label>รหัสไปรษณีย์ <span className="text-danger">*</span></label>
                                <Select name='zipcode'
                                        onChange={handleZipcode}
                                        options={optionZipcode}
                                        value={defaultZipcode}
                                        isLoading={loadingZipcode}
                                        ref={
                                            register('zipcode', {required: "กรุณาเลือกรหัสไปรษณีย์"})
                                        }
                                />
                                {errors.zipcode && 
                                    <span className='text-danger'>{errors.zipcode.message}</span>
                                }
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                                <label>เบอร์โทรศัพท์มือถือ <span className="text-danger">*</span></label>
                                <input type="text" className="form-control" name='mobilePhoneNumber' {...register('mobilePhoneNumber', {required: 'กรุณากรอกเบอร์โทรศัทพ์มือถือ'})} />
                                {errors.mobilePhoneNumber && 
                                    <span className='text-danger'>{errors.mobilePhoneNumber.message}</span>
                                }
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                                <label>เบอร์โทรศัพท์</label>
                                <input type="text" className="form-control" name='phoneNumber' {...register('phoneNumber')} />
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                                <label>เบอร์โทรศัพท์ที่ทำงาน</label>
                                <input type="text" className="form-control" name='officePhoneNumber' {...register('officePhoneNumber')} />
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <div className="form-group">
                                <label>อีเมล <span className="text-danger">*</span></label>
                                <input type="text" className="form-control" value={defaultEmail} name='emailAddr'
                                                    onChange={(e) => {
                                                        setDefaultEmail(e.target.value)
                                                        setValue("emailAddr", e.target.value)
                                                    }} />
                                {errors.emailAddr && 
                                    <span className='text-danger'>{errors.emailAddr.message}</span>
                                }
                            </div>
                        </div>
                        
                        <div className="col-md-6 col-sm-12">
                            <div className="form-group">
                                <label>วัน/เดือน/ปี เกิด <span className="text-danger">*</span></label>
                                <input type="date" className="form-control" name='birthDay' value={defaultBirthDay} 
                                                    onChange={(e) => {
                                                        setDefaultBirthDay(e.target.value)
                                                        setValue("birthDay", e.target.value)
                                                    }}  />
                                {errors.birthDay && 
                                    <span className='text-danger'>{errors.birthDay.message}</span>
                                }
                            </div>
                        </div>
                        {broker === 'deves' ?
                            <div className="col-md-12 col-sm-12">
                                <div className="form-group">
                                    <label>longname {starDriver === true && <span className="text-danger">*</span>}</label>
                                </div>
                            </div>
                            :
                            <div className="col-md-12 col-sm-12">
                                <div className="form-group">
                                    <label>ระบุชื่อผู้ขับขี่ {starDriver === true && <span className="text-danger">*</span>}</label>
                                </div>
                            </div>
                        }
                        
                        <div className="col-md-2 col-sm-12">
                            <div className="form-group">
                                <label>คำนำหน้าชื่อ {starDriver === true && <span className="text-danger">*</span>}</label>
                                <input type="text" className="form-control" name='driverPrefix' {...register('driverPrefix', requiredDriverPrefix)} disabled={disabledDriverPrefix} />
                                {errors.driverPrefix && 
                                    <span className='text-danger'>{errors.driverPrefix.message}</span>
                                }
                            </div>
                        </div>
                        <div className="col-md-5 col-sm-12">
                            <div className="form-group">
                                <label>ชื่อ {starDriver === true && <span className="text-danger">*</span>}</label>
                                <input type="text" className="form-control" name='driverName' {...register('driverName', requiredDriverName)} disabled={disabledDriverName} />
                                {errors.driverName && 
                                    <span className='text-danger'>{errors.driverName.message}</span>
                                }
                            </div>
                        </div>
                        <div className="col-md-5 col-sm-12">
                            <div className="form-group">
                                <label>นามสกุล {starDriver === true && <span className="text-danger">*</span>}</label>
                                <input type="text" className="form-control" name='driverLastname' {...register('driverLastname', requiredDriverLastname)} disabled={disabledDriverLastname} />
                                {errors.driverLastname && 
                                    <span className='text-danger'>{errors.driverLastname.message}</span>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </>
    )
}

export default Insured
