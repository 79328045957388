import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from 'primereact/dropdown';
import { Tag } from 'primereact/tag';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import moment from 'moment'
import Swal from 'sweetalert2';
import { Backdrop, CircularProgress } from '@mui/material';
import { useAuthUser } from 'react-auth-kit';
        

function ListCancleInsurance() {
    const auth = useAuthUser()
    const [insurance, setInsurance] = useState()
    const [open, setOpen] = useState(false)
    const getInsuranceVMI = () => {
        axios.get(process.env.REACT_APP_API_URL + '/getInsurancCancle')
        .then(res => res.data)
        .then((data) => setInsurance(data))
        .then((err) => console.log(err))
    }
    useEffect(() => {
        getInsuranceVMI()
    }, [])
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        'country.name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        status: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] }
    });

    const onGlobalFilterChange = (event) => {
        const value = event.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
    };

    const renderHeader = () => {
        const value = filters['global'] ? filters['global'].value : '';

        return (
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" value={value || ''} onChange={(e) => onGlobalFilterChange(e)} placeholder="ค้นหา..." />
            </span>
        );
    };

    const fileBodyTemplate = (rowData) => {
        return <a target='_blank' href={rowData.file.file_store} className='btn btn-light btn-sm'><i className="fa fa-file-text-o" aria-hidden="true"></i></a>
    };
    
    const form = useRef()
    const cancleBodyTemplate = (rowData) => {
        const DateNow = moment().format("YYYY-MM-DD")
        if (rowData.status.status === 'sendRequest') {
            const cancleDate = moment(rowData.status.cancleDate).format("YYYY-MM-DD")
            if (rowData.status.cancleDate) {
                if (DateNow === cancleDate) {
                    return (<>
                        <form ref={form} onSubmit={handleCanclePolicy}>
                            <input type='hidden' name='_id' value={rowData._id} />
                            <input type='hidden' name='broker' value={rowData.broker[0].broker_short_name} />
                            <input type='hidden' name='policyNo' value={rowData.policy_no} />
                            <input type='hidden' name='contractNo' value={rowData.contract_number} />
                            <input type='hidden' name='requestRemark' value={rowData.status.requestRemark} />
                            <input type='hidden' name='actuallyPaid' value={rowData.actually_paid} />
                            <input type='hidden' name='sellerId' value={rowData.seller_id} />
                            <input type='hidden' name='adminId' value={auth().id} />
                        </form>
                        <button type="button" onClick={handleCanclePolicy} className="btn btn-outline-success btn-sm"><i class="fa fa-check"></i></button>
                        <button type="button" onClick={handleCancleRequest} className="ml-1 btn btn-outline-danger btn-sm"><i class="fa fa-times"></i></button>
                    </>)   
                } else {
                    return (
                        <>
                            <span className="tag tag-warning">ไม่สามารถยกเลิกผ่านระบบได้เนื่องจากเกินกำหนดเวลา</span>
                        </>
                    )      
                }
            } else {
                return (
                    <>
                        <span className="tag tag-warning">ไม่สามารถยกเลิกผ่านระบบได้เนื่องจากเกินกำหนดเวลา</span>
                    </>
                )    
            }
        } else {
            return <span className="tag tag-success">ยกเลิกสำเร็จ</span>
        }
    };
    
    const handleCanclePolicy = (event) => {
        event.preventDefault() //ไม่ให้ Browser refresh
        const formData = new FormData(form.current)
        const formEnt = Object.fromEntries(formData.entries())
        console.log(formEnt);
        if (formEnt.broker === 'tokio') {
            Swal.fire({
                title: 'อนุมัตคำขอยกเลิกกรมธรรม์',
                text: 'เนื่องจาก บริษัท คุ้มภัยโตเกียวมารีนประกันภัย (ประเทศไทย) จำกัด (มหาชน) ไม่รองรับการยกเลิกกรมธรรม์ผ่านระบบเว็บเซอร์วิส ' +
                        'ท่านต้องทำการยกเลิกกรมธรรม์ให้กับลูกค้าของท่านผ่านระบบของบริษัทโดยตรง '+
                        'โปรดตรวจสอบให้แน่ใจว่ากรมธรรม์เลขที่ ' + formEnt.policyNo + ' ได้ทำการยกเลิกแล้ว',
                confirmButtonColor: '#dc3545',
                showCancelButton: true,
                confirmButtonText: 'ใช่ ยกเลิกกรมธรรม์เรียบร้อยแล้ว',
                cancelButtonText: 'ไม่ใช่',
                icon: 'question'
            }).then((result) => {
                setOpen(true)
                if (result.isConfirmed) {
                    axios.post(process.env.REACT_APP_API_URL + "/canclePolicy", formEnt)
                    .then(response=>{
                        setOpen(false)
                        console.log('response => ', response);
                        Swal.fire("สำเร็จ", response.data.success, 'success').then((result) => {
                            if (result.isConfirmed) {
                                getInsuranceVMI()
                            }
                        })
                    }).catch(err=>{
                        setOpen(false)
                        console.log('error2 => ',err.response.data.err.errors[0].message)
                        Swal.fire('แจ้งเตือน',err.response.data.err.errors[0].message,'error')
                    })
                } else {
                    setOpen(false)
                }
            })    
        } else {
            Swal.fire({
                title: 'อนุมัตคำขอยกเลิกกรมธรรม์',
                text: 'คุณต้องการยกเลิกกรมธรรม์เลขที่ '+formEnt.policyNo,
                confirmButtonColor: '#dc3545',
                showCancelButton: true,
                confirmButtonText: 'ใช่',
                cancelButtonText: 'ไม่ใช่',
                icon: 'question'
            }).then((result) => {
                setOpen(true)
                if (result.isConfirmed) {
                    axios.post(process.env.REACT_APP_API_URL + "/canclePolicy", formEnt)
                    .then(response=>{
                        setOpen(false)
                        console.log('response => ', response);
                        Swal.fire("สำเร็จ", response.data.success, 'success').then((result) => {
                            if (result.isConfirmed) {
                                getInsuranceVMI()
                            }
                        })
                    }).catch(err=>{
                        setOpen(false)
                        console.log('error2 => ',err.response.data.err.errors[0].message)
                        Swal.fire('แจ้งเตือน',err.response.data.err.errors[0].message,'error')
                    })
                } else {
                    setOpen(false)
                }
            })    
        }
    }

    const handleCancleRequest = (event) => {
        setOpen(true)
        event.preventDefault() //ไม่ให้ Browser refresh
        const formData = new FormData(form.current)
        const formEnt = Object.fromEntries(formData.entries())
        axios.post(process.env.REACT_APP_API_URL + "/cancleSendRequestCanclePolicy", formEnt)
        .then(response=>{
            setOpen(false)
            console.log('response => ', response);
            Swal.fire("สำเร็จ", response.data.success, 'success').then((result) => {
                if (result.isConfirmed) {
                    getInsuranceVMI()
                }
            })
        }).catch(err=>{
            setOpen(false)
            console.log('error => ', err);
            Swal.fire('แจ้งเตือน',err.response.data.error,'error')
        })
    }

    const brokerBodyTemplate = (rowData) => {   
        return (
            <div className='text-center'>
                <img className="avatar" src={process.env.REACT_APP_URL_IMG_LOGO + rowData.broker[0].logo} alt="" />
            </div>
        )
    }

    const remarkBodyTemplate = (rowData) => {
        return <span>{rowData.status.requestRemark}</span>
    }

    const registrationNoBodyTemplate = (rowData) => {
        return <span>{rowData.car[0].registration_no}</span>
    }

    const sellerBodyTemplate = (rowData) => {
        return <span>{rowData.user[0].name}</span>
    }

    const statusBodyTemplate = (rowData) => {
        const DateNow = moment().format("YYYY-MM-DD")
        if (rowData.status.status === 'sendRequest') {
            return <span className="tag tag-warning">ส่งคำขอยกเลิก</span>
        } else {
            return <span className="tag tag-danger">กรมธรรม์ถูกยกเลิก</span>
        }
    }

    const header = renderHeader();
    console.log(insurance);
    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <div className="tab-pane active" id="listVMI">
                <DataTable value={insurance} paginator showGridlines rows={10}  dataKey="id" filters={filters} header={header} emptyMessage="ไม่พบข้อมูล">
                    <Column className='font-prompt' header="โบรคเกอร์" body={brokerBodyTemplate} sortable></Column>
                    <Column className='font-prompt' field="policy_no" header="เลขกรมธรรม์" style={{ minWidth: '150px' }} sortable></Column>
                    <Column className='font-prompt' field="registration_no" header="ทะเบียนรถ" body={registrationNoBodyTemplate} sortable></Column>
                    <Column className='font-prompt' header="เหตุผล" sortable body={remarkBodyTemplate}></Column>
                    <Column className='font-prompt' header="สถานะ" body={statusBodyTemplate} sortable></Column>
                    <Column className='font-prompt' field="seller_id" header="ผู้ขาย" body={sellerBodyTemplate} sortable></Column>
                    <Column className='font-prompt text-center' field="file" header="กรมธรรม์" body={fileBodyTemplate} sortable></Column>
                    <Column className='font-prompt text-center' field="_id" header="การอนุมัติ" body={cancleBodyTemplate} style={{ minWidth: '150px' }} sortable></Column>
                </DataTable>
            </div>
        </>
    )
}

export default ListCancleInsurance
