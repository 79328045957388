import React, { useRef } from 'react'
import first from '../../assets/assets/images/insureType/insureType-01.png'
import secondPlus from '../../assets/assets/images/insureType/insureType-02.png'
import second from '../../assets/assets/images/insureType/insureType-03.png'
import thirdPlus from '../../assets/assets/images/insureType/insureType-04.png'
import third from '../../assets/assets/images/insureType/insureType-05.png'
import Select from 'react-select'

function FromDetailCar() {
    const form = useRef()
    const onSubmit = () => {
        
    }
    return (
        <>
            <div className="card">
                <div className="card-header">
                    <h3 className="card-title">กรอกข้อมูลเพื่อค้นหาแผนประกัน</h3>
                </div>
                <div className="card-body">
                    <form ref={form} onSubmit={onSubmit}>
                        <div className='row'>
                            <div className='col-xl-12'>
                                <div className="form-group">
                                    <h4 className="text-center">เลือกแผนประกัน</h4>
                                    <div className='row'>
                                        <div className='col-xl-1'></div>
                                        <div className="col-xl-2 col-lg-2 col-md-6">
                                            <label className='card-element' key="1">
                                                <input type="radio" name="insuredType" className="card-input-element" value="1" />
                                                <div className="card card-input">
                                                    <img src={first} alt="}" className='m-4' />
                                                </div>
                                            </label>
                                        </div>  
                                        <div className="col-xl-2 col-lg-2 col-md-6">
                                            <label className='card-element' key="2P">
                                                <input type="radio" name="insuredType" className="card-input-element" value="2+" />
                                                <div className="card card-input">
                                                    <img src={secondPlus} alt="}" className='m-4' />
                                                </div>
                                            </label>
                                        </div>  
                                        <div className="col-xl-2 col-lg-2 col-md-6">
                                            <label className='card-element' key="2">
                                                <input type="radio" name="insuredType" className="card-input-element" value="2" />
                                                <div className="card card-input">
                                                    <img src={second} alt="}" className="m-4" />
                                                </div>
                                            </label>
                                        </div>  
                                        <div className="col-xl-2 col-lg-2 col-md-6">
                                            <label className='card-element' key="3P">
                                                <input type="radio" name="insuredType" className="card-input-element" value="3+" />
                                                <div className="card card-input">
                                                    <img src={thirdPlus} alt="}" className="m-4" />
                                                </div>
                                            </label>
                                        </div>  
                                        <div className="col-xl-2 col-lg-2 col-md-6">
                                            <label className='card-element' key="3">
                                                <input type="radio" name="insuredType" className="card-input-element" value="3" />
                                                <div className="card card-input">
                                                    <img src={third} alt="}" className="m-4" />
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>        
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-xl-2'></div>
                            <div className='col-xl-8'>
                                <div className='form-group'>
                                    <label>ยี่ห้อ <span className="text-danger">*</span></label>
                                    <Select
                                        name='makeCode'
                                        // onChange={handleCarMake}
                                        // options={optionsCarMake}
                                        // value={optionsCarMake[indexMakeCodeState]}
                                        // isLoading={loadingMake}
                                    //     ref={
                                    //         register('makeCode', {required: "กรุณาเลือกยี่ห้อรถ"})
                                    //     }
                                    />
                                    {/* {errors.makeCode && <span className='text-danger'>{errors.makeCode.message}</span> } */}
                                </div>
                            </div>
                            <div className='col-xl-2'></div>
                        </div>
                        <div className='row'>
                            <div className='col-xl-2'></div>
                            <div className='col-xl-8'>
                                <div className='form-group'>
                                    <label>รุ่น <span className="text-danger">*</span></label>
                                    <Select
                                        name='makeCode'
                                        // onChange={handleCarMake}
                                        // options={optionsCarMake}
                                        // value={optionsCarMake[indexMakeCodeState]}
                                        // isLoading={loadingMake}
                                    //     ref={
                                    //         register('makeCode', {required: "กรุณาเลือกยี่ห้อรถ"})
                                    //     }
                                    />
                                    {/* {errors.makeCode && <span className='text-danger'>{errors.makeCode.message}</span> } */}
                                </div>
                            </div>
                            <div className='col-xl-2'></div>
                        </div>
                        <div className='row'>
                            <div className='col-xl-2'></div>
                            <div className='col-xl-8'>
                                <div className='form-group'>
                                    <label>รุ่นย่อย <span className="text-danger">*</span></label>
                                    <Select
                                        name='makeCode'
                                        // onChange={handleCarMake}
                                        // options={optionsCarMake}
                                        // value={optionsCarMake[indexMakeCodeState]}
                                        // isLoading={loadingMake}
                                    //     ref={
                                    //         register('makeCode', {required: "กรุณาเลือกยี่ห้อรถ"})
                                    //     }
                                    />
                                    {/* {errors.makeCode && <span className='text-danger'>{errors.makeCode.message}</span> } */}
                                </div>
                            </div>
                            <div className='col-xl-2'></div>
                        </div>
                        <div className='row'>
                            <div className='col-xl-2'></div>
                            <div className='col-xl-8'>
                                <div className='form-group'>
                                    <label>ปีรถ <span className="text-danger">*</span></label>
                                    <Select
                                        name='makeCode'
                                        // onChange={handleCarMake}
                                        // options={optionsCarMake}
                                        // value={optionsCarMake[indexMakeCodeState]}
                                        // isLoading={loadingMake}
                                    //     ref={
                                    //         register('makeCode', {required: "กรุณาเลือกยี่ห้อรถ"})
                                    //     }
                                    />
                                    {/* {errors.makeCode && <span className='text-danger'>{errors.makeCode.message}</span> } */}
                                </div>
                            </div>
                            <div className='col-xl-2'></div>
                        </div>
                        <div className='row'>
                            <div className='col-xl-2'></div>
                            <div className='col-xl-8'>
                                <div className='form-group'>
                                    <label>จังหวัดที่จดทะเบียนรถ <span className="text-danger">*</span></label>
                                    <Select
                                        name='makeCode'
                                        // onChange={handleCarMake}
                                        // options={optionsCarMake}
                                        // value={optionsCarMake[indexMakeCodeState]}
                                        // isLoading={loadingMake}
                                    //     ref={
                                    //         register('makeCode', {required: "กรุณาเลือกยี่ห้อรถ"})
                                    //     }
                                    />
                                    {/* {errors.makeCode && <span className='text-danger'>{errors.makeCode.message}</span> } */}
                                </div>
                            </div>
                            <div className='col-xl-2'></div>
                        </div>
                        <div className='row'>
                            <div className='col-xl-12 text-center'>
                                <button type='submit' className='btn btn-primary'>ค้นหากรมธรรม์</button>
                            </div>
                        </div>    
                    </form>
                    
                </div>
            </div> 
        </>
    )
}

export default FromDetailCar