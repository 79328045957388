import React from 'react'

function WithHold() {
  return (
    <>
        <div className="auth option2">
            <div className="auth_left">
                <div className="card">
                    <div className="card-body text-center">
                        <div className="display-3 text-muted mb-5"><i className="si si-exclamation"></i> 403</div>
                        <h1 className="h3 mb-3">ขออภัย</h1>
                        <p className="h6 text-muted font-weight-normal mb-3">ดูเหมือนว่าบัญชีของคุณถูกระงับการใช้งาน โปรดติดต่อผู้ดูแลระบบ&hellip;</p>
                        <a className="btn btn-primary" href="/login"><i className="fe fe-arrow-left mr-2"></i>กลับหน้าหลัก</a>
                    </div>
                </div>        
            </div>
        </div>
    </>
  )
}

export default WithHold
