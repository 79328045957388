import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import Swal from 'sweetalert2'
import { useAuthUser } from 'react-auth-kit'
import FormEditPassword from './FormEditPassword'

function FormEditAdmin(props) {
    const form = useRef()
    const roleSelectFeild = useRef()
    const [selectRole, setSelectRole] = useState()
    const [userId, setUserId] = useState()
    const [name, setName] = useState()
    const [email, setEmail] = useState()
    const onSubmit = (event) => {
        event.preventDefault() //ไม่ให้ Browser refresh
        const formData = new FormData(form.current)
        const formEnt = Object.fromEntries(formData.entries())
        axios.post(process.env.REACT_APP_API_URL + '/updateAdmin', formEnt)
        .then(response=>{
            // form.current.reset()
            Swal.fire('สำเร็จ',response.data.success,'success')
        }).catch(err=>{
            console.log(err.response);
            Swal.fire('แจ้งเตือน',err.response.data.error,'error')
        })
    }
    const setRole = () => {
        if (roleSelectFeild.current.value === '2') {
            setSelectRole(2)
        } else {
            setSelectRole()
        }
        
    }
    const getDetailAdmin = () => {
        axios.get(process.env.REACT_APP_API_URL + "/getDetailUser/" + props.id)
        .then(res => {
            setUserId(res.data._id)
            setName(res.data.name)
            setEmail(res.data.email)
        })
        .catch(err => console.log(err))
    }
    useEffect(() => {
        getDetailAdmin()
    }, [])
    return (
        <>
            <div className="tab-pane" id="FormEditAdmin">
                <div className="row clearfix">
                    <div className="col-lg-8 col-md-12 col-sm-12">
                        <form ref={form} onSubmit={onSubmit}>
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">แก้ไขข้อมูลผู้ดูแลระบบ</h3>
                                </div>
                                <div className="card-body">
                                    <input type="hidden" name="user_id" value={userId} />
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="form-group">
                                                <label>ชื่อผู้ใช้งาน</label>
                                                <input type="text" name="name" value={name} onChange={(e) => {setName(e.target.value)}} className="form-control" required />
                                            </div>
                                        </div>
                                        <div className="col-sm-12">
                                            <div className="form-group">
                                                <label>อีเมล</label>
                                                <input name='email' type="email" value={email} onChange={(e) => {setEmail(e.target.value)}} className="form-control" required />
                                            </div>
                                        </div>
                                    </div>
                                    <input type="hidden" name='role' value="administrator" />
                                    <div className='row mt-3'>
                                        <div className="col-sm-12">
                                            <button type="submit" className="btn btn-primary mr-2">บันทึก</button>
                                            <button type="reset" className="btn btn-outline-secondary">ยกเลิก</button>
                                        </div> 
                                    </div>        
                                </div>
                            </div>
                            
                        </form>
                    </div>
                    <FormEditPassword userId={userId} />
                </div>
            </div>
        </>
    )
}

export default FormEditAdmin
