import React, { useRef } from 'react'
import axios from 'axios'
import { withSignIn } from 'react-auth-kit'
import { useNavigate } from 'react-router-dom'
import logo from '../../assets/assets/images/logo-saifon-cricle.png'
import Swal from 'sweetalert2'

const Login = (props) => {
    const form = useRef()
    const navigate = useNavigate()
    const onSubmit = (event) => {
        event.preventDefault() //ไม่ให้ Browser refresh
        const formData = new FormData(form.current)
        const formEnt = Object.fromEntries(formData.entries())
        axios.post(process.env.REACT_APP_API_URL + "/login", formEnt)
        .then(response=>{
            if (response.data.status_active === "active") {
                if(props.signIn(
                    {
                        token: response.data.token,
                        expiresIn: 1440,
                        tokenType: "Bearer",
                        authState: {id: response.data._id, name: response.data.name, role: response.data.role, status_active: response.data.status_active},  // Only if you are using refreshToken feature
                    }
                )){
                    navigate("/dashboard")
                }else {
                    navigate("/login")
                }
            } else {
                navigate("/403")
            }
            
        }).catch(err=>{
            console.log(err)
            Swal.fire('แจ้งเตือน',err.response.data.message,'error')
        })
    }
    return (
        <>
            <div className="auth option2">
                <div className="auth_left">
                    <div className="card">
                        <div className="card-body">
                            <div className="text-center">
                                <a href='/'><img src={logo} style={{width: 100}} alt="" /></a><br />
                                <a className="header-brand" href="index.html">SaiFon Insurance</a>
                                <div className="card-title mt-3">เข้าสู่ระบบ</div>
                            </div>
                            <form ref={form} onSubmit={onSubmit}>
                                <div className="form-group">
                                    <input type="email" className="form-control" id="email" aria-describedby="emailHelp" placeholder="Enter email" name="email" />
                                </div>
                                <div className="form-group">
                                    <input type="password" className="form-control" id="password" placeholder="Password" name='password' />
                                </div>
                                <div className="form-group">
                                    <label className="custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" />
                                    </label>
                                </div>
                                <div className="text-center">
                                    <button type='submit' value="login" className="btn btn-primary btn-block" title="">เข้าสู่ระบบ</button>
                                </div>    
                            </form>
                            
                        </div>
                    </div>        
                </div>
            </div>
        </>        
    )
}

export default withSignIn(Login)
