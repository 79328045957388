import React, { useRef } from 'react'
import axios from 'axios'
import Swal from 'sweetalert2'

function FormEditPassword(props) {
    const formEditPassword = useRef()
    const onSubmitEditPassword = (event) => {
        event.preventDefault() //ไม่ให้ Browser refresh
        const formData = new FormData(formEditPassword.current)
        const formEnt = Object.fromEntries(formData.entries())
        axios.post(process.env.REACT_APP_API_URL + '/updatePassword', formEnt)
        .then(response=>{
            formEditPassword.current.reset()
            Swal.fire('สำเร็จ','แก้ไขรหัสผ่านสำเร็จ','success')
        }).catch(err=>{
            console.log(err.response);
            Swal.fire('แจ้งเตือน',err.response.data.error,'error')
        })
    }
    return (
        <>
            <div className="col-lg-4 col-md-12 col-sm-12">
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title">แก้ไขรหัสผ่าน</h3>
                    </div>
                    <div className="card-body">
                        <form ref={formEditPassword} onSubmit={onSubmitEditPassword}>
                            <div className="row">
                                <input type="hidden" name="user_id" value={props.userId} />
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label>รหัสผ่านเดิม</label>
                                        <input type="password" name="passwordOld" className="form-control" required />
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label>รหัสผ่านใหม่</label>
                                        <input type="password" name="passwordNew" className="form-control" required />
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label>ยืนยันรหัสผ่านใหม่</label>
                                        <input type="password" name="passwordNewConfirm" className="form-control" required />
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className="col-sm-12">
                                    <button type="submit" className="btn btn-primary mr-2">บันทึก</button>
                                    <button type="reset" className="btn btn-outline-secondary">ยกเลิก</button>
                                </div> 
                            </div>    
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FormEditPassword
