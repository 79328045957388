import axios from 'axios'
import React, { useEffect, useState } from 'react'

function SaleDashboard(props) {
    const [insuranceCount, setInsuranceCount] = useState()
    const getInsuranceCount = () => {
        axios.get(process.env.REACT_APP_API_URL + '/getInsuranceCountCMI/' + props.userAuth)
        .then(res => res.data)
        .then((data) => setInsuranceCount(data))
        .then((err) => console.log(err))
    }
    const [commission, setCommission] = useState()
    const getMyCommissionCMI = () => {
        axios.get(process.env.REACT_APP_API_URL + '/getMyCommissionCMI/' + props.userAuth)
        .then(res => res.data)
        .then((data) => setCommission(data))
        .then((err) => console.log(err))
    }
    useEffect(() => {
        getInsuranceCount()
        getMyCommissionCMI()
    }, [])
    return (
        <>
            <div className='row'>
                <div className='col-xl-3'>
                    <div className='card'>
                        <div className='card-header'>
                            <span className="card-title">คอมมิชชั่นทั้งหมด (บาท)</span>
                        </div>
                        <div className='card-body text-right'>
                            <h1>{commission === undefined ? '0.00' : parseFloat(commission).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits:2})}</h1>
                        </div>
                    </div>        
                </div>
                <div className='col-xl-3'>
                    <div className='card'>
                        <div className='card-header'>
                            <span className="card-title">จำนวนกรมธรรม์ (ฉบับ)</span>
                        </div>
                        <div className='card-body text-right'>
                            <h1>{insuranceCount}</h1>
                        </div>
                    </div>        
                </div>
            </div>  
        </>
    )
}

export default SaleDashboard
