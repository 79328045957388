import axios from 'axios'
import React, { useEffect, useState } from 'react'

function CommissionDashboard(props) {
    const [commissionTotal, setCommissionTotal] = useState()
    const [commissionPaid, setCommissionPaid] = useState()
    const [commissionUnpaid, setCommissionUnpaid] = useState()
    const getCommissionCountMonthly = () => {
        axios.get(process.env.REACT_APP_API_URL + '/getCommissionCountMonthly/' + props.authId)
        .then(res => res.data)
        .then((data) => {
            console.log(data);
            setCommissionTotal(data.commissionTotal)
            setCommissionPaid(data.commissionPaid)
            setCommissionUnpaid(data.commissionUnpaid)
        })
        .then((err) => console.log(err))
    }
    useEffect(() => {
        getCommissionCountMonthly()
    }, [])
    return (
        <>
            <div className='row'>
                <div className='col-xl-3'>
                    <div className='card'>
                        <div className='card-header'>
                            <span className="card-title">คอมมิชชั่นทั้งหมด (บาท)</span>
                        </div>
                        <div className='card-body text-right text-primary'>
                            <h1>{commissionTotal === undefined ? '0.00' : parseFloat(commissionTotal).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits:2})}</h1>
                        </div>
                    </div>        
                </div>
                <div className='col-xl-3'>
                    <div className='card'>
                        <div className='card-header'>
                            <span className="card-title text-success">คอมมิชชั่นจ่ายแล้ว (บาท)</span>
                        </div>
                        <div className='card-body text-right text-success'>
                            <h1>{commissionPaid === undefined ? '0.00' : parseFloat(commissionPaid).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits:2})}</h1>
                        </div>
                    </div>        
                </div>
                <div className='col-xl-3'>
                    <div className='card'>
                        <div className='card-header'>
                            <span className="card-title text-danger">คอมมิชชั่นค้างจ่าย (บาท)</span>
                        </div>
                        <div className='card-body text-right text-danger'>
                            <h1>{commissionUnpaid === undefined ? '0.00' : parseFloat(commissionUnpaid).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits:2})}</h1>
                        </div>
                    </div>
                </div>
                {/* <div className='col-xl-3'>
                    <div className='card'>
                        <div className='card-header'>
                            <span className="card-title">ยอดขายทั้งหมด (บาท)</span>
                        </div>
                        <div className='card-body text-right'>
                            <h1>{parseInt(0).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits:2})}</h1>
                        </div>
                    </div>
                </div> */}
            </div>  
        </>
    )
}

export default CommissionDashboard
