import React from 'react'
import { useAuthUser } from 'react-auth-kit'
import FormWithdrawHead from './FormWithdrawHead'
import FormWithdrawSelf from './FormWithdrawSelf'
import ListWithdraw from './ListWithdraw'

function Withdraw(props) {
    const auth = useAuthUser()
    return (
        <>
            <div className="tab-pane" id="withdraw">
                <div className="row">
                    <div className="col-xl-4 col-md-12">
                        {auth().id === props.userId
                        ? <FormWithdrawSelf userId={props.userId} creditTotal={props.creditTotal} />
                        : <FormWithdrawHead userId={props.userId} creditTotal={props.creditTotal} />}                        
                    </div>
                    <div className="col-xl-8 col-md-12">
                        <ListWithdraw userId={props.userId} creditTotal={props.creditTotal} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Withdraw
